const en = {
  setting: {
    title: "Settings",
    addressData: {
      title: "Address Information"
    },
    changePassword: {
      title: "Change Password"
    },
    treatmentCategories: {
      title: "Treatment Categories"
    },
    invoiceData: {
      title: "Invoice Data"
    },
    personalData: {
      title: "Personal Data"
    },
    clinicLogo: {
      title: "Clinic Logo",
      placeholder: "Click or Drag files to this area to upload"
    }
  },
  profile: {
    title: "Profile",
      additionalInfo: "In order to change the information below, please contact our admin.",
    clinicDetails: {
      title: "Clinic Details"
    },
    clinicPhotos: {
      title: "Clinic Photo"
    },
    specialists: {
      title: "Specialists"
    },
    generalInformation: {
      title: "General Information"
    },
    treatmentCategories: {
      title: "Treatment Categories"
    }
  },

  patients: {
    title: 'Patients',
    button: {
      addPatient: "Add Patient",
      show: "Show"
    },
    patientView: {
      title: "Patient",
      table: {
        title: "Patient info",
        name: "Name",
        email: "Email",
        birthdate: "Birthdate",
        phone: "Phone",
        address: "Address",
        residency: "Residency",
        invoiceAddress: "Invoice Address",
        patientId: "Patient Id"
      },
      treatmentPlansTable: {
        title: "Patient Treatment Plans",
        noDataAvailable: "Patient has no treatment plans.",
        startDate: "Start Date",
        planName: "Plan Name",
        treatmentType: "Treatment Type"
      }
    },
    table: {
      header: {
        name: "Name",
        birthdate: "Birthdate",
        phone: "Phone",
        address: "Address",
        status: "Status",
        action: "Action"
      }
    },
    status: {
      invited: "Invited",
      active: 'Active'
    },
    modal: {
      title: "Add New Patient",
    },
    createAndInvitePatientSuccess: "Patient account created and invitation mail has been sent to user"
  },
  specialists: {
    title: 'Specialists',
    list: {
      academicDegree: "Academic degree",
      medicalDegrees: "Medical degrees",
      name: "Name",
      specializations: "Specializations",
      actions: "Actions"
    }
  },
  insurances: {
    title: 'Insurance Reports',
    list: {
      paidAt: "Purchase date",
      patient: "Patient name",
      insuranceNo: "Insurance number",
      companion: "Patient companions",
      createdAt: "Created At",
      startDate: "Start Date",
      endDate: "End Date",
      price: "Price",
      commission: "Commission",
      commissionP: "Commission in %"
    },
    actions: {
        title: "Actions",
        view: {
            title: "View",
            description: "Click on that icon to view insurance."
        },
        edit: {
            title: "Edit",
            description: "Click on that icon to edit insurance"
        }
    },
    settledWithClinic: {
        title:"Settled with meditrip",
        settled: "Settled",
        notSettled: "Not settled",
        description: `That icon show that Inquiry payment is {{isSettled}} with Clinic.`
    }
  },
  consultations: {
    title: "Video Consultation Reports",
    list: {
      startDate: "Start Date",
      calendarName: "Calendar Name",
      patientName: "Patient Name",
      createdAt: "Created At",
      commission: "CH Commission",
      price: "Price",
      consultationTime: "Consultation Time",
      status: 'Status',
      durationTime: "Duration Time",
      durationMinutes: "min",
      selectCalendar: {
        title: "Calendar Selection",
        allCalendars: "All Calendars",
        noCalendars: "No calendars"
      },
      alerts: {
        minutesRemaining: 'Minutes remaining:',
        planReset: 'Usage Reset',
        minutesEnded: 'You’ve got no more minutes left',
        minutesLeft: 'Only {{minutes}} minutes left',
        upgradePlan: 'Upgrade your plan',
        freePlanDetails: 'Consultations are not available in Free plan.'
      }
    },
    statuses: {
      scheduled: 'Scheduled',
      draft: 'Draft',
      cancelled: 'Canceled',
      succeeded: 'Succeeded',
      notStarted: 'Not started',
      started: 'Started'
    },
    actions: {
      title: "Actions",
      view: {
        title: "View",
        description: "Click on that icon to view calendar."
      },
      edit: {
        title: "Edit",
        description: "Click on that icon to edit calendar"
      }
    },
    reminderSent: {
      title:"Reminder Sent",
      sent: "Settled",
      notSent: "Not Sent",
      description: `That icon show that reminder is {{isSent}}.`
    },
    cancelConsultation: 'Cancel Consultation',
    cancellationInformation: 'Now you are cancelling the consultation. Remember to inform the patient and return the funds to his account.',
    cancelledByPatient: 'If the consultation is canceled by the patient, check how long before the consultation you received the information:',
    cancelledDayBefore: 'If the patient informed you about the cancellation more than 24 hours before the consultation, you should return the funds.',
    cancelledWithinDay: 'If the patient informed you about the cancellation 24 hours before the consultation or later, the funds stay on your account.',
  },
  calendarWidgets: {
    title: "Calendar Widgets",
    calendarName: "Calendar Name",
    price: "Price",
    actions: {
      title: "Actions",
      create: "Create New Calendar Widget",
      add: "Add Calendar",
      view: {
        title: "View",
        description: "Click on that icon to view calendar."
      },
      edit: {
        title: "Edit",
        description: "Click here to edit calendar."
      }
    }
  },
  calendar: {
    title: "Calendar",
    buttons: {
      update: "Update",
      delete: "Delete",
      create: "Create",
      populate: "Populate",
      back: "Back",
      blockSlots: "Update Slots"
    },
    calendarDetails: {
      title: "Calendar details",
      form: {
        name: {
          label: "Calendar name",
          placeholder: "Name of calendar"
        } ,
        timezone: {
          label: "Timezone",
          placeholder: "No timezone selected",
          tooltipText: "Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown aliqua, retro synth master cleanse. Mustache cliche tempor, williamsburg carles vegan helvetica."
        },
        price: {
          label: "Consultation price",
          placeholder: "Amount"
        },
        currency: {
          placeholder: "Currency"
        },
        specialistEmail: {
          label: "Specialist email",
          placeholder: "Secondary email for notifications"
        },
        clinicEmail: {
          label: "Clinic email",
          placeholder: "Clinic main email"
        },
      }
    },
    calendarTimeSlots: {
      title: "Calendar time slots",
      tabs: {
        week: {
          title: "Week",
          timeSlots: "Time slots",
          availableFrom: "Calendar start hour",
          availableUntil: "Calendar end hour",
          endDate: "Calendar end date",
          duration: "Consultation duration",
          slotsToPopulate: "Select consultation slots to populate:",
          details: "Select your default weekday available timeslot. It will be automatically\n" +
              "populated to your calendar. To change the specific date of available\n" +
              "timeslots go to the calendar section."
        },
        month: {
          title: "Month",
        },
        calendar: {
          title: "Calendar",
          editCalendar: "Click on a specific date to edit available time slot"
        },
        widget: {
          title: "Get a Calendar Widget",
          tutorial: "Get a Widget in three steps",
          steps: {
            first: "Set the time when the doctor is available.",
            second: "Copy the widget code from your panel on meditrip.",
            third: "Paste the widget code in the appropriate part of your website code."
          },
          copyWidget: "Click to copy widget",
          preview: "Widget preview"
        }
      },
      weekDays: {
        monday: "Mo",
        tuesday: "Tu",
        wednesday: "We",
        thursday: "Th",
        friday: "Fr",
        saturday: "Sa",
        sunday: "Su"
      }
    },
    modals: {
      confirmCreateModal: {
        title: "Create calendar",
        question: "Are you sure you want to create calendar?",
        success: "Calendar successfully created"
      },
      confirmUpdateModal: {
        title: "Update calendar",
        question: "Are you sure you want to override calendar?",
        success: "Calendar successfully updated."
      },
      confirmDeleteModal: {
        title: "Delete calendar",
        description: "You have video consultations scheduled in this calendar. Even if you delete the calendar, consultation will still be available. If you would like to cancel the consultations you have to do it manually.",
        deleteBtn: "Delete calendar",
        success: "Calendar successfully deleted."
      },
      confirmPopulateModal: {
        title: "Populate calendar",
        question: "Are you sure you want to populate calendar?",
        success: "Calendar successfully populated"
      },
      confirmBlockSlotsModal: {
        title: "Confirm time slots availability changes",
        question: "Are you sure you want to change availability of consultation slots?",
        success: "Time slots successfully updated"
      },
      calendarLimitsModal: {
        limitReached: 'You have used up all the calendars from your package. Please, contact our',
        salesTeam: 'sales team',
        createCustomPlan: 'to create the custom plan for you.'
      },
      buttons: {
        yes: "yes",
        no: "no"
      }
    }
  },
  treatmentPlanner: {
    title: "Treatment Planner",
    addNewPlan: "Create New Plan",
    addNewPlanButton: "Create Plan",
    list: {
      patientName: "Patient's Name",
      treatmentType: "Treatment Type",
      status: "Status",
      statuses: {
        active: "Active",
        draft: "Draft",
        inactive: "Inactive",
        invited: "Invited",
        expired: "Expired"
      },
      actions: {
        title: "Actions",
        edit: "Edit"
      },
    },
    create: {
      title: "Treatment Planner",
      calendar:  "Treatment Calendar",
      dailyEvents: {
        title: "Daily Plans",
        table: {
          notificationName: "Notification Name",
          actions: {
            title: "Actions",
            view: "View",
            edit: "Edit"
          }
        },
        addEvent: "Create New Event",
      },
      send: "Send",
      update: "Update",
      invitePatient: "Invite Patient"
    },
    newTreatmentPlan: {
      patient: "Patient",
      addEventTitle: "Create New Event",
      editEventTitle: "Edit Event",
      form : {
        eventDate: "Event Date",
        eventHour: "Hour",
        eventDayPeriod: "AM/PM",
        plannerType: 'Event type',
        eventType: "Select Event from List",
        eventName: "Event Name",
        eventDescription: "Description",
        eventDescriptionPlaceholder: "Add Event Custom Note for Patient",
        selectTypePlaceholder: "Type",
        selectAmountPlaceholder: "Amount",
        reminderDate: "When to remind about Event",
        before: "before",
        eventOccurrence: "Event Type",
        singleEvent: "Single",
        consecutiveEvent: "Consecutive",
        consecutiveType: "Consecutive Type",
        interval: "Interval"
      },
      invitation: {
        description: "Invitation to Treatment Planner has been sent to a patient.",
        button: "Ok"
      }
    },
    treatmentPlannerConfirmation: {
      description: 'To get things going and proceed with your Treatment Planner, please click the button below:',
      button: 'Confirm'
    },
    treatmentPlannerConfirmationSuccess: {
      title: 'Thank you!',
      subtitle: 'Your Treatment Plan has been confirmed.',
      description: 'You will receive an e-mail with notifications about upcoming events.',
      button: 'Go to the panel'
    },
    predefinedEvents: {
      title: "Predefined Events",
      list: {
        eventName: "Event Name",
        eventDescription: "Event Description",
        type: "Type",
        eventType: "Event Type",
        eventSingle: "Single",
        eventConsecutive: "Consecutive"
      },
      type: {
        aftercare: 'Aftercare',
        travelPlan: 'Travel Plan'
      },
      createEvent: {
        title: "Create New Event",
        button: "Create Event"
      },
      editEvent: {
        title: "Edit Event"
      },
      deleteEvent: {
        title: "Delete Event",
        description: "Are you sure you want to delete an event?",
        buttons: {
          yes: "yes",
          no: "no"
        }
      }
    },
  },
  billings: {
    title: "Billings & Payments",
    tabs: {
      subscription: "Subscription",
      invoices: "Invoices",
      paymentMethods: "Payment Methods",
      paymentsCollection: "Payments Collection"
    },
    subscriptions: {
      changeSubscriptionModal: {
        title: 'Change your subscription plan',
        currentPlan: 'Your current plan',
        newPlan: 'New Plan',
        upgrade: 'Upgrade now',
        downgrade: 'Downgrade',
        upgradeDescription: 'All changes will take place immediately',
        downgradeDescription: 'All changes will take place at the end of your current billing period. During this time, our system will adjust your tools to the reduced limits.'
      },
      calendarLimitsModal: {
        description: 'Only {{upcomingCalendarsNo}} calendars are available in the new plan. Currently, you have {{currentCalendarsNo}} calendars.\n' +
            'Before downgrading your subscription, please reduce the number of calendars to {{upcomingCalendarsNo}}.',
        warning: 'Please kindly notice, that the current plan will be active till {{date}}. We recommend you to wait with calendars reduction until the end of the billing period.',
        reduceCalendars: 'Reduce Calendars'
      },
      currentPlan: 'Current Plan',
      upgrade: 'Upgrade',
      downgrade: 'Downgrade',
      subscriptionHistory: 'See your subscriptions',
      history: {
        title: 'Your Subscriptions',
        table: {
          type: 'Plan type',
          paymentDate: 'Payment date',
          startDate: 'Start date',
          endDate: 'End date',
          price: 'Price',
          nextPayment: 'Next payment date',
          status: 'Status'
        }
      },
      noData: 'There are no subscription plans available at the moment. Please contact an admin.'
    },
    invoices: {
      table: {
        id: 'Invoice ID',
        date: 'Date',
        price: 'Price',
        status: 'Status'
      },
      statuses: {
        paid: 'Paid',
        unpaid: 'Unpaid',
        active: 'Active',
        draft: 'Draft',
        deactivated: 'Deactivated'
      }
    },
    paymentMethods: {
      title: 'Card Details',
      cardDetails: {
        holderName: 'Card Holder:',
        cardNumber: 'Card Number:',
        cardExpiration: 'Card Expiration Date:'
      },
      cardStatuses: {
        attached: 'Attached',
        unattached: 'Unattached',
        valid: 'Valid',
        invalid: 'Invalid'
      },
      updateCardDetails: 'Update card details',
      cancelSubscription: 'Cancel subscription',
      noCardAttached: 'There is no card attach. Click the button below to add a card',
      addPaymentMethod: {
        add: 'Add payment method',
        update: 'Update payment method',
        form: {
          cardholderName: 'Cardholder Name',
          cardDetails: 'Provide your card details',
          addMethod: 'Add card'
        }
      },
      cancelSubscriptionModal: {
        title: "Are you sure?",
        description: "After the cancellation, you will be switched to the Free plan from the new billing period.\n" +
            "You won’t be able to edit current calendars after the cancellation.",
        cancelSubscription: "Cancel Subscription"
      }
    },
    paymentsCollection: {
      title: 'Onboarding Information',
      subtitle: 'If you would like to receive money per each telemedicine consultation, you need to  connect your account with our payment tool - Stripe.',
      instructions: 'Click the button below and next you will be redirected to the Stripe onboarding process. ' +
          'There you will be able to provide us with your bank account information and your company details.',
      startOnboarding: 'Start stripe onboarding',
      updateOnboarding: 'Update onboarding information',
      onboardingStatus: {
        onboardingComplete: 'Clinic is onboarded to Stripe',
        onboardingRequired: 'Clinic is not onboarded to Stripe'
      },
      stripeCard: 'Your current bank account number:'
    },
    onboardingModal: {
      title: 'Onboarding required',
      description: 'If you would like to use the telemedicine, you need to go through our onboardig. It is necessary for you to collect the payments for your online consultations.',
      proceed: 'Go to onboarding '
    },
    paymentModal: {
      title: 'Payment Required',
      description: 'Video consultations are only available on a paid plan. Upgrade your plan to use telemedicine.',
      proceed: 'Upgrade'
    }
  },
  subscriptionPlans: {
    details: {
      title: "Subscription Plan",
      planName: "Name:",
      planPrice: "Price:",
      purchaseDate: "Purchase Date:",
      telemedicineHours: "Telemedicine Monthly Hours:",
      telemedicineHoursUsage: "Telemedicine Current Monthly Hours Usage:",
      meditripWidget: "meditrip Widget:",
      clinicWidget: "Clinic Widget:",
      proposedPlan: "Proposed New Plan",
      changePlan: "Change Plan",
      acceptPlan: "Accept Plan"
    },
    clinicFiles: {
      title: "Files"
    }
  },
  chat: {
    title: "Chat with Clinics",
    noNodeConnection: "Chat server is unavailable. Please check again later",
    fileSend: "Send file",
    noMessages: "There are no messages yet.",
    fileSize: "File size",
    fileIsReadyToSent: 'File is ready to be sent!',
    inputPlaceholder: 'Type something...',
    connectionProblems: 'Something went wrong. Chat is currently unavailable. Check your internet connection or try to refresh page.',
    noUserListProvided: 'No users list was provided',
    online: 'online',
    connectionFailed: 'Unable to establish direct connection',
    connecting: 'Connecting to chat...',

    saveNote: 'Save Note',
    setReminder: 'Set Reminder',
    reminderMessage: 'Reminder message',
    toggleReminder: 'Toggle reminder',
    clearReminder: 'Clear reminder',
    reminderTime: 'Reminder time',
    cancel: 'Cancel',
    note: 'note',
    userNamePlaceholder: 'Unknown username',
    noConnectionReasons: 'Possible reasons are listed bellow: ',
    not_authorized: 'Wrong token provided. Try login again',
    'websocket error': 'Some other reasons are possible',
    already_online: `Some other user logged onto this account. If it's you, please logout on other devices`,
    no_token: 'No token provided. Try login again',
    expired_token: 'Expired token provided. Try login again',
  },
  formValidation: {
    errors: {
      minLength: "The input is too short",
      isEmailValid: "Provide a valid email address",
      isRequired: "The field is required",
      isTrue: "Must be checked",
      isPasswordSame: "Passwords not match",
      isStartDateValid: "Date must be after current day",
      isStartDateGreaterOrEvenValid: "Date must be at or after current day",
      isEndDateValid: "Date of return must be after departure"
    }
  },
  errors: {
    downloadReportError: 'There are no insurances with the status "Paid" within the selected period'
  },
  buttons: {
    edit: 'Edit',
    show: 'Show',
    first: 'First',
    last: 'Last',
    previous: 'Previous',
    next: 'Next',
    back: 'Back',
    add: 'Add',
    update: 'Update',
    delete: 'Delete',
    download: 'Download',
    yes: 'Yes',
    no: 'No',
    cancelConsultation: 'Cancel Consultation'
  }
};

export default en;
