import {getDefaultTimezoneFromOffset} from "meditrip-common-web";

export function numberToHourConverter(hourNumeric: number): string {
    const result = (hourNumeric - Math.floor(hourNumeric)) !== 0,
        truncatedHour = Math.trunc(hourNumeric);
    let hourString = '',
        minuteString = '00';
    if (truncatedHour < 10) {
        hourString = '0' + truncatedHour;
    } else {
        hourString = truncatedHour.toString();
    }
    if (result) {
        const decimal = hourNumeric - Math.floor(hourNumeric);
        if (decimal === 0.25) {
            minuteString = '15';
        } else if (decimal === 0.5) {
            minuteString = '30';
        } else if (decimal === 0.75) {
            minuteString = '45';
        }
    }

    const hourInString = hourString + ':' + minuteString;

    return hourInString;
}

export function numberToDateConverter(hourNumeric: number, timezone?: string): string {
    const result = (hourNumeric - Math.floor(hourNumeric)) !== 0,
        hour = Math.trunc(hourNumeric);
    let minute = 0;
    if (result) {
        const decimal = hourNumeric - Math.floor(hourNumeric);
        if (decimal === 0.25) {
            minute = 15;
        } else if (decimal === 0.5) {
            minute = 30;
        } else if (decimal === 0.75) {
            minute = 45;
        }
    }
    const date = new Date(),
        year = date.getUTCFullYear(),
        month = date.getUTCMonth() + 1,
        day = date.getUTCDate();
        let dateInUTC = new Date(Date.UTC(year, month, day, hour, minute, 0));

    if (timezone) {
        let dateWithTimezone = new Date( dateInUTC.toUTCString() + timezone );
        return dateWithTimezone.toISOString();
    }

    return dateInUTC.toISOString();
}


export function getHoursFromDate(date: string): number {
    const baseDate = new Date(date),
        hour = baseDate.getUTCHours(),
        minutes = baseDate.getMinutes(),
        minutesInDecimal = minutes / 60,
        hourInNumberFormat = hour + minutesInDecimal;

    return hourInNumberFormat;
}

export function getUTCTime(date: string): string {
    let hours = new Date(date).getUTCHours(),
        minutes = new Date(date).getUTCMinutes(),
        time = `${hours}:${minutes === 0 ? '00' : minutes}`;

    return time;
}

export function getTime(date: string): string {
    let hours = new Date(date).getHours(),
        minutes = new Date(date).getMinutes(),
        time = `${hours}:${minutes === 0 ? '00' : minutes}`;

    return time;
}


export function getTimezoneOffset(tz: string) {
    const hereDate = new Date(Date.now());
    hereDate.setMilliseconds(0);

    const
        hereOffsetHrs = hereDate.getTimezoneOffset() / 60 * -1,
        thereLocaleStr = hereDate.toLocaleString('en-US', {timeZone: tz}),
        thereDate = new Date(thereLocaleStr),
        diffHrs = (thereDate.getTime() - hereDate.getTime()) / 1000 / 60 / 60,
        thereOffsetHrs = hereOffsetHrs + diffHrs;

    return getDefaultTimezoneFromOffset(thereOffsetHrs) ;
}


