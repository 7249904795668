import {Observable} from "rxjs";
import {RestQueryParams} from "./base/queryParams";
import {meditripCalendarAPI} from "./provider/meditripCalendar";

export interface IUpdateTreatmentPointPayload {
    name: string;
    description: string | null;
    treatmentPointStartsAt: string;
    treatmentPointEndDate: string | null;
    startNotificationPropagation: string;
    treatmentPlanId: string;
    cyclic: boolean;
    type: string;
    notificationType: string;
    cycleInterval: string | null;
}

export function updateAftercareRecommendationAPI(
    authToken: string | undefined,
    recommendationId: string,
    eventDetails: IUpdateTreatmentPointPayload
): Observable<any> {
    const payload: any = {
        name: eventDetails.name,
        description: eventDetails.description,
        treatmentPointStartsAt: eventDetails.treatmentPointStartsAt,
        treatmentPointEndDate: eventDetails.treatmentPointEndDate,
        startNotificationPropagation: eventDetails.startNotificationPropagation,
        treatmentPlanId: eventDetails.treatmentPlanId,
        cyclic: eventDetails.cyclic,
        type: eventDetails.type,
        notificationType: eventDetails.notificationType,
        cycleInterval: eventDetails.cycleInterval
    };

    return meditripCalendarAPI.put(
        `treatment_points/${recommendationId}`,
        payload,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`,
        },
    );
}
