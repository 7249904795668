import {createSlice} from "redux-starter-kit";
import {IPrice} from "meditrip-common-web";
import {deepCloneObject} from "../../utils/runtimeUtils";

export enum WeekDays {
    MO = "Mo",
    TU = "Tu",
    WE = "We",
    TH = "Th",
    FR = "Fr",
    SA = "Sa",
    SU = "Su"
}

export const weekDays = [
    WeekDays.MO,
    WeekDays.TU,
    WeekDays.WE,
    WeekDays.TH,
    WeekDays.FR,
    WeekDays.SA,
    WeekDays.SU
];

export interface ICalendarCreationPayload {
    name: string,
    public: boolean,
    price: typeof IPrice,
    slotLength: number | null,
    availableFrom: string | null,
    availableUntil: string | null,
    timezone: string,
    calendarRules: ICalendarRule[],
    clinicEmail: string,
    specialistEmail: string | null
}

export interface ICalendarGeneratorHourItem {
    value: number | null;
    label: string | null;
    endLabel: string | null;
    isFree: boolean;
}

export interface ITimeSlotItem extends ICalendarGeneratorHourItem {
    dayName: WeekDays;
    dayDateTimeStart: string;
    dayDateTimeEnd: string;
}

export interface calendarWeekDay {
    dayName: WeekDays;
    dayIndex: number;
    dayTimeSlots: ITimeSlotItem[];
}


export interface ICalendar {
    Mo: ITimeSlotItem[],
    Tu: ITimeSlotItem[],
    We: ITimeSlotItem[],
    Th: ITimeSlotItem[],
    Fr: ITimeSlotItem[],
    Sa: ITimeSlotItem[],
    Su: ITimeSlotItem[]
}
export interface ICalendarSettings {
    slotLength: number | null,
    availableFrom: number | null,
    availableUntil: number | null
}

export interface ICalendarRule {
    startsAt: string,
    endsAt: string,
    calendarId: string,
    interval: string,
    intervalStart: string,
    tags?: string | null;
    id?: string | null;
}

export interface ICalendarDetails {
    calendarName: string,
    timezone: string,
    calendarPrice: typeof IPrice,
    clinicEmail: string,
    specialistEmail: string | null
}

export interface ICalendarState {
    calendar: ICalendar;
    calendarId: string;
    isCalendarCreated: boolean;
    showCalendarLoader: boolean,
    calendarDetails: ICalendarDetails | null;
    calendarSettings: ICalendarSettings | null;
}

export interface IChangeCalendarId {
    readonly payload: {
        calendarId: string;
    };
}

export interface IChangeCalendarSettings {
    readonly payload: {
        calendarSettings: ICalendarSettings | null;
    };
}

export interface IChangeCalendarDetails {
    readonly payload: {
        calendarDetails: ICalendarDetails
    };
}

export interface IChangeCalendar {
    readonly payload: {
        calendar: ICalendar;
    };
}

export interface IChangeCalendarDay {
    readonly payload: {
        calendarDay: calendarWeekDay;
    };
}

export interface IToggleTimeSlotAvailability {
    readonly payload: {
        timeSlot: ITimeSlotItem;
    };
}

export interface IChangeShowFormLoader {
    readonly payload: {
        showCalendarLoader: boolean;
    };
}


const initialState: ICalendarState = {
    calendar: {
        Mo: [],
        Tu: [],
        We: [],
        Th: [],
        Fr: [],
        Sa: [],
        Su: []
    },
    calendarId: '',
    isCalendarCreated: false,
    showCalendarLoader: false,
    calendarSettings: null,
    calendarDetails: null
};

const calendarSlice = createSlice({
    slice: "calendar",
    initialState: initialState,
    reducers: {
        changeCalendar: {
            reducer: (state: ICalendarState, action: IChangeCalendar) => {
                const actionCalendar: any = action.payload.calendar;
                const calendar: any = Object.assign({}, state.calendar);
                (Object.keys(state.calendar) as any).map((key: keyof ICalendar) => {
                    if (actionCalendar[key] || actionCalendar[key] === false) {
                        return calendar[key] = actionCalendar[key]
                    }
                    return calendar[key] = state.calendar[key];
                });

                return {
                    calendar: calendar,
                    calendarId: state.calendarId,
                    isCalendarCreated: state.isCalendarCreated,
                    showCalendarLoader: state.showCalendarLoader,
                    calendarSettings: state.calendarSettings,
                    calendarDetails: state.calendarDetails
                };
            },
            prepare(calendar: ICalendar) {
                return {
                    payload: {calendar: calendar}
                };
            }
        },
        changeCalendarDay: {
            reducer: (state: ICalendarState, action: IChangeCalendarDay) => {
                const actionCalendarDay: calendarWeekDay = action.payload.calendarDay;
                let calendar: any = deepCloneObject(state.calendar);
                calendar[actionCalendarDay.dayName] = actionCalendarDay.dayTimeSlots;
                return {
                    calendar: calendar,
                    calendarId: state.calendarId,
                    isCalendarCreated: state.isCalendarCreated,
                    showCalendarLoader: state.showCalendarLoader,
                    calendarSettings: state.calendarSettings,
                    calendarDetails: state.calendarDetails
                };
            },
            prepare(calendarDay: calendarWeekDay) {
                return {
                    payload: {calendarDay: calendarDay}
                };
            }
        },
        toggleTimeSlotAvailability: {
            reducer: (state: ICalendarState, action: IToggleTimeSlotAvailability) => {
                const actionTimeSlot: ITimeSlotItem = action.payload.timeSlot;
                let calendar: any = deepCloneObject(state.calendar);

                calendar[actionTimeSlot.dayName].forEach((timeSlot: ITimeSlotItem) => {
                    if (timeSlot.value === actionTimeSlot.value) {
                        timeSlot.isFree = !timeSlot.isFree;
                    }
                    return timeSlot
                });
                return {
                    calendar: calendar,
                    calendarId: state.calendarId,
                    isCalendarCreated: state.isCalendarCreated,
                    showCalendarLoader: state.showCalendarLoader,
                    calendarSettings: state.calendarSettings,
                    calendarDetails: state.calendarDetails
                };
            },
            prepare(timeSlot: ITimeSlotItem) {
                return {
                    payload: {timeSlot: timeSlot}
                };
            }
        },
        changeCalendarId: {
            reducer: (state: ICalendarState, action: IChangeCalendarId) => {
                return {
                    calendar: state.calendar,
                    calendarId: action.payload.calendarId,
                    isCalendarCreated: state.isCalendarCreated,
                    showCalendarLoader: state.showCalendarLoader,
                    calendarSettings: (action.payload.calendarId === state.calendarId) ? state.calendarSettings : null,
                    calendarDetails: state.calendarDetails
                };
            },
            prepare(calendarId: string) {
                return {
                    payload: {calendarId: calendarId}
                };
            }
        },
        changeShowCalendarLoader: {
            reducer: (state: ICalendarState, action: IChangeShowFormLoader) => {
                return {
                    calendar: state.calendar,
                    calendarId: state.calendarId,
                    isCalendarCreated: state.isCalendarCreated,
                    showCalendarLoader: action.payload.showCalendarLoader,
                    calendarSettings: state.calendarSettings,
                    calendarDetails: state.calendarDetails
                };
            },
            prepare(showCalendarLoader: boolean) {
                return {
                    payload: {showCalendarLoader: showCalendarLoader}
                };
            }
        },
        changeCalendarSettings: {
            reducer: (state: ICalendarState, action: IChangeCalendarSettings) => {
                return {
                    calendar: state.calendar,
                    calendarId: state.calendarId,
                    isCalendarCreated: state.isCalendarCreated,
                    showCalendarLoader: state.showCalendarLoader,
                    calendarSettings: action.payload.calendarSettings,
                    calendarDetails: state.calendarDetails
                };
            },
            prepare(calendarSettings: ICalendarSettings | null) {
                return {
                    payload: {calendarSettings: calendarSettings}
                };
            }
        },
        changeCalendarDetails: {
            reducer: (state: ICalendarState, action: IChangeCalendarDetails) => {
                return {
                    calendar: state.calendar,
                    calendarId: state.calendarId,
                    isCalendarCreated: state.isCalendarCreated,
                    showCalendarLoader: state.showCalendarLoader,
                    calendarSettings: state.calendarSettings,
                    calendarDetails: action.payload.calendarDetails
                };
            },
            prepare(calendarDetails: ICalendarDetails) {
                return {
                    payload: {calendarDetails: calendarDetails}
                };
            }
        },
        resetCalendar: {
            reducer: () => {
                const state = Object.assign({}, initialState);
                return state;
            },
            prepare() {
                return {
                    payload: {}
                };
            }
        },
    }
});

export const {
    changeCalendar,
    changeCalendarDay,
    changeCalendarId,
    changeShowCalendarLoader,
    resetCalendar,
    toggleTimeSlotAvailability,
    changeCalendarDetails,
    changeCalendarSettings
} = calendarSlice.actions;

export default calendarSlice.reducer;
