import React from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {reset, retrieve} from '../../../actions/patient/show';
import {RootState} from "../../../store/reducers";
import {AccountAddressBox, authTokenSelector, clinicIdSelector, CustomCard, Translation} from "meditrip-common-web";
import {
    retrievedPatientErrorSelector,
    retrievedPatientEventSourceSelector,
    retrievedPatientLoadingSelector,
    retrievedPatientSelector
} from '../../../store/selectors/patientSelectors';
import {noDataDisplay} from "../../../utils/runtimeUtils";

interface IConnectedPatientViewProps {
    readonly retrieved?: any;
    readonly loading: boolean;
    readonly error?: string;
    readonly eventSource?: EventSource;
    readonly deleteError?: string;
    readonly deleteLoading: boolean;
    readonly authToken: string;
    readonly retrieve: typeof retrieve;
    readonly reset: any;
    readonly clinicId: string;
}

interface IExternalPatientViewProps {
}

interface IPatientViewProps extends IConnectedPatientViewProps,
    IExternalPatientViewProps,
    RouteComponentProps {
}

interface IPatientViewState {
}

class PatientView extends React.Component<IPatientViewProps, IPatientViewState> {
    componentDidMount() {
        const slug = this.props.match.params;
        this.props.retrieve(`patients/${(slug as any).id}`, this.props.authToken);
    }

    componentWillUnmount() {
        if (this.props.eventSource) {
            this.props.reset(this.props.eventSource);
        }
    }

    render() {
        const item = this.props.retrieved;

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-xl-12">
                        <div className="view-header">
                            <div className="view-title">
                                <Translation text={'patients.patientView.title'}/>
                            </div>
                            <div className="action-container">
                                <Link to={`/dashboard/patients`} className="btn btn-no-outline">
                                    <span className="feather icon-chevron-left"/>
                                    <Translation text={'buttons.back'}/>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-6 col-md-12">
                        <CustomCard showLocalLoader={this.props.loading}>
                            <CustomCard.Header>
                                <Translation text={'patients.patientView.table.title'}/>
                            </CustomCard.Header>
                            <CustomCard.Body>
                                {item && item.account && (
                                    <table className="data-table">
                                        <thead>
                                        <tr>
                                            <th className="w-25">
                                                <Translation text={'patients.patientView.table.name'}/>
                                            </th>
                                            <th>{item.account.firstName} {item.account.lastName}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {item.account.user && (
                                            <tr>
                                                <td>
                                                    <Translation text={'patients.patientView.table.email'}/>
                                                </td>
                                                <td>{item.account.user.login}</td>
                                            </tr>
                                        )}
                                        <tr>
                                            <td><Translation text={'patients.patientView.table.phone'}/></td>
                                            <td>{noDataDisplay(item.account.phone)}</td>
                                        </tr>
                                        <tr>
                                            <td><Translation text={'patients.patientView.table.address'}/></td>
                                            <td>{noDataDisplay(item.account.address.addressLine1, <AccountAddressBox address={item.account.address}/>)}</td>
                                        </tr>
                                        <tr>
                                            <td><Translation text={'patients.patientView.table.residency'}/></td>
                                            <td>{noDataDisplay(item.account.residency)}</td>
                                        </tr>
                                        <tr>
                                            <td><Translation text={'patients.patientView.table.invoiceAddress'}/></td>
                                            <td>{noDataDisplay(item.account.invoiceAddress.addressLine1, <AccountAddressBox address={item.account.invoiceAddress}/>)}</td>
                                        </tr>
                                        <tr>
                                            <td><Translation text={'patients.patientView.table.patientId'}/></td>
                                            <td>{noDataDisplay(item.id)}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                )}
                            </CustomCard.Body>
                        </CustomCard>
                    </div>

                    <div className="col-xl-6 col-md-12">
                        <CustomCard showLocalLoader={this.props.loading}>
                            <CustomCard.Header>
                                <Translation text={'patients.patientView.treatmentPlansTable.title'}/>
                            </CustomCard.Header>
                            <CustomCard.Body>
                                {this.renderTreatmentPointTableRows()}
                            </CustomCard.Body>
                        </CustomCard>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private renderTreatmentPointTableRows() {
        if (!this.props.retrieved || !this.props.retrieved.treatmentPlans || !this.props.retrieved.treatmentPlans.length) {
            return (<p><Translation text={'patients.patientView.treatmentPlansTable.noDataAvailable'}/></p>);
        }

        return (
            <table className="data-table">
                <thead>
                    <tr>
                        <th><Translation text={'patients.patientView.treatmentPlansTable.startDate'}/></th>
                        <th><Translation text={'patients.patientView.treatmentPlansTable.planName'}/></th>
                        <th><Translation text={'patients.patientView.treatmentPlansTable.treatmentType'}/></th>
                        <th className="text-center"/>
                    </tr>
                </thead>
                <tbody>
                    {this.props.retrieved.treatmentPlans
                        .filter((item: {[key: string]: any}) => item.clinic.id === this.props.clinicId)
                        .map((item: any) => {
                        return (
                        <tr key={item['id']}>
                            <td>{this.getStartDate(item.treatmentPoints)}</td>
                            <td>{noDataDisplay(item.name)}</td>
                            <td>{noDataDisplay(item.treatmentType.name)}</td>
                            <td className="text-right align-middle">
                                <Link className="btn btn-action" to={`/dashboard/aftercare/${item['id']}`}>
                                    <span className="feather icon-search"/>
                                </Link>
                            </td>
                        </tr>)
                    })}
                </tbody>
        </table>)
    }

    private getStartDate = (treatmentPoints: {[key: string]: any}[]): string => {
        if (!treatmentPoints.length) {
            return '---';
        }

        let dates: string[] = [];
        treatmentPoints.forEach((item: {[key: string]: any}) => {
            dates.push(item.treatmentPointStartsAt.split('T')[0]);
        });

        let earliest = dates.reduce((prev: string, cur: string) => {
                return Date.parse(prev) > Date.parse(cur) ? cur : prev;
            });

        return earliest;
    }
 }

export default connect(
    (state: RootState) => ({
        retrieved: retrievedPatientSelector(state),
        error: retrievedPatientErrorSelector(state),
        loading: retrievedPatientLoadingSelector(state),
        eventSource: retrievedPatientEventSourceSelector(state),
        authToken: authTokenSelector(state),
        clinicId: clinicIdSelector(state)
    }),
    {
        retrieve,
        reset,
    }
)(PatientView);
