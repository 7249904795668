import React from 'react';
import {
    MainAccountData,
    PaymentAccount,
    SubscriptionDetails,
    Unit,
    updateAccountData
} from '../../../store/reducers/accountSlice';
import {IFormConfig, addAlert, updateCompanyName} from 'meditrip-common-web';
import {fixInjectedProperties, lazyInject} from '../../../ioc';
import {updateAccountDataAPI} from '../../../api/updateAccountAPI';
import {IAccountPayloadMapperService, UpdateCardType} from '../../../service/accountPayloadMapperService';
import {IAccountFormUpdaterService} from '../../../service/accountFormUpdaterService';
import {Subscription} from 'rxjs';
import {updateClinicAPI} from "../../../api/updateClinicAPI";

interface IBaseAccountFormConnectedProps {
    readonly account: any;
    readonly clinic: any;
    readonly accountId: string | null;
    readonly authToken: string | null;
    readonly isLoading: boolean;
    readonly updateAccountData: typeof updateAccountData;
    readonly updateCompanyName: typeof updateCompanyName;
    readonly addAlert: typeof addAlert;
}

interface IBaseAccountFormExternalProps {
}

export interface IBaseAccountFormProps extends IBaseAccountFormConnectedProps,
    IBaseAccountFormExternalProps {
}

export interface IBaseAccountFormState {
    isLoading: boolean;
    formConfig: typeof IFormConfig;
    value: any;
}

abstract class BaseAccountForm<Props extends IBaseAccountFormProps, State extends IBaseAccountFormState, FormConfig extends typeof IFormConfig = any> extends React.Component<Props, State> {
    @lazyInject('AccountPayloadMapperService') protected accountPayloadMapper: IAccountPayloadMapperService;
    @lazyInject('AccountFormUpdaterService') private updateFormService: IAccountFormUpdaterService;

    protected abstract cardType(): UpdateCardType;

    protected abstract successMessage(): string;

    protected abstract mapAccountToFormData(account: any, clinic: any): any;

    readonly subscriptions: Subscription[] = [];

    protected constructor(formConfig: FormConfig, props: Props) {
        super(props);

        this.state = {
            isLoading: false,
            formConfig: formConfig,
        } as Readonly<State>;

        fixInjectedProperties(this);
    }

    componentDidMount() {
        this.setState({isLoading: true});
        if (this.props.account) {
            this.updateFormFromState();
        }
    }

    componentDidUpdate(
        prevProps: Readonly<Props>,
        prevState: Readonly<State>,
        snapshot?: any
    ): void {
        if (this.props.isLoading !== prevProps.isLoading && !this.props.isLoading) {
            this.setState({isLoading: false});
        }
        if (this.props.account !== prevProps.account) {
            this.updateFormFromState();
        }
    }

    componentWillUnmount() {
        if (null !== this.subscriptions) {
            this.subscriptions.forEach(subscription => subscription.unsubscribe());
        }
    }

    protected updateFormFromState() {
        this.setState({
            value: this.mapAccountToFormData(this.props.account, this.props.clinic),
            isLoading: false
        });
    }

    protected save = (event: any, value: any, valid: boolean, touched: boolean): void => {
        if (!touched || !valid || !this.props.authToken || !this.props.accountId || this.state.isLoading) {
            return;
        }
        this.setState({isLoading: true});

        const updateAccountPayload = this.accountPayloadMapper.updateAccountFromCard(
            this.props.account,
            value,
            this.cardType()
        ).uploadAccountPayload;

        this.subscriptions.push(updateAccountDataAPI(
            this.props.accountId as string,
            this.props.authToken as string,
            updateAccountPayload.firstName,
            updateAccountPayload.lastName,
            updateAccountPayload.birthdate,
            updateAccountPayload.phone,
            updateAccountPayload.address,
            updateAccountPayload.invoiceAddress,
            updateAccountPayload.residency,
            updateAccountPayload.timezone
        ).subscribe(
            resp => {
                this.props.addAlert({
                    message: this.successMessage(),
                });
                const addressDataFromApi = resp.address,
                    invoiceDataFromApi = resp.invoiceAddress,
                    personalDataFromApi: Unit = {
                        firstName: resp.firstName,
                        lastName: resp.lastName,
                        phone: resp.phone,
                        birthDate: resp.birthDate,
                        residency: resp.residency,
                        timezone: resp.timezone
                    },
                    paymentAccounts: PaymentAccount[] = resp.paymentAccounts.map((account: {[key: string]: any}) => {
                        return {
                            id: account.id,
                        paymentAccountVendorData: {
                            accountBankTransferBankName: account.paymentAccountVendorData.accountBankTransferBankName,
                            accountBankTransferCountry: account.paymentAccountVendorData.accountBankTransferCountry,
                            accountBankTransferCurrency: account.paymentAccountVendorData.accountBankTransferCurrency,
                            accountBankTransferLastFour: account.paymentAccountVendorData.accountBankTransferLastFour,
                            accountEmail: account.paymentAccountVendorData.accountEmail,
                            chargesEnabled: account.paymentAccountVendorData.chargesEnabled,
                            customerId: account.paymentAccountVendorData.customerId,
                            customerPaymentMethodBrand: account.paymentAccountVendorData.customerPaymentMethodBrand,
                            customerPaymentMethodExpMonth: account.paymentAccountVendorData.customerPaymentMethodExpMonth,
                            customerPaymentMethodExpYear: account.paymentAccountVendorData.customerPaymentMethodExpYear,
                            customerPaymentMethodId: account.paymentAccountVendorData.customerPaymentMethodId,
                            customerPaymentMethodLastFour: account.paymentAccountVendorData.customerPaymentMethodLastFour,
                            customerPaymentMethodName: account.paymentAccountVendorData.customerPaymentMethodName,
                            paymentAccountId: account.paymentAccountVendorData.paymentAccountId
                        },
                        paymentAccountVendorType: account.paymentAccountVendorType
                        }
                    }),
                    subscription: SubscriptionDetails = {
                        currentSubscriptionEntry: resp.subscription.currentSubscriptionEntry,
                        id: resp.subscription.id,
                        proposedPlans: resp.subscription.proposedPlans,
                        subscriptionEntries: resp.subscription.subscriptionEntries,
                        upcomingSubscription: resp.subscription.upcomingSubscription
                    },
                    updatedAccountState: MainAccountData = {
                        personalData: personalDataFromApi,
                        addressData: addressDataFromApi,
                        invoiceData: invoiceDataFromApi,
                        paymentAccounts: paymentAccounts,
                        subscription: subscription,
                        id: resp.id
                    };
                this.props.updateAccountData(updatedAccountState);
                this.setState({isLoading: false});
            },
            err => {
                this.props.addAlert({
                    message: err.response ? err.response.message : 'Something went wrong. Please try again later.',
                });
                this.setState({isLoading: false});
            }
        ));

        this.subscriptions.push(updateClinicAPI(
                this.props.clinic.id as string,
                this.props.authToken as string,
                this.props.clinic.login,
                this.props.clinic.account,
                value.companyName,
                this.props.clinic.reference,
                this.props.clinic.feePercent,
                this.props.clinic.timezone
            ).subscribe(
                resp => {
                    this.props.addAlert({
                        message: this.successMessage(),
                    });
                    this.props.updateCompanyName(resp.companyName);
                    this.setState({isLoading: false});
                },
                err => {
                    this.props.addAlert({
                        message: err.response ? err.response.message : 'Something went wrong. Please try again later.',
                    });
                    this.setState({isLoading: false});
                }
            ))
    };
}

export default BaseAccountForm;
