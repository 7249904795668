import React from 'react';
import {catchError, tap} from "rxjs/operators";
import {of, Subscription} from "rxjs";
import {fixInjectedProperties, lazyInject} from "../../../../ioc";
import {IAlertManagerService} from "../../../../service/alertManagerService";
import {BasicModal, CustomCard, CustomCardType, Translation} from "meditrip-common-web";
import {cancelConsultationAPI} from "../../../../api/cancelConsultation";

interface IConnectedCancelConsultationProps {}

interface IExternalCancelConsultationProps {
    id: string;
    authToken: string;
    getInquiryList: any;
    isDisabled?: boolean;
}

interface ICancelConsultationProps extends
    IConnectedCancelConsultationProps,
    IExternalCancelConsultationProps {}

interface ICancelConsultationState {
    deleteModalShown: boolean;
    selectedConsultationId: string | null;
}

class CancelConsultation extends React.Component<ICancelConsultationProps, ICancelConsultationState> {
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;

    private subscription: Subscription | null = null;

    constructor(props: any) {
        super(props);

        this.state = {
            deleteModalShown: false,
            selectedConsultationId: null
        };

        fixInjectedProperties(this);
    }

    componentDidMount(): void {}

    componentWillUnmount() {
        if (null !== this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    render() {
        return (
            <React.Fragment>
                <button className="btn btn-action"
                        onClick={() => this.handleChange()}
                        disabled={this.props.isDisabled}>
                    <span className="feather icon-minus-circle"/>
                </button>

                <BasicModal isModalShown={this.state.deleteModalShown} closeModal={this.toggleDeleteModal}>
                    <CustomCard type={CustomCardType.MODAL_CARD}>
                        <CustomCard.Body>
                            <div className="modal-header">
                                <Translation text={"consultations.cancelConsultation"}/>
                                <button className="btn-modal-close" onClick={() => this.toggleDeleteModal()}>
                                    <span className="feather icon-x"/>
                                </button>
                            </div>
                            <div className="modal-body consultation-cancellation-modal">
                                <p><Translation text={'consultations.cancellationInformation'}/></p>
                                <p><Translation text={'consultations.cancelledByPatient'}/></p>
                                <ol>
                                    <li><Translation text={'consultations.cancelledDayBefore'}/></li>
                                    <li><Translation text={'consultations.cancelledWithinDay'}/></li>
                                </ol>
                            </div>
                            <div className="modal-footer">
                                {/*<button className="btn btn-danger-outline mr-4"*/}
                                {/*        onClick={() => this.toggleDeleteModal()}>*/}
                                {/*    <Translation text={'buttons.no'}/>*/}
                                {/*</button>*/}
                                <button className="btn btn-theme"
                                        onClick={() => this.cancelOnlineConsultation()}>
                                    <Translation text={'buttons.cancelConsultation'}/>
                                </button>
                            </div>
                        </CustomCard.Body>
                    </CustomCard>
                </BasicModal>
            </React.Fragment>

        );
    }

    private handleChange = () => {
        this.setState({deleteModalShown: true, selectedConsultationId: this.props.id});
    };

    private cancelOnlineConsultation = (): any => {
        if (!this.state.selectedConsultationId) {
            return;
        }

        return this.subscription = cancelConsultationAPI(
            this.state.selectedConsultationId,
            this.props.authToken
        ).pipe(
            tap(() => {
                this.alertManager.addAlert('Your online consultation was successfully cancelled');
                this.setState({deleteModalShown: false});
                this.props.getInquiryList(
                    `online_consultations?page=1&itemsPerPage=10&order[startsAt]=DESC`,
                    this.props.authToken
                );
            }),
            catchError((error: any) => {
                return of(this.alertManager.handleApiError(error))
            })
        ).subscribe();
    };

    private toggleDeleteModal = () => {
            this.setState({
            deleteModalShown: !this.state.deleteModalShown,
        });
    };
}

export default CancelConsultation;
