import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {RootState} from "../../../../store/reducers";
import {list} from '../../../../actions/recommendationDefinition/list';
import {
    authTokenSelector,
    BasicModal,
    clinicIdSelector,
    CustomCard,
    CustomCardType,
    Form,
    FormControlChangeType,
    IFormConfig,
    Translation,
} from "meditrip-common-web";
import {connect} from "react-redux";
import {WithTranslation, withTranslation} from "react-i18next";
import {IAlertManagerService} from '../../../../service/alertManagerService';
import {fixInjectedProperties, lazyInject} from "../../../../ioc";
import {BehaviorSubject, of, Subscription} from "rxjs";
import {catchError, filter, map, tap} from "rxjs/operators";
import {eventTypeFormConfig} from "./formConfig";
import {IAftercareMapperService} from "../../../../service/aftercareMapperService";
import {
    createRecommendationDefinitionAPI,
    IRecommendationDefinitionPayload
} from "../../../../api/createRecomendationDefinitionAPI";
import {updateRecommendationDefinitionAPI} from "../../../../api/updateRecomendationDefinitionAPI";
import {RecommendationDefinitionType} from "../index";
import AddTravelPlan from './AddTravelPlan';
import AddAftercare from './AddAftercare';
import {isNullOrUndefined} from "../../../../utils/runtimeUtils";


export enum EventType {
    AFTERCARE = 'after_care',
    TRAVEL_PLAN = 'travel_plan'
}

interface IConnectedAddPredefinedRecommendationProps {
    readonly authToken: string;
    readonly clinicId: string;
    readonly list: any;
}

interface IAddPredefinedRecommendationProps extends IConnectedAddPredefinedRecommendationProps,
    RouteComponentProps,
    WithTranslation {
    addRecommendationModalShown: boolean;
    closeRecommendationModal: any;
    toggleRecommendationModal: any;
    selectedRecommendation: {[key: string]: any} | null;
}

interface IAddPredefinedRecommendationState {
    formConfig: typeof IFormConfig;
    isFormValid: boolean;
    isLoading: boolean;
    value: any;
}

class AddPredefinedRecommendation extends React.Component<IAddPredefinedRecommendationProps, IAddPredefinedRecommendationState> {
    readonly subscriptions: Subscription[] = [];
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;
    @lazyInject('AftercareMapperService') private aftercareMapper: IAftercareMapperService;

    constructor(props: IAddPredefinedRecommendationProps) {
        super(props);

        this.state = {
            formConfig: eventTypeFormConfig,
            isFormValid: true,
            isLoading: false,
            value: null
        };

        fixInjectedProperties(this);
    }

    componentDidMount() {
        this.subscriptions.push(
            this.onValueStateChange$.pipe(
                filter((data: any) => data && data.changeType === FormControlChangeType.User),
                tap((data: any) => this.onFormValueChange(data.value, data.changeType)),
            ).subscribe()
        );

        if (this.props.selectedRecommendation) {
            this.changeFormControlValues(this.props.selectedRecommendation);
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        return (
            <React.Fragment>
                <BasicModal isModalShown={this.props.addRecommendationModalShown} closeModal={this.closeModal}>
                    <CustomCard showLocalLoader={this.state.isLoading} type={CustomCardType.MODAL_CARD}>
                        <CustomCard.Body>
                            <div className="modal-header">
                                {this.props.selectedRecommendation ?
                                    <Translation text={'treatmentPlanner.predefinedEvents.editEvent.title'}/> :
                                    <Translation text={'treatmentPlanner.predefinedEvents.createEvent.title'}/>
                                }
                                <button className="btn-modal-close" onClick={() => {
                                    this.setState({value: null});
                                    this.props.toggleRecommendationModal()
                                }}>
                                    <span className="feather icon-x"/>
                                </button>
                            </div>
                            <div className="modal-body aftercare-event-modal">
                                <Form config={this.state.formConfig}
                                      onValueStateChange={this.onValueStateChange}
                                      onValidationStateChange={this.onValidationStateChange}
                                      value={this.state.value}
                                      controlName={'addRecommendationForm'}
                                      submitForm={this.addRecommendation}/>

                                {this.state.value?.treatmentPlannerType === EventType.AFTERCARE ?
                                    (<AddAftercare selectedRecommendation={this.props.selectedRecommendation}
                                                   closeRecommendationModal={this.props.closeRecommendationModal}
                                                   addRecommendation={this.addRecommendation}/>) :
                                    (<AddTravelPlan selectedRecommendation={this.props.selectedRecommendation}
                                                    closeRecommendationModal={this.props.closeRecommendationModal}
                                                    addRecommendation={this.addRecommendation}/>)
                                }
                            </div>
                        </CustomCard.Body>
                    </CustomCard>
                </BasicModal>
            </React.Fragment>
        );
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private onValidationStateChange = (controlName: string, isValid: boolean) => {
        this.setState({isFormValid: isValid});
    };

    private onFormValueChange = (value: any, changeType: typeof FormControlChangeType) => {
        this.setState({value: value});
    };

    private addRecommendation = (value: any) => {
        this.setState({isLoading: true});
        this.subscriptions.push(this.handleRecommendationAPI(value).subscribe());
    };

    private handleRecommendationAPI(value: any) {
        const startNotificationPropagation = this.aftercareMapper.convertValueToDateInterval(value.reminderAmount, value.reminderUnit);
        const cycleInterval = this.aftercareMapper.convertValueToDateInterval(value.intervalValue, value.intervalUnit);
        const treatmentPointEndsAtInterval = this.aftercareMapper.convertToRecommendationEndsAtInterval(
            value.consecutiveType,
            value.consecutiveValue,
            value.intervalUnit,
            value.intervalValue
        );
        let notificationType = value.repeatEvent === RecommendationDefinitionType.SINGLE || isNullOrUndefined(value.repeatEvent)?
            RecommendationDefinitionType.SINGLE : value.consecutiveType;

        let payload: IRecommendationDefinitionPayload = {
            type: this.state.value.treatmentPlannerType,
            name: value.recommendationName,
            description: value.recommendationDescription,
            startNotificationPropagation: startNotificationPropagation,
            accountId: this.props.clinicId,
            cyclic: this.state.value.treatmentPlannerType === EventType.TRAVEL_PLAN ? false : value.repeatEvent !== RecommendationDefinitionType.SINGLE,
            cycleInterval: isNullOrUndefined(value.repeatEvent) ? null :
                (value.repeatEvent !== RecommendationDefinitionType.SINGLE ? cycleInterval : null),
            treatmentPointEndsAtInterval: isNullOrUndefined(value.repeatEvent) ? null :
                (value.repeatEvent !== RecommendationDefinitionType.SINGLE ? treatmentPointEndsAtInterval : null),
            notificationType: notificationType
        };

        if (this.props.selectedRecommendation) {
            let editPayload = Object.assign({}, payload);
            delete editPayload.accountId;

            return updateRecommendationDefinitionAPI(
                this.props.authToken,
                this.props.selectedRecommendation.id,
                payload
            ).pipe(
                map(() => {
                    const searchParams = this.props.location.search;
                    this.props.list(
                        `treatment_point_definitions${searchParams}`,
                        this.props.authToken
                    );

                    this.setState({isLoading: false});
                    this.closeModal();
                    this.alertManager.addAlert('Recommendation was successfully updated.');
                }),
                catchError((error: any) => {
                        this.setState({isLoading: false});
                        return of(this.alertManager.handleApiError(error));
                    }
                )
            );
        }

        return createRecommendationDefinitionAPI(this.props.authToken, payload).pipe(
            map(() => {
                this.props.list(
                    `treatment_point_definitions`,
                    this.props.authToken
                );

                this.setState({isLoading: false});
                this.closeModal();
                this.alertManager.addAlert('Recommendation was successfully created.');
            }),
            catchError((error: any) => {
                    this.setState({isLoading: false});
                    return of(this.alertManager.handleApiError(error));
                }
            )
        );
    }

    private closeModal = () => {
        this.props.closeRecommendationModal();
    };

    private changeFormControlValues(recommendation: {[key: string]: any}) {
        this.setState({
            value: {
                treatmentPlannerType: recommendation.structure.type,
            }
        });
    }
}

export default withTranslation()(connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
        clinicId: clinicIdSelector(state)
    }),
    {
        list
    }
)(withRouter(AddPredefinedRecommendation)));
