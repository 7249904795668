import {Observable} from "rxjs";
import {RestQueryParams} from "./base/queryParams";
import { meditripRegistrationAPI } from "./provider/meditripRegistration";


export function confirmTreatmentPlanAPI(
    token: string,
    treatmentPlanId: string
): Observable<any> {
    const payload: any = {
        token: token,
        treatmentPlanId: treatmentPlanId
    };

    return meditripRegistrationAPI.post(
        "treatment_plans/confirm",
        payload,
        new RestQueryParams(),
    );
}

