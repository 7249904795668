import {FormControlType, InputType, ValidationRules} from 'meditrip-common-web';

export const generalInformationFormConfig: {[key: string]: any} = {
  controlType: 'group',
  class: '',
  dataAccessor: (data: any) => data, // data is not grouped
  outputDataMapper: (data: any, previousStateSnapshot: any) => {
    Object.assign(previousStateSnapshot, data);

    return previousStateSnapshot;
  },
  controls: [
    {
      controlType: 'group',
      key: 'overview',
      class: 'row',
      controls: {
        overview: {
          controlType: 'control',
          defaultValue: '',
          formControlType: FormControlType.TEXTAREA,
          disabled: true,
          validationRules: [
            { name: ValidationRules.IS_REQUIRED },
          ],
          placeholder: 'Overview',
          label: "Overview",
          type: InputType.TEXT,
          hostClass: 'col-xl-12',
        }
      },
    },
    // {
    //   controlType: 'group',
    //   key: 'location',
    //   class: 'row',
    //   controls: {
    //     location: {
    //       controlType: 'control',
    //       defaultValue: '',
    //       formControlType: FormControlType.TEXTAREA,
    //       disabled: true,
    //       validationRules: [
    //         { name: ValidationRules.IS_REQUIRED },
    //       ],
    //       placeholder: 'Location',
    //       label: "Location",
    //       type: InputType.TEXT,
    //       hostClass: 'col-xl-12',
    //     }
    //   }
    // },
    {
      controlType: 'group',
      key: 'local_attraction',
      class: 'row',
      controls: {
        localAttraction: {
          controlType: 'control',
          defaultValue: '',
          formControlType: FormControlType.TEXTAREA,
          disabled: true,
          validationRules: [
            { name: ValidationRules.IS_REQUIRED },
          ],
          placeholder: 'Local Attraction',
          label: "Local Attraction",
          type: InputType.TEXT,
          hostClass: 'col-xl-12',
        }
      }
    },
    // {
    //   controlType: 'group',
    //   key: 'maps',
    //   class: 'row',
    //   controls: {
    //     maps: {
    //       controlType: 'control',
    //       defaultValue: '',
    //       formControlType: FormControlType.INPUT,
    //       disabled: true,
    //       validationRules: [
    //         { name: ValidationRules.IS_REQUIRED },
    //       ],
    //       placeholder: 'Maps',
    //       label: "Maps",
    //       type: InputType.TEXT,
    //       hostClass: 'col-xl-12',
    //     }
    //   }
    // },
    {
      controlType: 'group',
      key: 'extra_services',
      class: 'row',
      controls: {
        extraServices: {
          controlType: 'control',
          defaultValue: '',
          formControlType: FormControlType.AUTOCOMPLETE,
          multiselectOptions: [],
          inputDataMapper: (data: any) => {
            if (!data) {
              return [];
            }

            return data.map((service: any) => ({
              value: service.id,
              label: service.name,
            }));
          },
          disabled: true,
          validationRules: [
            { name: ValidationRules.IS_REQUIRED },
          ],
          placeholder: 'No extra services selected',
          label: "Extra Services",
          type: InputType.TEXT,
          hostClass: 'col-xl-12',
        }
      }
    }
  ]
};
