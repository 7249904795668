import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {
    authTokenSelector,
    Form,
    FormControlChangeType,
    IFormConfig,
    IMultiselectOption,
    ValidationRules
} from "meditrip-common-web";
import {WithTranslation, withTranslation} from "react-i18next";
import {IAlertManagerService} from '../../../../../../service/alertManagerService';
import {fixInjectedProperties, lazyInject} from "../../../../../../ioc";
import {BehaviorSubject, Subscription} from "rxjs";
import {filter, tap} from "rxjs/operators";
import {createAfterCareFormConfig} from "./formConfig";
import {IAftercareMapperService} from "../../../../../../service/aftercareMapperService";
import {RecommendationDefinitionType} from "../../../../PredefinedRecommendationsList";
import {connect} from "react-redux";
import {RootState} from "../../../../../../store/reducers";
import {retrievedRecommendationDefinitionListSelector} from "../../../../../../store/selectors/recommendationDefinitionListSelectors";
import {list as recommendationDefinitionList} from "../../../../../../actions/recommendationDefinition/list";
import {isNotNullOrUndefined} from "../../../../../../utils/runtimeUtils";
import {EventType} from "../../../../PredefinedRecommendationsList/AddPredefinedRecommendation";


interface IConnectedCreateAfterCareEventProps {
    readonly authToken: string;
    readonly retrievedRecommendationDefinitions: any;
    readonly recommendationDefinitionList: any;
}

interface ICreateAfterCareEventProps extends IConnectedCreateAfterCareEventProps,
    RouteComponentProps,
    WithTranslation {
    closeRecommendationModal: any;
    addNewEvent: (value: any) => void;
    selectedRecommendationDate?: string | null;
    selectedRecommendation?: {[key: string]: any} | null;
}

interface ICreateAfterCareEventState {
    formConfig: typeof IFormConfig;
    isFormValid: boolean;
    isLoading: boolean;
    value: any;
}

class CreateAfterCareEvent extends React.Component<ICreateAfterCareEventProps, ICreateAfterCareEventState> {
    readonly subscriptions: Subscription[] = [];
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;
    @lazyInject('AftercareMapperService') private aftercareMapper: IAftercareMapperService;

    constructor(props: ICreateAfterCareEventProps) {
        super(props);

        this.state = {
            formConfig: createAfterCareFormConfig,
            isFormValid: true,
            isLoading: false,
            value: null
        };

        fixInjectedProperties(this);
    }

    componentDidMount() {
        this.getRecommendationDefinitionList();

        this.subscriptions.push(
            this.onValueStateChange$.pipe(
                filter((data: any) => data && data.changeType === FormControlChangeType.User),
                tap((data: any) => this.onFormValueChange(data.value, data.changeType)),
            ).subscribe()
        );

        if (this.props.selectedRecommendation) {
            this.changeFormControlValues(this.props.selectedRecommendation);
        } else {
            this.updateButtonText('treatmentPlanner.create.dailyEvents.addEvent');
        }

        this.setIntervalRate();
        this.setHourReminderIntervalRate(0, 23);

        if (this.props.selectedRecommendationDate) {
            let formValues: {[key: string]: any} = {};
            if (this.state.value) {
                formValues = Object.assign({}, this.state.value);
            }

            formValues['eventDate'] = this.props.selectedRecommendationDate;
            return this.setState({value: formValues});
        }
    }

    getSnapshotBeforeUpdate(
        prevProps: Readonly<ICreateAfterCareEventProps>,
        prevState: Readonly<ICreateAfterCareEventState>) {
        const prevEventType = prevState.value ? prevState.value.eventType : null;
        return {
            eventType: prevEventType
        };
    };

    componentDidUpdate(
        prevProps: Readonly<ICreateAfterCareEventProps>,
        prevState: Readonly<ICreateAfterCareEventState>,
        snapshot?: any
    ): void {
        if (this.props.retrievedRecommendationDefinitions !== prevProps.retrievedRecommendationDefinitions) {
            this.setEventTypeOptions();
        }

        if (this.state.value !== null &&
            this.state.value.eventType !== snapshot.eventType &&
            this.state.value.eventType !== 'custom' &&
            this.props.retrievedRecommendationDefinitions) {
            let selectedDefinition = this.props.retrievedRecommendationDefinitions['hydra:member']
                .filter((recommendation: {[key: string]: any}) => recommendation.id === this.state.value.eventType);

            if (selectedDefinition && selectedDefinition.length) {
                return this.changeFormControlValues(selectedDefinition[0]);
            }
        }

        if (this.props.selectedRecommendationDate !== prevProps.selectedRecommendationDate &&
            this.props.selectedRecommendationDate) {
            let formValues: {[key: string]: any} = {};
            if (this.state.value) {
                formValues = Object.assign({}, this.state.value);
            }

            formValues['eventDate'] = this.props.selectedRecommendationDate;
            return this.setState({value: formValues});
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        return (
            <Form config={this.state.formConfig}
                  onValueStateChange={this.onValueStateChange}
                  onValidationStateChange={this.onValidationStateChange}
                  value={this.state.value}
                  controlName={'addAfrerCareEventForm'}
                  submitForm={() => this.props.addNewEvent(this.state.value)}/>
        );
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private onValidationStateChange = (controlName: string, isValid: boolean) => {
        this.setState({isFormValid: isValid});
    };

    private onFormValueChange = (value: any, changeType: typeof FormControlChangeType) => {
        Object.keys(value).map((key: string) => {
            if (key === 'eventType' && isNotNullOrUndefined(value[key])) {
                this.setHourReminderIntervalRate(0, 23);
                return this.updateFormControls(value[key]);
            }

            if (key === 'repeatEvent' &&
                value[key] !== null &&
                value[key] !== this.state.value?.repeatEvent) {
                this.updateEventTypeFormControls(value[key])
            }

            if (key === 'reminderUnit' && value[key] !== null) {
                return value[key] === 'hour' ? this.setHourReminderIntervalRate(0, 23) :
                    this.setHourReminderIntervalRate(1, 30);
            }

            if (key === 'consecutiveType' &&
                value[key] !== null) {
                this.updateConsecutiveTypeLabel(value[key]);
            }
        });

        this.setState({value: value});
    };

    private getRecommendationDefinitionList = () => {
        this.props.recommendationDefinitionList(
            `treatment_point_definitions`,
            this.props.authToken
        );
    };

    private updateFormControls(value: string): void {
        const cloneFormConfig = Object.assign({}, this.state.formConfig);
        cloneFormConfig.controls.map((control: any) => {
            if (control.hasOwnProperty("controls")) {
                Object.keys(control.controls).map((key: string) => {
                    if (control.controls[key].disabled === true) {
                        control.controls[key].disabled = false;
                    }
                });
            }

            return control;
        });

        this.setState({
            formConfig: cloneFormConfig,
        });
    }

    private updateEventTypeFormControls(value: string): void {
        const cloneFormConfig = Object.assign({}, this.state.formConfig);
        cloneFormConfig.controls.map((control: any) => {
            if (control.hasOwnProperty("controls")) {
                Object.keys(control.controls).map((key: string) => {
                    if (value === RecommendationDefinitionType.SINGLE) {
                        if (key === 'consecutiveType') {
                            control.controls[key].disabled = true;
                            control.controls[key].validationRules = [];
                        }
                        if (key === 'consecutiveValue') {
                            control.controls[key].disabled = true;
                            control.controls[key].validationRules = [];
                        }
                        if (key === 'intervalValue') {
                            control.controls[key].disabled = true;
                            control.controls[key].validationRules = [];
                        }
                        if (key === 'intervalUnit') {
                            control.controls[key].disabled = true;
                            control.controls[key].validationRules = [];
                        }
                    } else {
                        let requiredField = [
                            { name: ValidationRules.IS_REQUIRED },
                        ];
                        if (key === 'consecutiveType') {
                            control.controls[key].disabled = false;
                            control.controls[key].validationRules = requiredField;
                        }
                        if (key === 'consecutiveValue') {
                            control.controls[key].disabled = false;
                            control.controls[key].validationRules = requiredField;
                        }
                        if (key === 'intervalValue') {
                            control.controls[key].disabled = false;
                            control.controls[key].validationRules = requiredField;
                        }
                        if (key === 'intervalUnit') {
                            control.controls[key].disabled = false;
                            control.controls[key].validationRules = requiredField;
                        }
                    }
                });
            }

            return control;
        });

        this.setState({
            formConfig: cloneFormConfig,
        });
    }

    private setIntervalRate() {
        let repeatValue = 30;
        let options: typeof IMultiselectOption[] = [];
        for (let step = 1; step <= repeatValue; step++) {
            options.push({
                value: step,
                label: step
            })
        }

        createAfterCareFormConfig.controls.map((control: any) => {
            if (control.hasOwnProperty("controls")) {
                Object.keys(control.controls).map((key: string) => {
                    if (key === 'intervalValue') {
                        control.controls[key].multiselectOptions = options;
                    }

                    if (key === 'consecutiveValue') {
                        control.controls[key].multiselectOptions = options;
                    }
                });
            }

            return control;
        });

        this.setState({formConfig: createAfterCareFormConfig});
    }

    private setHourReminderIntervalRate(startValue: number, repeatValue: number) {
        let options: typeof IMultiselectOption[] = [];
        for (let step = startValue; step <= repeatValue; step++) {
            options.push({
                value: step,
                label: step
            })
        }

        createAfterCareFormConfig.controls.map((control: any) => {
            if (control.hasOwnProperty("controls")) {
                Object.keys(control.controls).map((key: string) => {
                    if (key === 'reminderAmount') {
                        control.controls[key].multiselectOptions = options;
                    }
                });
            }

            return control;
        });

        this.setState({formConfig: createAfterCareFormConfig});
    }

    private updateConsecutiveTypeLabel(value: string) {
        createAfterCareFormConfig.controls.map((control: any) => {
            if (control.hasOwnProperty("controls")) {
                Object.keys(control.controls).map((key: string) => {
                    if (key === 'consecutiveValue') {
                        value === 'period' ? control.controls[key].label = 'days' :
                            control.controls[key].label = '';
                    }
                });
            }

            return control;
        });

        this.setState({formConfig: createAfterCareFormConfig});
    }

    private setEventTypeOptions = () => {
        let eventTypes: typeof IMultiselectOption[] = [];
        eventTypes.push({
            label: 'Custom',
            value: 'custom'
        });

        if (this.props.retrievedRecommendationDefinitions &&
            this.props.retrievedRecommendationDefinitions['hydra:member'] &&
            this.props.retrievedRecommendationDefinitions['hydra:member'].length) {

            this.props.retrievedRecommendationDefinitions['hydra:member']
                .filter((item: {[key: string]: any}) => item.structure.type === EventType.AFTERCARE)
                .forEach((recommendation: {[key: string]: any}) => {
                return eventTypes.push({
                    label: recommendation.name,
                    value: recommendation.id
                });
            })
        }

        createAfterCareFormConfig.controls.map((control: any) => {
            if (control.hasOwnProperty("controls")) {
                Object.keys(control.controls).map((key: string) => {
                    if (key === 'eventType') {
                        control.controls[key].multiselectOptions = eventTypes;
                    }
                });
            }

            return control;
        });

        this.setState({formConfig: createAfterCareFormConfig});
    };

    private changeFormControlValues(recommendation: {[key: string]: any}) {
        let recommendationProperties = recommendation?.structure?.properties,
            reminderDetails = this.aftercareMapper.convertDateIntervalToValue(
                recommendationProperties?.notificationPropagationStart
            ),
            intervalDetails = this.aftercareMapper.convertDateIntervalToValue(recommendationProperties?.cycleInterval),
            consecutiveType = recommendation?.structure?.notificationType === RecommendationDefinitionType.SINGLE ?
                null : recommendation?.structure?.notificationType,
            notificationType = recommendation.structure?.notificationType === RecommendationDefinitionType.SINGLE ?
                RecommendationDefinitionType.SINGLE : RecommendationDefinitionType.CONSECUTIVE,
            consecutiveValue = null;

        if (recommendation?.structure?.notificationType === RecommendationDefinitionType.CONSECUTIVE_NUMBER) {
            consecutiveValue = recommendationProperties?.cycleValue;
        }
        if (recommendation?.structure?.notificationType === RecommendationDefinitionType.CONSECUTIVE_PERIOD) {
            consecutiveValue = this.aftercareMapper.convertDateIntervalToDaysAmount(
                recommendationProperties?.cycleValue
            );
        }

        const cloneFormValue = Object.assign({}, this.state.value);
        cloneFormValue['eventName'] = recommendation.name;
        cloneFormValue['eventDescription'] = recommendation.description;
        cloneFormValue['repeatEvent'] = notificationType;
        cloneFormValue['reminderAmount'] = reminderDetails?.amount;
        cloneFormValue['reminderUnit'] = reminderDetails?.unit;
        cloneFormValue['consecutiveType'] = consecutiveType;
        cloneFormValue['consecutiveValue'] = consecutiveValue;
        cloneFormValue['intervalUnit'] = intervalDetails?.unit;
        cloneFormValue['intervalValue'] = intervalDetails?.amount;

        this.setState({value: cloneFormValue});

        this.updateEventTypeFormControls(
            recommendation.structure.notificationType !== RecommendationDefinitionType.SINGLE ?
                RecommendationDefinitionType.CONSECUTIVE :
                RecommendationDefinitionType.SINGLE
        );
        this.updateConsecutiveTypeLabel(consecutiveType);
        this.updateButtonText('treatmentPlanner.create.dailyEvents.addEvent');
    }

    private updateButtonText = (btnText: string) => {
        const updatedFormConfig = Object.assign({}, createAfterCareFormConfig);
        updatedFormConfig.controls.map((control: any) => {
            if (control.hasOwnProperty("controls")) {
                Object.keys(control.controls).map((key: string) => {
                    if (key === 'submitButton') {
                        control.controls[key].btnText = btnText;
                    }
                });
            }

            return control;
        });

        return this.setState({formConfig: updatedFormConfig});
    }
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
        retrievedRecommendationDefinitions: retrievedRecommendationDefinitionListSelector(state)
    }),
    {
        recommendationDefinitionList
    }
)(withTranslation()(withRouter(CreateAfterCareEvent)));
