import * as React from "react";
import {Translation} from 'meditrip-common-web';
import styles from "./styles.module.scss";

interface ITreatmentPlanConfirmationSuccessProps {}

interface ITreatmentPlanConfirmationSuccessState {}

class TreatmentPlanConfirmationSuccess extends React.Component<ITreatmentPlanConfirmationSuccessProps, ITreatmentPlanConfirmationSuccessState> {

    render() {
        return (
            <React.Fragment>
                <div className={styles.successPageWrapper}>
                    <div className={styles.completedIcon}>
                        <div className={styles.checked}/>
                    </div>
                    <div className={styles.successPageContent}>
                        <h2 className={styles.successTitle}>
                            <Translation text={`treatmentPlanner.treatmentPlannerConfirmationSuccess.title`}/>
                        </h2>
                        <p className={styles.successSubTitle}>
                            <Translation text={`treatmentPlanner.treatmentPlannerConfirmationSuccess.subtitle`}/>
                        </p>
                        <p className={styles.successSubTitle}>
                            <Translation text={`treatmentPlanner.treatmentPlannerConfirmationSuccess.description`}/>
                        </p>
                        <div className={styles.buttonWrapper}>
                            <button type="button" name="forward"
                                    className="btn btn-theme"
                                    onClick={() => this.goToPatientPanel()}>
                                <Translation text={`treatmentPlanner.treatmentPlannerConfirmationSuccess.button`}/>
                            </button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    goToPatientPanel = () => {
        window.location.href = `${process.env.REACT_APP_PATIENT_PANEL_URL}`;
    };
}

export default TreatmentPlanConfirmationSuccess;
