import React from 'react';
import {Link, RouteComponentProps} from 'react-router-dom';
import {
    AccountAddressBox,
    authTokenSelector,
    BasicModal, clinicIdSelector,
    CustomCard,
    CustomCardType,
    DateConverter,
    fixInjectedProperties,
    Form,
    FormControlChangeType,
    CustomPagination,
    RestQueryParams,
    Translation,
} from 'meditrip-common-web';
import {initialPatientFormConfig} from "./formConfig";
import {connect} from "react-redux";
import {RootState} from "../../store/reducers";
import {
    patientsListErrorSelector,
    patientsListEventSourceSelector,
    patientsListLoadingSelector,
    retrievedPatientsListSelector
} from "../../store/selectors/patientListSelectors";
import {list, reset} from "../../actions/patient/list";
import {catchError, filter, tap} from "rxjs/operators";
import {BehaviorSubject, Observable, of, Subscription} from "rxjs";
import {lazyInject} from "../../ioc";
import {IAlertManagerService} from "../../service/alertManagerService";
import {noDataDisplay} from "../../utils/runtimeUtils";
import {invitePatientAPI} from "../../api/invitePatientAPI";


interface IConnectedPatientsProps {
    readonly retrieved: any;
    readonly loading: boolean;
    readonly error: string;
    readonly authToken: string;
    readonly clinicId: string;
    readonly eventSource: EventSource;
    readonly list: any;
    readonly reset: any;
}

interface IExternalPatientsProps {
}

interface IPatientsProps extends IConnectedPatientsProps,
    IExternalPatientsProps,
    RouteComponentProps {
}

interface IPatientState {
    localLoader: boolean;
    modalShown: boolean;
    formConfig: any;
    queryParams: typeof RestQueryParams;
    value: any;
}

class Patients extends React.Component<IPatientsProps, IPatientState> {
    private subscriptions: Subscription[] = [];
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;

    constructor(props: IPatientsProps) {
        super(props);

        this.state = {
            localLoader: false,
            modalShown: false,
            formConfig: initialPatientFormConfig,
            queryParams: new RestQueryParams,
            value: null
        };
        fixInjectedProperties(this);
    }

    componentDidMount() {
        this.subscriptions.push(
            this.onValueStateChange$.pipe(
                filter((data: any) => data && data.changeType === FormControlChangeType.User),
                tap((data: any) => this.onFormValueChange(data.value, data.changeType)),
            ).subscribe()
        );
    }

    componentWillUnmount() {
        this.props.reset(this.props.eventSource);
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-xl-12">
                        <div className="view-header">
                            <div className="view-title">
                                <Translation text={'patients.title'}/>
                            </div>
                            <div className="action-container">
                                <button className="btn btn-theme"
                                        type="button"
                                        onClick={() => this.toggleModal()}>
                                    <Translation text={'patients.button.addPatient'}/>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12">

                        <CustomCard showLocalLoader={this.props.loading}>
                            <CustomCard.Body>
                                <table className="data-table">
                                    <thead>
                                    <tr>
                                        <th><Translation text={'patients.table.header.name'}/></th>
                                        <th><Translation text={'patients.table.header.birthdate'}/></th>
                                        <th><Translation text={'patients.table.header.phone'}/></th>
                                        <th><Translation text={'patients.table.header.address'}/></th>
                                        <th className="text-center"><Translation text={'patients.table.header.status'}/></th>
                                        <th className="text-center"/>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.renderTableRows()}
                                    </tbody>
                                </table>

                                <CustomPagination retrieved={this.props.retrieved}
                                                  basePath="dashboard"
                                                  path="patients"
                                                  provider={this.getPatientsList}/>
                            </CustomCard.Body>
                        </CustomCard>
                    </div>
                </div>

                <BasicModal isModalShown={this.state.modalShown} closeModal={this.closeModal}>
                    <CustomCard showLocalLoader={this.state.localLoader} type={CustomCardType.MODAL_CARD}>
                        <CustomCard.Body>
                            <div className="modal-header">
                                <Translation text={'patients.modal.title'}/>
                                <button className="btn-modal-close" onClick={() => this.toggleModal()}>
                                    <span className="feather icon-x"/>
                                </button>
                            </div>
                            <div className="modal-body">
                                <Form config={this.state.formConfig}
                                      onValueStateChange={this.onValueStateChange}
                                      submitForm={this.addPatient}
                                      value={this.state.value}
                                      controlName={'addPatientForm'}/>
                            </div>
                        </CustomCard.Body>
                    </CustomCard>
                </BasicModal>
            </React.Fragment>
        );
    }
    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private onFormValueChange = (value: any, changeType: typeof FormControlChangeType) => {
        this.setState({value: value});
    };

    private renderTableRows() {
        if (!this.props.retrieved || !this.props.retrieved['hydra:member']) {
            return;
        }
        const rows: any[] = [];
        this.props.retrieved['hydra:member'].map((item: any) => {
            if (!item.account) {
                return;
            }

            return rows.push(
                <tr key={item['id']}>
                    <td>{item.account.firstName} {item.account.lastName}</td>
                    <td> {noDataDisplay(item.account.birthdate, <DateConverter date={item.account.birthdate}/>)}
                    </td>
                    <td>{noDataDisplay(item.account.phone)}</td>
                    <td>{noDataDisplay(item.account.address.addressLine1, <AccountAddressBox
                        address={item.account.address}/>)}</td>
                    <td className="text-center align-middle">
                        <div
                            className={`status-wrapper ${item.account.user?.registrationConfirmed ? 'active' : 'inactive'}`}>
                            <p className="status">
                                <Translation
                                    text={`patients.status.${item.account.user?.registrationConfirmed ? 'active' : 'invited'}`}/>
                            </p>
                        </div>
                    </td>
                    <td className="text-right align-middle">
                        <Link className="btn btn-action"
                              to={`/dashboard/patients/${item['id']}`}>
                            <span className="feather icon-search"/>
                        </Link>
                    </td>
                </tr>)
        });

        return rows;
    }

    private toggleModal = () => {
        this.setState({modalShown: !this.state.modalShown, value: null});
    };

    private closeModal = () => {
        return this.setState({modalShown: false, value: null}, () => console.log(this.state.value));
    };


    private addPatient = (event: any, value: any, valid: boolean, touched: boolean) => {
        if (!touched || !valid || !this.props.authToken || !this.props.clinicId) {
            return;
        }

        const account = {
                firstName: value.firstName,
                lastName: value.lastName
            },
            patientEmail = value.email,
            returnUrlForRegisteredUser = `${process.env.REACT_APP_PATIENT_PANEL_URL}/auth/accept-invitation`,
            returnUrlForNewUser = `${process.env.REACT_APP_PATIENT_PANEL_URL}/auth/create-from-invitation`;
        this.setState({localLoader: true});
        this.subscriptions.push(
            this.createPatientAndSendInvitationObservable(this.props.authToken, patientEmail, account, returnUrlForRegisteredUser, returnUrlForNewUser).subscribe());
    };

    private createPatientAndSendInvitationObservable(authToken: string, patientEmail: string, account: any, returnUrlForRegisteredUser: string, returnUrlForNewUser: string): Observable<any> {
        return invitePatientAPI(this.props.clinicId, authToken, patientEmail, account, returnUrlForRegisteredUser, returnUrlForNewUser).pipe(
            catchError((error: any) => {
                this.setState({localLoader: false});
                this.alertManager.handleApiError(error);
                return of();
            }),
            tap(() => {
                this.setState({localLoader: false});
                this.getPatientsList(this.state.queryParams);
                this.alertManager.addAlert('patients.createAndInvitePatientSuccess');
                this.closeModal();
            }),
        );
    }

    private getPatientsList = (searchParams: typeof RestQueryParams) => {
        this.setState({queryParams: searchParams});
        this.props.list(`patients${searchParams.prepareQuery()}`, this.props.authToken);
    }
}

export default connect(
    (state: RootState) => ({
        retrieved: retrievedPatientsListSelector(state),
        loading: patientsListLoadingSelector(state),
        error: patientsListErrorSelector(state),
        eventSource: patientsListEventSourceSelector(state),
        clinicId: clinicIdSelector(state),
        authToken: authTokenSelector(state)
    }),
    {
        list,
        reset
    }
)(Patients);
