import {Observable} from 'rxjs';
import {RestQueryParams} from './base/queryParams';
import {meditripClinicAPI} from './provider/meditripClinic';
import {Address} from '../store/reducers/accountSlice';
import {IInvoiceAddress} from '../service/accountPayloadMapperService';

export function updateAccountDataAPI(
    accountId: string,
    accessToken: string,
    firstName: string | null,
    lastName: string | null,
    birthdate: string | null,
    phone: string | null,
    address: Address | null,
    invoiceAddress: IInvoiceAddress | null,
    residency: string | null,
    timezone: string | null,
): Observable<any> {
    let payload = {
        firstName: firstName,
        lastName: lastName,
        birthdate: birthdate,
        phone: phone,
        address: address,
        invoiceAddress: invoiceAddress,
        residency: residency,
        timezone: timezone
    }
    return meditripClinicAPI.put(
        `update-account/${accountId}`,
        payload,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${accessToken}`,
        },
    );
}

