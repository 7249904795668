import {Observable} from "rxjs";
import {RestQueryParams} from "./base/queryParams";
import {meditripClinicAPI} from "./provider/meditripClinic";

export enum PaymentServiceName {
    STRIPE = 'stripe'
}

export function buySubscriptionDefinitionAPI(
    authToken: string,
    subscriptionId: string,
    paymentServiceName: PaymentServiceName,
): Observable<any> {
    const payload = {
        paymentServiceName: paymentServiceName,
    };
    return meditripClinicAPI.put(
        `subscription_definitions/${subscriptionId}/buy`,
        payload,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`
        }
    );
}
