import {Observable} from "rxjs";
import {RestQueryParams} from "./base/queryParams";
import {meditripCalendarAPI} from "./provider/meditripCalendar";

export interface IRecommendationDefinitionPayload {
    type: string;
    name: string;
    description: string | null;
    accountId: string;
    startNotificationPropagation: string;
    cyclic: boolean | null;
    cycleInterval: string | null;
    treatmentPointEndsAtInterval: string | null;
    notificationType: string;
}

export function createRecommendationDefinitionAPI(
    authToken: string | undefined,
    recommendationDetails: IRecommendationDefinitionPayload
): Observable<any> {
    const payload: any = {
        type: recommendationDetails.type,
        name: recommendationDetails.name,
        description: recommendationDetails.description,
        accountId: recommendationDetails.accountId,
        startNotificationPropagation: recommendationDetails.startNotificationPropagation,
        cyclic: recommendationDetails.cyclic,
        cycleInterval: recommendationDetails.cycleInterval,
        treatmentPointEndsAtInterval: recommendationDetails.treatmentPointEndsAtInterval,
        notificationType: recommendationDetails.notificationType
    };

    return meditripCalendarAPI.post(
        "treatment_point_definitions",
        payload,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`,
        },
    );
}
