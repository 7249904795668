import { Observable } from "rxjs";
import {RestQueryParams} from "meditrip-common-web";
import {meditripInquiryAPI} from "./provider/meditripInquiry";

export enum OnboardingType {
    Onboarding = 'account_onboarding',
    Update = 'account_update'
}

export interface IStripeOnboardingPayload {
    refreshUrl: string; // 'https://clinic.meditrip.solvee.pl/generate_onboarding_link'
    returnUrl: string; // 'https://clinic.meditrip.solvee.pl/'
    onboardingMode: OnboardingType; // 'account_onboarding' | 'account_update'
}

export function generateStripeOnboardingAPI(
    paymentAccountId: string,
    authToken: string,
    payload: IStripeOnboardingPayload
): Observable<any> {
    return meditripInquiryAPI.put(
        `payment_accounts/${paymentAccountId}/generate_onboarding_link`,
        payload,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`
        },
    );
}
