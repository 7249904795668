import { Observable } from "rxjs";
import {IBlockerEvent, RestQueryParams} from "meditrip-common-web";
import {meditripCalendarAPI} from "./provider/meditripCalendar";

export function editSlotsAvailability(
    slotBlockerEvents: typeof IBlockerEvent[],
    calendarValidUntil: string,
    calendarId: string,
    authToken: string
): Observable<any> {
    return meditripCalendarAPI.put(
        `calendar/${calendarId}/edit_slots_availability`,
        {
            slots: slotBlockerEvents,
            calendarValidUntil: calendarValidUntil,
        },
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`
        },
    );
}
