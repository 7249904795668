import React from "react";
import {CustomCard, Form, Translation, IFormHandleChangeService} from "meditrip-common-web";
import { clinicDetailsFormConfig } from "./clinicDetailsFormConfig";
import { fixInjectedProperties, lazyInject } from "../../../ioc";

interface IClinicDetailsConnectedProps {}

interface IClinicDetailsExternalProps {
  readonly clinicProfile: any;
  isLoading: boolean;
}

interface IClinicDetailsProps extends
  IClinicDetailsConnectedProps,
  IClinicDetailsExternalProps {}

interface IClinicDetailsState {
  formConfig: any;
  value: any;
}

class ClinicDetails extends React.Component<IClinicDetailsProps, IClinicDetailsState> {
  @lazyInject('FormHandleChangeService') private formHandleChangeService: typeof IFormHandleChangeService;

  constructor(props: IClinicDetailsProps) {
    super(props);

    this.state = {
      formConfig: clinicDetailsFormConfig,
      value: null
    };
    fixInjectedProperties(this);
  }

  componentDidMount(): void {
    if (this.props.clinicProfile) {
      this.updateFormFromState();
    }
  }

  componentDidUpdate(
    prevProps: Readonly<IClinicDetailsProps>,
    prevState: Readonly<IClinicDetailsState>,
    snapshot?: any
  ): void {
    if (this.props.clinicProfile !== prevProps.clinicProfile) {
      this.updateFormFromState();
    }
  }

  render() {
    return (
      <CustomCard showLocalLoader={this.props.isLoading}>
        <CustomCard.Header>
          <Translation text={'profile.clinicDetails.title'}/>
        </CustomCard.Header>
        <CustomCard.Body>
          <Form config={this.state.formConfig}
                // btnText="Save"//
                value={this.state.value}
                controlName={'clinicDetailsForm'}/>

        </CustomCard.Body>
      </CustomCard>
    );
  }

  private mapAccountToFormData(clinicProfile: any): any {
    return {
      languages: clinicProfile.languages,
      openingHours: clinicProfile.openingHours,
      paymentOptions: clinicProfile.paymentOptions,
      accreditation: clinicProfile.accreditations
    };
  }

  updateFormFromState() {
    this.setState({
      value: this.mapAccountToFormData(this.props.clinicProfile)
    })
  }
}

export default ClinicDetails;
