import React from 'react';
import {Link} from 'react-router-dom';
import {
    authTokenSelector,
    CustomCard,
    Loader,
    CustomPagination,
    RestQueryParams, Toast,
    Translation,
    transformToPriceString
} from "meditrip-common-web";
import {connect} from "react-redux";
import {RootState} from "../../../store/reducers";
import {list, reset} from '../../../actions/calendar/list';
import {
    calendarListLoadingSelector,
    retrievedCalendarListSelector
} from "../../../store/selectors/calendarListSelectors";
import CreateCalendarModal from "./CreateCalendarModal";
import DeleteCalendarModal from "./DeleteCalendarModal";
import OnboardingModal from '../../Billings/OnboardingModal';
import {getAccountDataAPI} from "../../../api/getAccountData";
import {catchError, tap} from "rxjs/operators";
import {of, Subscription} from "rxjs";
import {fixInjectedProperties, lazyInject} from "../../../ioc";
import {IAlertManagerService} from "../../../service/alertManagerService";
import CalendarLimitsModal from "./CalendarLimitsModal";

export enum ModuleConfigType {
    AFTERCARE = 'aftercare',
    AXA_INSURANCE = 'axa_insurance',
    NOTIFICATIONS = 'notifications',
    PAYMENT = 'payment',
    SUBSCRIPTION = 'subscription',
    ONLINE_CONSULTATION = 'online_consultation',
    CALENDAR = 'calendar'
}

interface IConnectedCalendarWidgetsProps {
    readonly retrieved: { [key: string]: any };
    readonly isLoading: boolean;
    readonly eventSource: EventSource;
    readonly list: any;
    readonly reset: any;
    readonly authToken: string;
}

interface ICalendarWidgetsState {
    isLoading: boolean,
    createModalShown: boolean,
    deleteModalShown: boolean,
    calendarLimitsModalShown: boolean,
    chosenCalendarId: string | null,
    calendarModuleConfig: {[key: string]: any} | null
}

class CalendarWidgets extends React.Component<IConnectedCalendarWidgetsProps, ICalendarWidgetsState> {
    readonly subscriptions: Subscription[] = [];
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;

    constructor(props: any) {
        super(props);

        this.state = {
            createModalShown: false,
            deleteModalShown: false,
            calendarLimitsModalShown: false,
            chosenCalendarId: null,
            isLoading: false,
            calendarModuleConfig: null
        };
        fixInjectedProperties(this);
    }

    componentDidMount() {
        this.getModuleConfig();
    }

    componentWillUnmount() {
        this.props.reset(this.props.eventSource);

        if (null !== this.subscriptions) {
            this.subscriptions.forEach(subscription => subscription.unsubscribe());
        }
    }

    render() {
        let isCalendarLimitReached = this.state.calendarModuleConfig &&
            this.state.calendarModuleConfig.calendarsAvailable <= 0;

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-xl-12">
                        <div className="view-header">
                            <div className="view-title">
                                <Translation text={'calendarWidgets.title'}/>
                            </div>
                            <div className="action-container">
                                <button className="btn btn-theme"
                                        type="button"
                                        onClick={() => isCalendarLimitReached ? this.toggleCalendarLimitsModal() : this.toggleCreateModal()}>
                                    <Translation text={'calendar.buttons.create'}/>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-6">
                        <CustomCard showLocalLoader={this.props.isLoading}>
                            <CustomCard.Body>
                                {this.renderCalendarWidgetList()}
                                <CustomPagination retrieved={this.props.retrieved}
                                            basePath="dashboard"
                                            path="consultations/widget"
                                            provider={this.getCalendarsList}
                                            defaultPage={1}
                                            defaultItemsPerPage={10}
                                />
                            </CustomCard.Body>
                        </CustomCard>
                    </div>
                </div>
                <Toast/>
                {this.state.createModalShown &&
                    <CreateCalendarModal createModalShown={this.state.createModalShown}
                                         toggleCreateModal={this.toggleCreateModal}/>
                }
                {this.state.deleteModalShown &&
                    <DeleteCalendarModal deleteModalShown={this.state.deleteModalShown}
                                         calendarId={this.state.chosenCalendarId}
                                         toggleDeleteModal={this.toggleDeleteModal}/>
                }
                <OnboardingModal />
                <CalendarLimitsModal calendarLimitsModalShown={this.state.calendarLimitsModalShown}
                                     toggleCalendarLimitsModal={this.toggleCalendarLimitsModal} />
            </React.Fragment>
        );
    }

    private toggleCreateModal = () => {
        this.setState({createModalShown: !this.state.createModalShown});
    };

    private toggleDeleteModal = (calendarId: string) => {
        this.setState({chosenCalendarId: calendarId, deleteModalShown: !this.state.deleteModalShown});
    };

    private toggleCalendarLimitsModal = () => {
        this.setState({calendarLimitsModalShown: !this.state.calendarLimitsModalShown});
    };

    private renderCalendarWidgetList() {
        if (this.props.isLoading) {
            return <Loader type="local" showLoader={true}/>
        }

        if (!this.props.retrieved ||
            !this.props.retrieved['hydra:member'] ||
            !Array.isArray(this.props.retrieved['hydra:member'])) {
            return <p>There is no data available</p>;
        }

        return (
            <React.Fragment>
                <table className="data-table">
                    <thead>
                    {this.renderTableHeaders()}
                    </thead>
                    <tbody>
                    {this.renderTableRows()}
                    </tbody>
                </table>

            </React.Fragment>
        )
    };

    private renderTableHeaders() {
        return (
            <tr>
                <th><Translation text={'calendarWidgets.calendarName'}/></th>
                <th><Translation text={'calendarWidgets.price'}/></th>
                <th/>
            </tr>
        )
    }

    private getCalendarsList = (searchParams: typeof RestQueryParams) => {
        this.props.list(`calendars${searchParams.prepareQuery()}`, this.props.authToken);
    };

    private renderTableRows() {
        const rows: any[] = [];
        const list = this.props.retrieved['hydra:member'];

        list.map((item: any) => {
            const calendarId = item.id,
                isLinkEnabled = !item.disabledBySystem;

            return rows.push((
                <tr key={item['@id']}>
                    <td>
                        {item.name}
                    </td>
                    <td>
                        {item.price ?
                            transformToPriceString(item.price.currency.code, item.price.amount / 100) :
                            '---'
                        }
                    </td>
                    <td className="text-right">
                        <Link to={`/dashboard/consultations/edit/${item['id']}`}
                              className={`btn btn-action mr-2 ${!isLinkEnabled ? 'disabled' : ''}`}>
                        <span className="feather icon-search"/>
                        </Link>
                        <button className="btn btn-action"
                                onClick={() => this.toggleDeleteModal(calendarId)}>
                            <span className="feather icon-minus-circle"/>
                        </button>
                    </td>
                </tr>
            ))
        });
        return rows;
    }

    private getModuleConfig = () => {
        this.subscriptions.push(
            getAccountDataAPI(this.props.authToken).pipe(
                tap((resp: any) => {
                    let calendarModuleConfig = resp.clinic.moduleConfigs.find((item: {[key: string]: any}) => {
                        return item.module.name === ModuleConfigType.CALENDAR;
                    });
                    this.setState({calendarModuleConfig: calendarModuleConfig.moduleParameters});
                }),
                catchError((error: any) => {
                    this.alertManager.handleApiError(error);
                    return of();
                })
            ).subscribe()
        );
    }
}

export default connect(
    (state: RootState) => ({
        isLoading: calendarListLoadingSelector(state),
        retrieved: retrievedCalendarListSelector(state),
        authToken: authTokenSelector(state),
    }),
    {
        list,
        reset,
    }
)(CalendarWidgets);
