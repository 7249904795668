import { Observable } from "rxjs";
import {RestQueryParams} from "./base/queryParams";
import {meditripClinicAPI} from "./provider/meditripClinic";

export function getAfterCareRecommendationAPI(
    accessToken: string,
    recommendationId: string
): Observable<any> {
    return meditripClinicAPI.get(
        `treatment_points/${recommendationId}`,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${accessToken}`,
        },
    );
}
