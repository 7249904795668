import { Observable } from "rxjs";
import { RestQueryParams } from "meditrip-common-web";
import {meditripClinicAPI} from "./provider/meditripClinic";

export function getSubscriptionPlansAPI(): Observable<any> {
    return meditripClinicAPI.get(
        `subscription_definitions?itemsPerPage=100`,
        new RestQueryParams(),
    );
}
