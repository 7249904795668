import {combineEpics} from 'redux-observable';
import {
  changePasswordEpic,
  clinicEpic,
  patientEpic,
  reauthenticateEpic,
  registrationEpic,
  renewAuthTokenEpic,
  resetPasswordEpic,
  calendarEpic
} from 'meditrip-common-web';
import loginEpic from "./loginEpic";

export const rootEpic = combineEpics(
   loginEpic,
   registrationEpic,
   resetPasswordEpic,
   renewAuthTokenEpic,
   changePasswordEpic,
   clinicEpic,
   patientEpic,
   reauthenticateEpic,
   calendarEpic
);
