import {
    clinicConsultationDuration,
    clinicOpeningHours,
    FormControlType,
    IFormConfig,
    MultiSelectType,
    ValidationRules
} from 'meditrip-common-web';
import store from '../../../../../store';
import {calendarSettingsSelector} from '../../../../../store/selectors/calendarSelectors';

export const calendarSettingsConfig: typeof IFormConfig = {
    class: '',
    dataAccessor: (data: any) => data, // data is not grouped
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);

        return previousStateSnapshot;
    },
    controls: [
        {
            controlType: 'group',
            key: 'clinic_opening_hours',
            class: 'form-row',
            controls: {
                availableFrom: {
                    controlType: 'control',
                    defaultValue: '',
                    placeholder: 'Start hour',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.SINGLE,
                    label: 'Start hour',
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                    ],
                    multiselectOptions: () => {
                        const calendarSettings = calendarSettingsSelector(store.getState());
                        if ((calendarSettings !== null) && calendarSettings.availableUntil !== null) {
                            const availableUntil = calendarSettings.availableUntil;
                            return clinicOpeningHours.map((openingHour: any) =>
                                ({
                                    value: openingHour.value,
                                    label: openingHour.label,
                                    isDisabled: openingHour.value >= availableUntil,
                                }))
                        }
                        return clinicOpeningHours;
                    },
                    hostClass: 'col-xl-4',
                    inputDataMapper: (data: any) => {
                        if (!data) {
                            return [];
                        }

                        return clinicOpeningHours
                            .filter((openingHour: any) => openingHour.value === data)
                            .map((timezone: any) => ({
                                value: timezone.value,
                                label: timezone.label,
                            }));
                    },
                    outputDataMapper: (data: any) => {
                        if (Array.isArray(data)) {
                            return (data.length > 0) ? data[0].value : null;
                        }
                        if (data && typeof data === 'object') {
                            return data.value;
                        }

                        return data;
                    },
                },
                availableUntil: {
                    controlType: 'control',
                    defaultValue: '',
                    placeholder: 'Last hour',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.SINGLE,
                    label: 'Last hour',
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                    ],
                    multiselectOptions: () => {
                        const calendarSettings = calendarSettingsSelector(store.getState());
                        if (calendarSettings && calendarSettings.availableFrom) {
                            const availableFrom = calendarSettings.availableFrom;

                            return clinicOpeningHours.map((openingHour: any) => ({
                                value: openingHour.value,
                                label: openingHour.label,
                                isDisabled: openingHour.value <= availableFrom,
                            }))
                        }
                        return clinicOpeningHours;
                    },
                    hostClass: 'col-xl-4',
                    inputDataMapper: (data: any) => {
                        if (!data) {
                            return [];
                        }

                        return clinicOpeningHours
                            .filter((openingHour: any) => openingHour.value === data)
                            .map((openingHour: any) => ({
                                value: openingHour.value,
                                label: openingHour.label,
                            }));
                    },
                    outputDataMapper: (data: any) => {
                        if (Array.isArray(data)) {
                            return (data.length > 0) ? data[0].value : null;
                        }
                        if (data && typeof data === 'object') {
                            return data.value;
                        }

                        return data;
                    }
                },
                slotLength: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.SINGLE,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                    ],
                    placeholder: 'Duration',
                    label: 'Duration',
                    multiselectOptions: clinicConsultationDuration,
                    hostClass: 'col-xl-4',
                    disabled: true,
                    inputDataMapper: (data: any) => {
                        if (!data) {
                            return [];
                        }

                        return clinicConsultationDuration
                            .filter((duration: any) => duration.value === data)
                            .map((duration: any) => ({
                                value: duration.value,
                                label: duration.label,
                            }));
                    },
                    outputDataMapper: (data: any) => {
                        if (Array.isArray(data)) {
                            return (data.length > 0) ? data[0].value : null;
                        }
                        if (data && typeof data === 'object') {
                            return data.value;
                        }

                        return data;
                    }
                }
            }
        }
    ]
};
