import {injectable} from 'inversify';
import {fixInjectedProperties} from '../ioc';
import {isNullOrUndefined} from "../utils/runtimeUtils";

export interface IAftercareMapperService {
    convertValueToDateInterval(value: number, unit: string): string;
    calculateRecommendationEndDate(
        startDate: string,
        intervalValue: number,
        intervalUnit: string,
        consecutiveUnit: string,
        consecutiveValue: number | string
    ): string | null;
    calculateNotificationPropagationDate(startDate: string, intervalValue: number, intervalUnit: string): string;
    treatmentPointStartDate(date: string, hour: number, dayPeriod: string): string;
    convertToRecommendationEndsAtInterval(
        consecutiveType: string,
        consecutiveValue: number,
        intervalUnit: string,
        intervalValue: number
    ): string | null;
    convertToNotificationPropagationValue(
        notificationStartDate: string,
        treatmentPointStartDate: string
    ): {[key: string]: any};
    convertDateIntervalToValue(dateInterval: string | null): {[key: string]: any} | null;
    convertDateIntervalToDaysAmount(dateInterval: string | null): number | null;
    convertDateToConsecutiveValue(
        consecutiveType: string,
        treatmentPointEndsAt: string,
        treatmentPointStartsAt: string,
        intervalValue: number,
        intervalUnit: string
    ): number | string | null;
    getRecurringEvents(
        baseEvent: {[key: string]: any},
        consecutiveType: string,
        consecutiveAmount: number | Date,
        intervalValue: number,
        intervalUnit: string): {[key: string]: any}[];
}


@injectable()
class AftercareMapperService implements IAftercareMapperService {
    constructor() {
        fixInjectedProperties(this);
    }

    public convertValueToDateInterval(value: number, unit: string): string {
        // if (isNullOrUndefined(value) || isNullOrUndefined(unit)) {
        //     return null;
        // }

        let hourInterval = '0',
            dayInterval = '0',
            monthInterval = '0',
            yearInterval = '0';

        if (unit === 'hour') {
            hourInterval = value.toString();
        }

        if (unit === 'day') {
            if(value < 28) {
                dayInterval = value.toString();
            }

            dayInterval = (value % 28).toString();
            monthInterval = Math.floor(value / 28).toString();
        }

        if (unit === 'month') {
            if (value < 12) {
                monthInterval = value.toString();
            }

            monthInterval = (value % 12).toString();
            yearInterval = Math.floor(value / 12).toString();
        }

        if (unit === 'week') {
            if (value < 4) {
                dayInterval = (value * 7).toString();
            }

            monthInterval = (Math.floor(value / 4)).toString();
            dayInterval = ((value % 4) * 7).toString();
        }

        return `P${yearInterval}Y${monthInterval}M${dayInterval}DT${hourInterval}H0M0S`;
    }

    public calculateRecommendationEndDate(
        startDate: string,
        intervalValue: number,
        intervalUnit: string,
        consecutiveUnit: string,
        consecutiveValue: number | string
    ): string | null {
        if (isNullOrUndefined(startDate) ||
            isNullOrUndefined(intervalValue) ||
            isNullOrUndefined(intervalUnit) ||
            isNullOrUndefined(consecutiveUnit) ||
            isNullOrUndefined(consecutiveValue)) {
            return  null;
        }

        if (consecutiveUnit === 'period') {
            return new Date(consecutiveValue).toISOString();
        }

        let date = new Date(startDate);
        let days;
        if (intervalUnit === 'week') {
            days = intervalValue * 7;
        } else if (intervalUnit === 'month') {
            days = intervalValue * 28;
        } else {
            days = intervalValue;
        }

        let frequency = days * Number(consecutiveValue);
        let treatmentPointEndDate = date.setDate(date.getDate() + frequency);
        return new Date(treatmentPointEndDate).toISOString();
    }

    public calculateNotificationPropagationDate(startDate: string, intervalValue: number, intervalUnit: string): string {
        let date = new Date(startDate);
        let days;
        if (intervalUnit === 'week') {
            days = intervalValue * 7;
            let treatmentPointEndDate = date.setDate(date.getDate() - days);
            return new Date(treatmentPointEndDate).toISOString();
        } else if (intervalUnit === 'month') {
            days = intervalValue * 28;
            let treatmentPointEndDate = date.setDate(date.getDate() - days);
            return new Date(treatmentPointEndDate).toISOString();
        } else if (intervalUnit === 'day') {
            days = intervalValue;
            let treatmentPointEndDate = date.setDate(date.getDate() - days);
            return new Date(treatmentPointEndDate).toISOString();
        }

        let treatmentPointEndDate = date.setHours(date.getHours() - intervalValue);
        return new Date(treatmentPointEndDate).toISOString();
    }

    public treatmentPointStartDate(date: string, hour: number, dayPeriod: string): string {
        let recommendationDate = new Date(date);
        if (dayPeriod === 'am') {
            recommendationDate.setHours(hour);
        } else {
            recommendationDate.setHours(hour + 12);
        }

        // let timezoneOffset = (new Date(recommendationDate)).getTimezoneOffset() * 60000;
        // let localISOTime = (new Date(new Date(recommendationDate).getTime() - timezoneOffset)).toISOString().slice(0,-1);
        // console.log('localISOTime:', localISOTime);

        return new Date(recommendationDate).toISOString();
    }

    public convertToRecommendationEndsAtInterval(
        consecutiveType: string,
        consecutiveValue: number,
        intervalUnit: string,
        intervalValue: number
    ): string | null {
        if (isNullOrUndefined(consecutiveType) ||
            isNullOrUndefined(consecutiveValue) ||
            isNullOrUndefined(intervalUnit) ||
            isNullOrUndefined(intervalValue)) {
            return null;
        }

        if (consecutiveType === 'period') {
            return `P0Y0M${consecutiveValue}DT0H0M0S`;
        }

        let days;
        if (intervalUnit === 'week') {
            days = intervalValue * 7;
        } else if (intervalUnit === 'month') {
            days = intervalValue * 28;
        } else {
            days = intervalValue;
        }

        let frequency = days * consecutiveValue;
        let dayInterval = (frequency % 28).toString();
        let monthInterval = Math.floor(frequency / 28).toString();

        return `P0Y${monthInterval}M${dayInterval}DT0H0M0S`;
    }

    public convertDateIntervalToValue(dateInterval: string | null): {[key: string]: any} | null {
        if (!dateInterval) {
            return null;
        }

        let year = dateInterval.substring(
            dateInterval.lastIndexOf("P") + 1,
            dateInterval.lastIndexOf("Y")
        );

        let month = dateInterval.substring(
            dateInterval.lastIndexOf("Y") + 1,
            dateInterval.indexOf("M")
        );

        let day = dateInterval.substring(
            dateInterval.indexOf("M") + 1,
            dateInterval.lastIndexOf("D")
        );

        let hour = dateInterval.substring(
            dateInterval.indexOf("T") + 1,
            dateInterval.indexOf("H")
        );

        let unit = 'day';
        let amount = Number(day);

        if (Number(month) > 0) {
            unit = 'month';
            amount = Number(month);
        }

        if (amount >= 7 && amount % 7 === 0) {
            unit = 'week';
            amount = Number(day) / 7;
        }

        if (Number(year) === 0 &&
            Number(month) === 0 &&
            Number(day) === 0) {
            unit = 'hour';
            amount = Number(hour);
        }

        let reminderDetails = {
            amount: amount,
            unit: unit
        };

        return reminderDetails;
    }

    public convertToNotificationPropagationValue(notificationStartDate: string, treatmentPointStartDate: string): {[key: string]: any} {
        let hoursAmount = Math.round(
            (new Date(treatmentPointStartDate).getTime() - new Date(notificationStartDate).getTime())/(1000*60*60)
        );

        let unit = 'hour';
        let amount = hoursAmount;

        if (hoursAmount > 23) {
            let daysAmount = hoursAmount / 24;
            unit = 'day';
            amount = daysAmount;

            if (daysAmount >= 28 && daysAmount % 28 === 0) {
                unit = 'month';
                amount = daysAmount / 28;
            }

            if (daysAmount >= 7 && daysAmount % 7 === 0) {
                unit = 'week';
                amount = daysAmount / 7;
            }
        }

        let reminderDetails = {
            amount: amount,
            unit: unit
        };
        return reminderDetails;
    }

    public convertDateToConsecutiveValue(
        consecutiveType: string,
        treatmentPointEndsAt: string,
        treatmentPointStartsAt: string,
        intervalValue: number,
        intervalUnit: string
    ): number | string | null {
        if (!consecutiveType ||
            !treatmentPointEndsAt ||
            !treatmentPointStartsAt ||
            !intervalValue ||
            !intervalUnit) {
            return null;
        }

        if (consecutiveType === 'period') {
            return treatmentPointEndsAt.split('T')[0];
        }

        let recommendationDuration = Math.ceil(
            (new Date(treatmentPointEndsAt).getTime() - new Date(treatmentPointStartsAt).getTime())/(1000*60*60*24)
        );

        if (intervalUnit === 'week') {
            return (recommendationDuration / 7) / intervalValue;
        }

        if (intervalUnit === 'month') {
            return (recommendationDuration / 28) / intervalValue;
        }

        return recommendationDuration / intervalValue;
    }

    public convertDateIntervalToDaysAmount(dateInterval: string | null): number | null {
        if (!dateInterval) {
            return null;
        }

        let year = dateInterval.substring(
            dateInterval.lastIndexOf("P") + 1,
            dateInterval.lastIndexOf("Y")
        );

        let month = dateInterval.substring(
            dateInterval.lastIndexOf("Y") + 1,
            dateInterval.indexOf("M")
        );

        let day = dateInterval.substring(
            dateInterval.indexOf("M") + 1,
            dateInterval.lastIndexOf("D")
        );

        let amount = Number(day);

        if (Number(month) > 0) {
            amount = Number(month) * amount;
        }

        return amount;
    }

    public getRecurringEvents = (
        baseEvent: {[key: string]: any},
        consecutiveType: string,
        consecutiveAmount: number,
        intervalValue: number,
        intervalUnit: string): {[key: string]: any}[] => {

        let treatmentPointStartDate = new Date(baseEvent.treatmentPointStartsAt);
        let startNotificationPropagation = new Date(baseEvent.startNotificationPropagation);
        const updatedEvent = Object.assign({}, baseEvent);
        let eventsList = [];
        eventsList.push(updatedEvent);

        let interval;
        if (intervalUnit === 'week') {
            interval = intervalValue * 7;
        } else if (intervalUnit === 'month') {
            interval = intervalValue * 28;
        } else {
            interval = intervalValue;
        }

        if (consecutiveType === 'number' ||
            (consecutiveType === 'period' && intervalUnit === 'day')) {
            for (let i = 1; i <= consecutiveAmount; i++) {
                let newDate = treatmentPointStartDate.setDate(treatmentPointStartDate.getDate() + interval);
                let reminderDate = startNotificationPropagation.setDate(startNotificationPropagation.getDate() + interval);
                let event = Object.assign({}, baseEvent);
                event['treatmentPointStartsAt'] = new Date(newDate).toISOString();
                event['startNotificationPropagation'] = new Date(reminderDate).toISOString();
                eventsList.push(event);
            }
        }

        let tillDate = new Date(baseEvent.treatmentPointStartsAt).setDate(new Date(baseEvent.treatmentPointStartsAt).getDate() + consecutiveAmount);
        if (consecutiveType === 'period' && intervalUnit !== 'day') {
            for (let i = 1; i <= interval; i++) {
                let newDate = new Date(baseEvent.treatmentPointStartsAt).setDate(new Date(baseEvent.treatmentPointStartsAt).getDate() + interval * i);
                let reminderDate = startNotificationPropagation.setDate(startNotificationPropagation.getDate() + interval);
                let event = Object.assign({}, baseEvent);
                event['treatmentPointStartsAt'] = new Date(newDate).toISOString();
                event['startNotificationPropagation'] = new Date(reminderDate).toISOString();

                if(new Date(newDate).getTime() < new Date(tillDate).getTime()) {
                    eventsList.push(event);
                }
            }
        }

        return eventsList;
    };
}

export default AftercareMapperService;
