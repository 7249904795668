import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {RootState} from "../../../store/reducers";
import {
    authTokenSelector,
    BasicModal,
    CustomCard,
    CustomCardType,
    Form,
    FormControlChangeType,
    IFormConfig,
    Translation
} from "meditrip-common-web";
import {connect} from "react-redux";
import {WithTranslation, withTranslation} from "react-i18next";
import {paymentMethodFormConfig} from "./paymentMethodFormConfig";
import {BehaviorSubject, of, Subscription} from "rxjs";
import {catchError, debounceTime, filter, map, tap} from "rxjs/operators";
import {CardElement} from '@stripe/react-stripe-js';
import {StripeCardElement} from '@stripe/stripe-js';
import {attachPaymentMethodAPI} from "../../../api/attachPaymentMethod";
import {buySubscriptionDefinitionAPI, PaymentServiceName} from "../../../api/buySubscriptionDefiniton";
import {changePaymentAccountData, PaymentAccount} from '../../../store/reducers/accountSlice';
import {IAlertManagerService} from "../../../service/alertManagerService";
import {fixInjectedProperties, lazyInject} from "../../../ioc";
import {detachPaymentMethodAPI} from "../../../api/detachPaymentMethod";
import {subscriptionSelector} from "../../../store/selectors/accountSelectors";


interface IConnectedAddPaymentMethodModalProps {
    readonly authToken: string;
    readonly changePaymentAccountData: typeof changePaymentAccountData;
    readonly subscription: any;
}

interface IAddPaymentMethodModalProps extends IConnectedAddPaymentMethodModalProps,
    RouteComponentProps,
    WithTranslation {
    isModalShown: boolean;
    paymentAccount: PaymentAccount | null;
    toggleModal: any;
}

interface IAddPaymentMethodModalState {
    readonly formConfig: typeof IFormConfig;
    readonly value: any;
    isFormValid: boolean;
    isLoading: boolean;
    isCardAttached: boolean;
}

class AddPaymentMethodModal extends React.Component<IAddPaymentMethodModalProps, IAddPaymentMethodModalState> {
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    readonly subscriptions: Subscription[] = [];
    private stripe: any;
    private elements: any;

    constructor(props: IAddPaymentMethodModalProps) {
        super(props);

        this.state = {
            value: null,
            formConfig: paymentMethodFormConfig,
            isFormValid: true,
            isLoading: false,
            isCardAttached: false
        };

        fixInjectedProperties(this);
    }

    componentDidMount() {
        this.subscriptions.push(
            this.onValueStateChange$.pipe(
                filter((data: any) => data && data.changeType === FormControlChangeType.User),
                debounceTime(500),
                tap((data: any)  => this.onFormValueChange(data.value)),
            ).subscribe()
        );

        if (this.props.paymentAccount && this.props.paymentAccount.paymentAccountVendorData.customerPaymentMethodId) {
            this.setState({isCardAttached: true})
        }
    }

    componentDidUpdate(
        prevProps: Readonly<IAddPaymentMethodModalProps>,
        prevState: Readonly<IAddPaymentMethodModalState>,
        snapshot?: any
    ): void {}



    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        return (
            <React.Fragment>
                <BasicModal isModalShown={this.props.isModalShown} closeModal={this.props.toggleModal}>
                    <CustomCard showLocalLoader={this.state.isLoading} type={CustomCardType.MODAL_CARD}>
                        <CustomCard.Body>
                            <div className="modal-header">
                                <p>
                                    {!this.state.isCardAttached ? (<Translation text={'billings.paymentMethods.addPaymentMethod.add'}/>) :
                                        (<Translation text={'billings.paymentMethods.addPaymentMethod.update'}/>)}
                                </p>
                                <button className="btn-modal-close" onClick={() => this.props.toggleModal()}>
                                    <span className="feather icon-x"/>
                                </button>
                            </div>
                            <div className="modal-body">
                                <Form config={this.state.formConfig}
                                      controlName={'paymentMethodForm'}
                                      onValueStateChange={this.onValueStateChange}
                                      onValidationStateChange={this.onValidationStateChange}
                                      submitForm={this.addPaymentMethod}
                                />
                            </div>
                        </CustomCard.Body>
                    </CustomCard>
                </BasicModal>
            </React.Fragment>
        );
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private onValidationStateChange = (controlName: string, isValid: boolean) => {
        this.setState({isFormValid: isValid});
    };

    private onFormValueChange = (value: any) => {
        const mappedValue: any = {};
        Object.keys(value).forEach((key: string) => {
            let fieldValue = value[key];
            if (undefined === fieldValue || null === fieldValue) {
                mappedValue[key] = null;

                return;
            }
            if (key === 'stripecard') {
                fieldValue = Object.assign({}, fieldValue);
                this.stripe = fieldValue['stripe'];
                this.elements = fieldValue['elements'];
                delete fieldValue['stripe'];
                delete fieldValue['elements'];
            } else {
                mappedValue[key] = fieldValue;
            }
        });
    };

    private addPaymentMethod = (event: any, value: any, valid: boolean, touched: boolean) => {
        this.setState({isLoading: true});
        const createPaymentMethodPayload = {
            type: 'card',
            card: this.elements.getElement(CardElement) as StripeCardElement,
            billing_details: {
                name: value.cardholderName,
            },
        };

        if (!this.state.isCardAttached) {
            return this.addStripePaymentMethod(createPaymentMethodPayload);
        } else {
            return this.detachCardFromStripe(createPaymentMethodPayload);
        }
    };

    private addStripePaymentMethod = (createPaymentMethodPayload: {[key: string]: any}) => {
        return this.stripe.createPaymentMethod(createPaymentMethodPayload)
            .then((paymentMethod: any) => {
                if (this.props.paymentAccount !== null &&
                    this.props.paymentAccount !== undefined &&
                    this.props.paymentAccount.id) {
                    return this.attachCardToStripe(this.props.paymentAccount.id, paymentMethod.paymentMethod.id)
                }
            });
    };

    private attachCardToStripe = (paymentAccountId: string, paymentMethodId: string) => {
        return attachPaymentMethodAPI(
            this.props.authToken,
            paymentAccountId,
            PaymentServiceName.STRIPE,
            paymentMethodId
        ).pipe(
            map((paymentData: any) => {
                const vendorData = paymentData.paymentAccountVendorData,
                    paymentAccount: PaymentAccount = {
                        id: paymentData.id,
                        paymentAccountVendorData: {
                            accountBankTransferBankName: vendorData.accountBankTransferBankName,
                            accountBankTransferCountry: vendorData.accountBankTransferCountry,
                            accountBankTransferCurrency: vendorData.accountBankTransferCurrency,
                            accountBankTransferLastFour: vendorData.accountBankTransferLastFour,
                            accountEmail: vendorData.accountEmail,
                            chargesEnabled: vendorData.chargesEnabled,
                            customerId: vendorData.customerId,
                            customerPaymentMethodBrand: vendorData.customerPaymentMethodBrand,
                            customerPaymentMethodExpMonth: vendorData.customerPaymentMethodExpMonth,
                            customerPaymentMethodExpYear: vendorData.customerPaymentMethodExpYear,
                            customerPaymentMethodId: vendorData.customerPaymentMethodId,
                            customerPaymentMethodLastFour: vendorData.customerPaymentMethodLastFour,
                            customerPaymentMethodName: vendorData.customerPaymentMethodName,
                            paymentAccountId: vendorData.paymentAccountId
                        },
                        paymentAccountVendorType: paymentData.paymentAccountVendorType
                    };
                this.props.changePaymentAccountData(paymentAccount);
                this.setState({isLoading: false});
                this.alertManager.addAlert('Payment method was successfully added');
            }),
            tap(() => {
               if (
                   this.props.subscription &&
                   this.props.subscription.proposedPlans &&
                   this.props.subscription.proposedPlans[0] &&
                   !this.props.subscription.currentSubscriptionEntry
               ) {
                    return this.buyPlan(this.props.subscription.proposedPlans[0].id)
               }
                this.props.toggleModal();
            }),
            catchError((error: any) => {
                this.setState({isLoading: false});
                return of(this.alertManager.handleApiError(error));
            })
        ).subscribe()
    };

    private detachCardFromStripe = (createPaymentMethodPayload: {[key: string]: any}) => {
        if (this.props.paymentAccount &&
            this.props.paymentAccount.id &&
            this.props.paymentAccount.paymentAccountVendorData &&
            this.props.paymentAccount.paymentAccountVendorData.customerPaymentMethodId) {
            return detachPaymentMethodAPI(
                this.props.authToken,
                this.props.paymentAccount.id,
                PaymentServiceName.STRIPE,
                this.props.paymentAccount.paymentAccountVendorData.customerPaymentMethodId
            ).pipe(
                map((paymentData: any) => {
                return this.addStripePaymentMethod(createPaymentMethodPayload);
                }),
                catchError((error: any) => {
                    this.setState({isLoading: false});
                    return of(this.alertManager.handleApiError(error));
                })
            ).subscribe()
        }
    };

    private buyPlan = (planId: string) => {
        return this.subscriptions.push(
            buySubscriptionDefinitionAPI(
                this.props.authToken,
                planId,
                PaymentServiceName.STRIPE
            ).pipe(
                map(() => {
                    this.alertManager.addAlert('Your subscription plan has been successfully updated');
                }),
                catchError((error: any) => {
                    return of(this.alertManager.handleApiError(error));
                })
            ).subscribe()
        )
    };
}

export default withTranslation()(connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
        subscription: subscriptionSelector(state)
    }),
    {
        changePaymentAccountData
    }
)(withRouter(AddPaymentMethodModal)));
