import React from "react";
import { connect } from "react-redux";
import { RootState } from "../../../store/reducers";
import {
    accountLoadingSelector,
    authTokenSelector,
    clinicLogoUrlSelector,
    CustomCard,
    selectClinic,
    Translation,
    updateClinicLogoStart
} from "meditrip-common-web";
import { Uploader } from "rsuite";

export interface IDefaultUploadList {
  name: string;
  fileKey: number;
  url: string;
}

interface IClinicLogoExternalProps {}

interface IClinicLogoPropsConnectedProps {
  readonly authToken: string;
  readonly logo: any;
  readonly clinic: any;
  readonly isLoading: boolean;
  readonly updateClinicLogoStart: typeof updateClinicLogoStart;
}

interface IClinicLogoProps extends
  IClinicLogoExternalProps,
  IClinicLogoPropsConnectedProps {}

interface IClinicLogoState {
  logo: string | Blob | null;
  defaultLogoList: IDefaultUploadList[] | [];
  isLoading: boolean;
  value: any[];
}

class ClinicLogo extends React.Component<IClinicLogoProps, IClinicLogoState> {
  constructor(props: IClinicLogoProps) {
    super(props);

      this.state = {
          logo: null,
          value: [],
          defaultLogoList: [],
          isLoading: false
      };
  }

    componentDidUpdate(
      prevProps: Readonly<IClinicLogoProps>,
      prevState: Readonly<IClinicLogoState>,
      snapshot?: any
    ): void {
      if(this.props.isLoading !== prevProps.isLoading
        && !this.props.isLoading) {
        this.setState({isLoading: false})
      }

      if(this.props.logo !== prevProps.logo) {
        this.setState({
          defaultLogoList: [{
            name: 'Clinic logo',
            fileKey: 1,
            url: this.props.logo.contentUrl
          }]
        })
      }
    }

  render() {
    return (
        <CustomCard showLocalLoader={this.state.isLoading}>
            <CustomCard.Header>
                <Translation text={'setting.clinicLogo.title'}/>
            </CustomCard.Header>
            <CustomCard.Body>
                <form>
                    <Uploader draggable
                              removable={false}
                              accept="image/x-png, image/jpeg"
                              listType="picture-text"
                              onChange={this.handleChange}
                              autoUpload={false}
                              fileList={this.state.value && this.state.value.length ?
                                  this.state.value : this.state.defaultLogoList}
                              renderFileInfo={(file, fileElement) => {
                                  return this.renderCustomItemPreview(file, fileElement)
                              }}>
                        <div>
                            <i className="feather icon-upload-cloud" />
                            <p>Drop you file here or <span>choose from your computer</span></p>
                        </div>
                    </Uploader>
                    <div className="button-container mt-4">
                        <button className="btn btn-primary"
                                type="button"
                                // disabled={this.state.isFormDisabled}
                                onClick={() => this.changeLogo()}>Update logo
                        </button>
                    </div>
                </form>
            </CustomCard.Body>
        </CustomCard>
    );
  }


    private handleChange = (file: any) => {
      if (!file.length) {
         return this.setState({value: [], logo: null});
      }

      return this.setState({
        value: file.slice(file.length - 1),
        logo: file.length > 1 ? file[1].blobFile : file[0].blobFile,
      })
    };


  private changeLogo = () => {
    if (!this.state.logo) {
        return;
    }

     this.setState({isLoading: true});

    if (this.state.logo) {
      const formData = new FormData();
      const imageData = this.state.logo;
      formData.append('file', imageData);
      formData.append('public', 'true');

      window.fetch(
        `${process.env.REACT_APP_AUTH_API_URL}/media_objects`,
        {
          method: 'POST',
          body: formData,
          headers: new Headers({
            'Authorization': 'Bearer ' + this.props.authToken
          })
        }
      ).then((resp: any) => {
        return resp.json().then( (fileUploadObj: any) => {
          const imageId = fileUploadObj['@id'].replace('/media_object/', '');
          this.props.updateClinicLogoStart(this.props.clinic.id, imageId);
          this.setState({isLoading: false});
        })
      });
    }
  };

  private deleteFile = () => {
      console.log('delete files');
  };

  private renderCustomItemPreview = (file: any, fileElement: any) => {
      return (
          <div className="rs-file-custom-content">
              <p>{file.name}</p>
              <p>{this.formatBytes(file?.blobFile?.size)}</p>
              <button className="rs-remove-file"
                      type="button"
                      onClick={() => this.deleteFile()}>
                  <i className="feather icon-x" />
                  <span>
                      <Translation text={'buttons.delete'} />
                  </span>
              </button>
          </div>
      );
  };

  private formatBytes(bytes: number | undefined, decimals = 2) {
      if (bytes === 0 || bytes === undefined) return '0 Bytes';

      const k = 1024,
        dm = decimals < 0 ? 0 : decimals,
        sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
}

export default connect(
  (state: RootState) => ({
    authToken: authTokenSelector(state),
    logo: clinicLogoUrlSelector(state),
    clinic: selectClinic(state),
    isLoading: accountLoadingSelector(state)
  }),
  {
    updateClinicLogoStart,
  }
)(ClinicLogo);
