import React from "react";
import {CustomCard, Form, Translation} from "meditrip-common-web";
import { generalInformationFormConfig} from "./generalInformationFormConfig";

interface IGeneralInformationConnectedProps {}

interface IGeneralInformationExternalProps {
  readonly clinicProfile: {[key: string]: any} | null;
  readonly isLoading: boolean;
}

interface IGeneralInformationProps extends
  IGeneralInformationConnectedProps,
  IGeneralInformationExternalProps {}

interface IGeneralInformationState {
  formConfig: any;
  value: any;
}

class GeneralInformation extends React.Component<IGeneralInformationProps, IGeneralInformationState> {

  constructor(props: IGeneralInformationProps) {
    super(props);
      this.state = {
        formConfig: generalInformationFormConfig,
        value: null
      };
    }

    componentDidMount(): void {
      if (this.props.clinicProfile) {
        this.updateFormFromState();
      }
    }

  componentDidUpdate(
    prevProps: Readonly<IGeneralInformationProps>,
    prevState: Readonly<IGeneralInformationState>,
    snapshot?: any
  ): void {
    if (this.props.clinicProfile !== prevProps.clinicProfile) {
      this.updateFormFromState();
    }
  }

  render() {
    return (
      <CustomCard showLocalLoader={this.props.isLoading}>
        <CustomCard.Header>
          <Translation text={'profile.generalInformation.title'}/>
        </CustomCard.Header>
        <CustomCard.Body>
          <Form config={this.state.formConfig}
              // btnText="Save"//
              value={this.state.value}
              controlName={'generalInformationForm'}/>

        </CustomCard.Body>
      </CustomCard>
    );
  }

  private mapAccountToFormData(clinicProfile: any): any {
    return {
      overview: clinicProfile.description,
      location: clinicProfile.localization,
      localAttraction: clinicProfile.localAttractions,
      maps: clinicProfile.localization,
      extraServices: clinicProfile.extraServices,
    };
  }

  updateFormFromState() {
    this.setState({
      value: this.mapAccountToFormData(this.props.clinicProfile)
    })
  }
}

export default GeneralInformation;
