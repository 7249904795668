import {Observable} from "rxjs";
import {RestQueryParams} from "./base/queryParams";
import {meditripClinicAPI} from "./provider/meditripClinic";
import {PaymentServiceName} from "./buySubscriptionDefiniton";

export function detachPaymentMethodAPI(
    authToken: string,
    paymentAccountId: string,
    paymentServiceName: PaymentServiceName,
    paymentMethodId: string
): Observable<any> {
    const payload = {
        paymentServiceName: paymentServiceName,
        paymentMethod: {
            paymentMethodId: paymentMethodId
        }
    };

    return meditripClinicAPI.put(
        `payment_accounts/${paymentAccountId}/detach_payment_method`,
        payload,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`
        }
    );
}
