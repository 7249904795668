import React from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {reset, retrieve} from '../../../../actions/calendar/show';
import {RootState} from "../../../../store/reducers";
import {
    authTokenSelector,
    consultationSlotsToBlockSelector,
    CustomCard,
    IBlockerEvent,
    Loader,
    Tabs,
    Translation
} from "meditrip-common-web";
import {WithTranslation, withTranslation} from "react-i18next";
import {
    calendarItemLoadingSelector,
    retrievedCalendarItemSelector
} from "../../../../store/selectors/calendarItemSelectors";
import {changeCalendarId} from "../../../../store/reducers/calendarSlice";
import {BehaviorSubject, Subscription} from "rxjs";
import CalendarGenerator from "./CalendarGenerator";
import {calendarIdSelector} from "../../../../store/selectors/calendarSelectors";
import PopulateCalendarModal from "./PopulateCalendarModal";
import CalendarDetailsForm from './CalendarDetailsForm';
import MonthCalendar from "./MonthCalendar";
import BlockSlotsCalendarModal from "./BlockSlotsCalendarModal";
import DeleteCalendarModal from "../DeleteCalendarModal";
import CalendarWidget from "./Widget";

interface IConnectedShowProps {
    readonly changeCalendarId: typeof changeCalendarId;
    readonly slotsToBlock: typeof IBlockerEvent[];
    readonly calendarId: string;
    readonly retrieved?: any;
    readonly loading: boolean;
    readonly error?: string;
    readonly eventSource?: EventSource;
    readonly authToken: string;
    readonly retrieve: typeof retrieve;
    readonly reset: typeof reset;
}

interface ICalendarEditState {
    isLoading: boolean;
    populateModalShown: boolean,
    deleteModalShown: boolean;
    calendarPopulated: boolean;
    blockSlotsModalShown: boolean;
    calendar: any;
    chosenTab: number;
}

interface IExternalShowProps {
}

interface IShowProps extends IConnectedShowProps,
    IExternalShowProps,
    RouteComponentProps,
    WithTranslation {
}

class CalendarEdit extends React.Component<IShowProps, ICalendarEditState> {
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    readonly subscriptions: Subscription[] = [];

    constructor(props: any) {
        super(props);
        this.redirectToMonth = this.redirectToMonth.bind(this);
        this.selectedTab = this.selectedTab.bind(this);
        this.state = {
            isLoading: this.props.loading,
            populateModalShown: false,
            deleteModalShown: false,
            calendarPopulated: false,
            blockSlotsModalShown: false,
            calendar: null,
            chosenTab: 0
        };
    }

    componentDidMount() {
        const slug = this.props.match.params;
        this.props.retrieve(`calendars/${(slug as any).id}`, this.props.authToken);
        this.props.changeCalendarId((slug as any).id);

        if (this.props.retrieved !== null) {
            this.setState({
                calendarPopulated: !(!this.props.retrieved?.calendarRules?.length || !this.props.retrieved?.calendarFreeTerms?.length)
            });
        }
    }

    componentDidUpdate(
        prevProps: Readonly<IShowProps>,
        prevState: Readonly<ICalendarEditState>,
        snapshot?: any
    ) {
        if (this.props.retrieved !== prevProps.retrieved && this.props.retrieved !== null) {
            if (this.props.retrieved.calendarRules) {
                this.setState({
                    calendarPopulated: !(!this.props.retrieved?.calendarRules?.length || !this.props.retrieved?.calendarFreeTerms?.length)
                })
            }
            this.setState({calendar: this.props.retrieved});
        }
    }

    componentWillUnmount() {
        if (this.props.eventSource) {
            this.props.reset(this.props.eventSource);
        }
    }

    render() {
        if (!this.props.retrieved) {
            return (<Loader/>);
        }

        const calendarRules = this.props.retrieved && this.props.retrieved.calendarRules ? this.props.retrieved.calendarRules : null;

        return (
            <section className="d-flex flex-column">
                <div className="view-header">
                    <div className="view-title">
                        <Translation text={'calendar.title'}/>
                    </div>
                    <div className="action-container">
                        <Link to={"/dashboard/consultations/widget"}
                              className="btn btn-no-outline mr-1">
                            <span className="feather icon-chevron-left"/>
                            <Translation text={'calendar.buttons.back'}/>
                        </Link>
                        <button className="btn btn-no-outline"
                                onClick={() => this.toggleDeleteModal()}>
                            <span className="feather icon-trash"/>
                            <Translation text={`calendar.buttons.delete`}/>
                        </button>
                    </div>
                </div>
                <div className="row">
                    <CalendarDetailsForm calendar={this.props.retrieved}/>
                    {this.renderCalendarGenerator(this.props.retrieved)}
                </div>
                <PopulateCalendarModal populateModalShown={this.state.populateModalShown}
                                       calendarRules={calendarRules}
                                       redirectToMonth={this.redirectToMonth}/>
                <BlockSlotsCalendarModal blockSlotsModalShown={this.state.blockSlotsModalShown}/>
                <DeleteCalendarModal deleteModalShown={this.state.deleteModalShown}
                                     calendarId={this.props.calendarId}
                                     redirectToList={true}/>
            </section>
        );
    }

    private renderCalendarGenerator(item: any) {
        return (
            <div className="col-lg-6">
                <CustomCard>
                    <CustomCard.Header>
                        <span className="d-flex justify-content-between align-items-center">
                            <Translation text={'calendar.calendarTimeSlots.title'}/>
                            {this.renderActionButton()}
                        </span>
                    </CustomCard.Header>
                    <CustomCard.Body>
                        <Tabs chosenTab={this.state.chosenTab} selectedTab={this.selectedTab}>
                            <div className="tab-headers">
                                <span><Translation text={'calendar.calendarTimeSlots.tabs.week.timeSlots'}/></span>
                                <span><Translation text={'calendar.calendarTimeSlots.tabs.calendar.title'}/></span>
                                <span><Translation text={'calendar.calendarTimeSlots.tabs.widget.title'}/></span>
                            </div>
                            <div className="tabs-content">
                                <div className="tabs-content">
                                    <CalendarGenerator calendar={item}/>
                                </div>
                                <div className="tabs-content">
                                    <MonthCalendar calendar={this.props.retrieved}/>
                                </div>
                                <div className="tabs-content">
                                    <CalendarWidget calendarId={this.props.calendarId}/>
                                </div>
                            </div>
                        </Tabs>
                    </CustomCard.Body>
                </CustomCard>
            </div>
        )
    }

    private redirectToMonth() {
        this.setState({chosenTab: 1, calendarPopulated: true});
        this.props.retrieve(`calendars/${this.props.calendarId}`, this.props.authToken);
    }

    private selectedTab(tabNumber: number) {
        this.setState({chosenTab: tabNumber});
    }

    private togglePopulateModal = () => {
        this.setState({populateModalShown: !this.state.populateModalShown});
    };

    private toggleDeleteModal = () => {
        this.setState({deleteModalShown: !this.state.deleteModalShown});
    };

    private toggleBlockSlotsModal = () => {
        this.setState({blockSlotsModalShown: !this.state.blockSlotsModalShown});
    };

    private renderActionButton() {
        if (this.state.chosenTab === 1) {
            return (
                <button className="btn btn-primary"
                        type="button"
                        onClick={() => this.toggleBlockSlotsModal()}
                        disabled={(this.props.slotsToBlock.length < 1)}>
                    <Translation text={'calendar.buttons.blockSlots'}/>
                </button>
            )
        } else {
            return (
                <button className="btn btn-theme-outline btn-primary"
                        type="button"
                        onClick={() => this.togglePopulateModal()}
                        disabled={!this.props.calendarId}>
                    <Translation text={this.state.calendarPopulated ? 'calendar.buttons.update' : 'calendar.buttons.populate'}/>
                </button>
            )
        }
    }
}

export default withTranslation()(connect(
    (state: RootState) => ({
        loading: calendarItemLoadingSelector(state),
        retrieved: retrievedCalendarItemSelector(state),
        calendarId: calendarIdSelector(state),
        authToken: authTokenSelector(state),
        slotsToBlock: consultationSlotsToBlockSelector(state),
    }),
    {
        changeCalendarId,
        retrieve,
        reset
    }
)(CalendarEdit));
