import { Observable } from "rxjs";
import { RestQueryParams } from "./base/queryParams";
import { meditripRegistrationAPI } from "./provider/meditripRegistration";

export function sendCalendarInvitationAPI(
  accessToken: string,
  urlToCalendar: string,
  calendarId: string,
  patientDetails: {
    accountId?: string,
    firstName?: string,
    lastName?: string,
    email?: string,
  }
): Observable<any> {
  let payload: {[key: string]: any} = {
    urlToCalendar: urlToCalendar,
    calendarId: calendarId
  };

  if (patientDetails.accountId) {
    payload['accountId'] = patientDetails.accountId;
  }

  if (patientDetails.accountId === null || patientDetails.accountId === undefined) {
    payload['firstName'] = patientDetails.firstName;
    payload['lastName'] = patientDetails.lastName;
    payload['email'] = patientDetails.email;
  }

  return meditripRegistrationAPI.post(
    `calendar/send_calendar_link`,
    payload,
    new RestQueryParams(),
    {
      Authorization: `Bearer ${accessToken}`,
    },
  );
}
