import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {RootState} from "../../../../../store/reducers";
import {
    authTokenSelector,
    BasicModal,
    CustomCard,
    CustomCardType,
    Form,
    FormControlChangeType,
    IFormConfig,
    IMultiselectOption,
    Translation,
} from "meditrip-common-web";
import {connect} from "react-redux";
import {WithTranslation, withTranslation} from "react-i18next";
import {IAlertManagerService} from '../../../../../service/alertManagerService';
import {fixInjectedProperties, lazyInject} from "../../../../../ioc";
import {BehaviorSubject, of, Subscription} from "rxjs";
import {catchError, filter, map, tap} from "rxjs/operators";
import {updateEventFormConfig} from "./formConfig";
import {IAftercareMapperService} from "../../../../../service/aftercareMapperService";
import {
    IUpdateTreatmentPointPayload,
    updateAftercareRecommendationAPI
} from "../../../../../api/updateAftercareRecommendationAPI";
import {RecommendationDefinitionType} from "../../../PredefinedRecommendationsList";
import {retrievedRecommendationDefinitionListSelector} from "../../../../../store/selectors/recommendationDefinitionListSelectors";
import {list as recommendationDefinitionList} from "../../../../../actions/recommendationDefinition/list";
import {getAfterCareRecommendationAPI} from "../../../../../api/getAfterCareRecommendation";
import {retrieve} from "../../../../../actions/treatmentPlanner/show";

interface IConnectedUpdateEventProps {
    readonly authToken: string;
    readonly recommendationDefinitionList: any;
    readonly retrievedRecommendationDefinitions: any;
    readonly retrieve: typeof retrieve;
}

interface IUpdateEventProps extends IConnectedUpdateEventProps,
    RouteComponentProps,
    WithTranslation {
        updateAfterCareModalShown: boolean;
        closeAfterCareModal: any;
        toggleAfterCareModal: any;
        afterCareId: string | null;
        selectedRecommendation: {[key: string]: any} | null;
}

interface IUpdateEventState {
    formConfig: typeof IFormConfig;
    isFormValid: boolean;
    isLoading: boolean;
    repeatEvent: boolean;
    value: any;
}

class UpdateEvent extends React.Component<IUpdateEventProps, IUpdateEventState> {
    readonly subscriptions: Subscription[] = [];
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;
    @lazyInject('AftercareMapperService') private aftercareMapper: IAftercareMapperService;

    constructor(props: IUpdateEventProps) {
        super(props);

        this.state = {
            formConfig: updateEventFormConfig,
            isFormValid: true,
            isLoading: false,
            repeatEvent: false,
            value: null
        };

        fixInjectedProperties(this);
    }

    componentDidMount() {
        this.getRecommendationDefinitionList();
        this.getAfterCareRecommendation();

        this.subscriptions.push(
            this.onValueStateChange$.pipe(
                filter((data: any) => data && data.changeType === FormControlChangeType.User),
                tap((data: any) => this.onFormValueChange(data.value, data.changeType)),
            ).subscribe()
        );

        this.setIntervalRate();
        this.setHourReminderIntervalRate(0, 23);
    }

    componentDidUpdate(
        prevProps: Readonly<IUpdateEventProps>,
        prevState: Readonly<IUpdateEventState>,
        snapshot?: any
    ): void {
        if (this.props.selectedRecommendation !== prevProps.selectedRecommendation &&
            this.props.selectedRecommendation
        ) {
            this.getAfterCareRecommendation();
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        return (
            <React.Fragment>
                <BasicModal isModalShown={this.props.updateAfterCareModalShown} closeModal={this.props.closeAfterCareModal}>
                    <CustomCard showLocalLoader={this.state.isLoading} type={CustomCardType.MODAL_CARD}>
                        <CustomCard.Body>
                            <div className="modal-header">
                                <Translation text={'treatmentPlanner.newTreatmentPlan.editEventTitle'}/>
                                <button className="btn-modal-close" onClick={() => this.props.toggleAfterCareModal()}>
                                    <span className="feather icon-x"/>
                                </button>
                            </div>
                            <div className="modal-body aftercare-event-modal">
                                <Form config={this.state.formConfig}
                                      onValueStateChange={this.onValueStateChange}
                                      onValidationStateChange={this.onValidationStateChange}
                                      value={this.state.value}
                                      controlName={'updateAfterCareForm'}
                                      submitForm={this.updateEvent}/>
                            </div>
                        </CustomCard.Body>
                    </CustomCard>
                </BasicModal>
            </React.Fragment>
        );
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private onValidationStateChange = (controlName: string, isValid: boolean) => {
        this.setState({isFormValid: isValid});
    };

    private onFormValueChange = (value: any, changeType: typeof FormControlChangeType) => {
        this.setState({value: value});
        Object.keys(value).map((key: string) => {
            if (key === 'reminderUnit' && value[key] !== null) {
                return value[key] === 'hour' ? this.setHourReminderIntervalRate(0, 23) :
                    this.setHourReminderIntervalRate(1, 30);
            }
        });

        this.setState({value: value});
    };

    private updateEvent = () => {

        this.setState({isLoading: true});
        this.subscriptions.push(this.handleUpdateEventAPI().subscribe());
    };

    private handleUpdateEventAPI() {
        if (!this.props.afterCareId ||
            !this.props.selectedRecommendation?.id ||
            !this.state.value) {
            return of();
        }

        const value = this.state.value,
            cycleInterval = this.aftercareMapper.convertValueToDateInterval(value.intervalValue, value.intervalUnit),
            treatmentPointStartAt = this.aftercareMapper.treatmentPointStartDate(
                value.eventDate,
                value.eventHour,
                value.eventDayPeriod
            ),
            treatmentPointEndDate = this.aftercareMapper.calculateRecommendationEndDate(
                treatmentPointStartAt,
                value.intervalValue,
                value.intervalUnit,
                value.consecutiveType,
                value.consecutiveValue
            ),
            startNotificationPropagation = this.aftercareMapper.calculateNotificationPropagationDate(
                treatmentPointStartAt,
                value.reminderAmount,
                value.reminderUnit
            ),
            notificationType = value.repeatEvent === RecommendationDefinitionType.SINGLE ?
                RecommendationDefinitionType.SINGLE : RecommendationDefinitionType.CONSECUTIVE_PERIOD;

        let payload: IUpdateTreatmentPointPayload = {
            name: value.eventName,
            description: value.eventDescription,
            treatmentPointStartsAt: treatmentPointStartAt,
            treatmentPointEndDate: value.repeatEvent ? treatmentPointEndDate : treatmentPointStartAt, // null,
            startNotificationPropagation: startNotificationPropagation,
            treatmentPlanId: this.props.afterCareId,
            cyclic: false,
            type: value.treatmentPlannerType,
            notificationType: notificationType,
            cycleInterval: null
        };

        return updateAftercareRecommendationAPI(this.props.authToken, this.props.selectedRecommendation.id, payload).pipe(
            map(() => {
                this.setState({isLoading: false});
                if (this.props.afterCareId) {
                    this.props.retrieve(`treatment_plans/${this.props.afterCareId}`, this.props.authToken);
                }
                this.props.closeAfterCareModal();
                this.alertManager.addAlert('Aftercare event was successfully updated.');
            }),
            catchError((error: any) => {
                    this.setState({isLoading: false});
                    return of(this.alertManager.handleApiError(error));
                }
            )
        );
    }

    private getRecommendationDefinitionList = () => {
        this.props.recommendationDefinitionList(
            `treatment_point_definitions`,
            this.props.authToken
        );
    };

    private setIntervalRate() {
        let repeatValue = 30;
        let options: typeof IMultiselectOption[] = [];
        for (let step = 1; step <= repeatValue; step++) {
            options.push({
                value: step,
                label: step
            })
        }

        updateEventFormConfig.controls.map((control: any) => {
            if (control.hasOwnProperty("controls")) {
                Object.keys(control.controls).map((key: string) => {
                    if (key === 'intervalValue') {
                        control.controls[key].multiselectOptions = options;
                    }
                });
            }

            return control;
        });

        this.setState({formConfig: updateEventFormConfig});
    }

    private setHourReminderIntervalRate(startValue: number, repeatValue: number) {
        let options: typeof IMultiselectOption[] = [];
        for (let step = startValue; step <= repeatValue; step++) {
            options.push({
                value: step,
                label: step
            })
        }

        updateEventFormConfig.controls.map((control: any) => {
            if (control.hasOwnProperty("controls")) {
                Object.keys(control.controls).map((key: string) => {
                    if (key === 'reminderAmount') {
                        control.controls[key].multiselectOptions = options;
                    }
                });
            }

            return control;
        });

        this.setState({formConfig: updateEventFormConfig});
    }

    private changeFormControlValues(recommendation: {[key: string]: any}) {
        let recommendationProperties = recommendation?.structure?.properties,
            reminderDetails = this.aftercareMapper.convertToNotificationPropagationValue(
                recommendation?.notificationPropagationStart, recommendation.treatmentPointStartsAt),
            intervalDetails = this.aftercareMapper.convertDateIntervalToValue(recommendationProperties?.cycleInterval),
            consecutiveType = recommendation?.structure?.notificationType === RecommendationDefinitionType.SINGLE ?
                null : recommendation?.structure?.notificationType,
            notificationType = recommendation.structure?.notificationType === RecommendationDefinitionType.SINGLE ?
                RecommendationDefinitionType.SINGLE : RecommendationDefinitionType.CONSECUTIVE,
            consecutiveValue = this.aftercareMapper.convertDateToConsecutiveValue(
                consecutiveType,
                recommendation.treatmentPointEndDate,
                recommendation.treatmentPointStartsAt,
                intervalDetails?.amount,
                intervalDetails?.unit
            );

        let eventHours = new Date(recommendation.treatmentPointStartsAt).getHours();
        let dayPeriod  = 'am';
        if (eventHours > 12) {
            eventHours = eventHours - 12;
            dayPeriod = 'pm';
        }
        this.setState({
            value: {
                treatmentPlannerType: recommendation.structure.type,
                eventHour: eventHours,
                eventDayPeriod: dayPeriod,
                eventDate: recommendation.treatmentPointStartsAt.split('T')[0],
                eventName: recommendation.name,
                eventDescription: recommendation.description,
                // eventType: recommendation.id,
                repeatEvent: notificationType,
                reminderAmount: reminderDetails?.amount,
                reminderUnit: reminderDetails?.unit,
                consecutiveType: consecutiveType,
                consecutiveValue: consecutiveValue,
                intervalUnit: intervalDetails?.unit,
                intervalValue: intervalDetails?.amount
            }
        });
    }

    private getAfterCareRecommendation() {
        if (!this.props.selectedRecommendation) {
            return;
        }

        this.setState({isLoading: true});

        return getAfterCareRecommendationAPI(this.props.authToken, this.props.selectedRecommendation.id).pipe(
            map((response: {[key: string]: any}) => {
                let recommendation: {[key: string]: any} = {};
                let recommendationDefinition;
                if (this.props.retrievedRecommendationDefinitions) {
                    recommendationDefinition = this.props.retrievedRecommendationDefinitions['hydra:member']
                        .filter((item: {[key: string]: any}) => item.id === response.id);
                }

                recommendation['structure'] = response.structure;
                recommendation['notificationPropagationStart'] = response.structure.properties.notificationPropagationStart;
                recommendation['treatmentPointStartsAt'] = response.treatmentPointStartsAt;
                recommendation['name'] = response.name;
                recommendation['description'] = response.description;
                recommendation['id'] = recommendationDefinition && recommendationDefinition.length ? recommendationDefinition : 'custom';
                recommendation['treatmentPointEndDate'] = response.treatmentPointEndDate;
                return this.changeFormControlValues(recommendation);
            }),
            tap(() => this.setState({isLoading: false})),
            catchError((error: any) => {
                    this.setState({isLoading: false});
                    return of(this.alertManager.handleApiError(error));
                }
            )
        ).subscribe();
    }
}

export default withTranslation()(connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
        retrievedRecommendationDefinitions: retrievedRecommendationDefinitionListSelector(state)
    }),
    {
        recommendationDefinitionList,
        retrieve
    }
)(withRouter(UpdateEvent)));
