import {Observable} from "rxjs";
import {RestQueryParams} from "./base/queryParams";
import {meditripCalendarAPI} from "./provider/meditripCalendar";

export function deleteAftercareRecommendationAPI(
    authToken: string | undefined,
    aftercareRecommendationId: string
): Observable<any> {
    return meditripCalendarAPI.delete(
        `treatment_points/${aftercareRecommendationId}`,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`,
        },
    );
}
