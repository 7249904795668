import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {RootState} from "../../../../../../store/reducers";
import {authTokenSelector, Form, FormControlChangeType, IFormConfig, IMultiselectOption} from "meditrip-common-web";
import {connect} from "react-redux";
import {WithTranslation, withTranslation} from "react-i18next";
import {IAlertManagerService} from '../../../../../../service/alertManagerService';
import {fixInjectedProperties, lazyInject} from "../../../../../../ioc";
import {BehaviorSubject, Subscription} from "rxjs";
import {filter, tap} from "rxjs/operators";
import {createTravelPlanConfig} from "./formConfig";
import {IAftercareMapperService} from "../../../../../../service/aftercareMapperService";
import {retrievedRecommendationDefinitionListSelector} from "../../../../../../store/selectors/recommendationDefinitionListSelectors";
import {RecommendationDefinitionType} from "../../../../PredefinedRecommendationsList";
import {isNotNullOrUndefined} from "../../../../../../utils/runtimeUtils";
import {list as recommendationDefinitionList} from "../../../../../../actions/recommendationDefinition/list";
import {EventType} from "../../../../PredefinedRecommendationsList/AddPredefinedRecommendation";

interface IConnectedCreateTravelPlanEventProps {
    readonly authToken: string;
    readonly retrievedRecommendationDefinitions: any;
    readonly recommendationDefinitionList: any;
}

interface ICreateTravelPlanEventProps extends IConnectedCreateTravelPlanEventProps,
    RouteComponentProps,
    WithTranslation {
        addNewEvent: (value: any) => void;
        closeRecommendationModal: any;
        selectedRecommendationDate?: string | null;
}

interface ICreateTravelPlanEventState {
    formConfig: typeof IFormConfig;
    isFormValid: boolean;
    isLoading: boolean;
    value: any;
}

class CreateTravelPlanEvent extends React.Component<ICreateTravelPlanEventProps, ICreateTravelPlanEventState> {
    readonly subscriptions: Subscription[] = [];
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;
    @lazyInject('AftercareMapperService') private aftercareMapper: IAftercareMapperService;

    constructor(props: ICreateTravelPlanEventProps) {
        super(props);

        this.state = {
            formConfig: createTravelPlanConfig,
            isFormValid: true,
            isLoading: false,
            value: null
        };

        fixInjectedProperties(this);
    }

    componentDidMount() {
        this.getRecommendationDefinitionList();

        this.subscriptions.push(
            this.onValueStateChange$.pipe(
                filter((data: any) => data && data.changeType === FormControlChangeType.User),
                tap((data: any) => this.onFormValueChange(data.value, data.changeType)),
            ).subscribe()
        );

        if (this.props.selectedRecommendationDate) {
            let formValues: {[key: string]: any} = {};
            if (this.state.value) {
                formValues = Object.assign({}, this.state.value);
            }

            formValues['eventDate'] = this.props.selectedRecommendationDate;
            return this.setState({value: formValues});
        }
    }

    getSnapshotBeforeUpdate(
        prevProps: Readonly<ICreateTravelPlanEventProps>,
        prevState: Readonly<ICreateTravelPlanEventState>) {
        const prevEventType = prevState.value ? prevState.value.eventType : null;
        return {
            eventType: prevEventType
        };
    };

    componentDidUpdate(
        prevProps: Readonly<ICreateTravelPlanEventProps>,
        prevState: Readonly<ICreateTravelPlanEventState>,
        snapshot?: any
    ): void {
        if (this.props.retrievedRecommendationDefinitions !== prevProps.retrievedRecommendationDefinitions) {
            this.setEventTypeOptions();
        }

        if (this.state.value !== null &&
            this.state.value.eventType !== snapshot.eventType &&
            this.state.value.eventType !== 'custom' &&
            this.props.retrievedRecommendationDefinitions) {
            let selectedDefinition = this.props.retrievedRecommendationDefinitions['hydra:member']
                .filter((recommendation: {[key: string]: any}) => recommendation.id === this.state.value.eventType);

            if (selectedDefinition && selectedDefinition.length) {
                return this.changeFormControlValues(selectedDefinition[0]);
            }
        }

        if (this.props.selectedRecommendationDate !== prevProps.selectedRecommendationDate &&
            this.props.selectedRecommendationDate) {
            let formValues: {[key: string]: any} = {};
            if (this.state.value) {
                formValues = Object.assign({}, this.state.value);
            }

            formValues['eventDate'] = this.props.selectedRecommendationDate;
            return this.setState({value: formValues});
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        return (
            <Form config={this.state.formConfig}
                  onValueStateChange={this.onValueStateChange}
                  onValidationStateChange={this.onValidationStateChange}
                  value={this.state.value}
                  controlName={'addEventForm'}
                  submitForm={() => this.props.addNewEvent(this.state.value)}/>
        );
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private onValidationStateChange = (controlName: string, isValid: boolean) => {
        this.setState({isFormValid: isValid});
    };

    private onFormValueChange = (value: any, changeType: typeof FormControlChangeType) => {
        this.setState({value: value});

        Object.keys(value).map((key: string) => {
            if (key === 'eventType' && isNotNullOrUndefined(value[key])) {
                this.setHourReminderIntervalRate(0, 23);
                return this.updateFormControls(value[key]);
            }

            if (key === 'reminderUnit' && isNotNullOrUndefined(value[key])) {
                return value[key] === 'hour' ? this.setHourReminderIntervalRate(0, 23) :
                    this.setHourReminderIntervalRate(1, 30);
            }
        });
    };

    private getRecommendationDefinitionList = () => {
        this.props.recommendationDefinitionList(
            `treatment_point_definitions`,
            this.props.authToken
        );
    };

    private updateFormControls(value: string): void {
        const cloneFormConfig = Object.assign({}, this.state.formConfig);
        cloneFormConfig.controls.map((control: any) => {
            if (control.hasOwnProperty("controls")) {
                Object.keys(control.controls).map((key: string) => {
                    if (control.controls[key].disabled === true) {
                        control.controls[key].disabled = false;
                    }
                });
            }

            return control;
        });

        this.setState({
          formConfig: cloneFormConfig,
        });
    }

    private setHourReminderIntervalRate(startValue: number, repeatValue: number) {
        let options: typeof IMultiselectOption[] = [];
        for (let step = startValue; step <= repeatValue; step++) {
            options.push({
                value: step,
                label: step
            })
        }

        createTravelPlanConfig.controls.map((control: any) => {
            if (control.hasOwnProperty("controls")) {
                Object.keys(control.controls).map((key: string) => {
                    if (key === 'reminderAmount') {
                        control.controls[key].multiselectOptions = options;
                    }
                });
            }

            return control;
        });

        this.setState({formConfig: createTravelPlanConfig});
    }

    private setEventTypeOptions = () => {
        let eventTypes: typeof IMultiselectOption[] = [];
        eventTypes.push({
            label: 'Custom',
            value: 'custom'
        });

        if (this.props.retrievedRecommendationDefinitions &&
            this.props.retrievedRecommendationDefinitions['hydra:member'] &&
            this.props.retrievedRecommendationDefinitions['hydra:member'].length) {
            this.props.retrievedRecommendationDefinitions['hydra:member']
                .filter((item: {[key: string]: any}) => item.structure.type === EventType.TRAVEL_PLAN)
                .forEach((recommendation: {[key: string]: any}) => {
                return eventTypes.push({
                    label: recommendation.name,
                    value: recommendation.id
                });
            })
        }

        createTravelPlanConfig.controls.map((control: any) => {
            if (control.hasOwnProperty("controls")) {
                Object.keys(control.controls).map((key: string) => {
                    if (key === 'eventType') {
                        control.controls[key].multiselectOptions = eventTypes;
                    }
                });
            }

            return control;
        });

        this.setState({formConfig: createTravelPlanConfig});
    };

    private changeFormControlValues(recommendation: {[key: string]: any}) {
        let recommendationProperties = recommendation?.structure?.properties,
            reminderDetails = this.aftercareMapper.convertDateIntervalToValue(
                recommendationProperties?.notificationPropagationStart
            );

        if (recommendation?.structure?.notificationType === RecommendationDefinitionType.CONSECUTIVE_NUMBER) {
        }
        if (recommendation?.structure?.notificationType === RecommendationDefinitionType.CONSECUTIVE_PERIOD) {
            let eventDays = this.aftercareMapper.convertDateIntervalToDaysAmount(
                recommendationProperties?.cycleValue
            );

            if (this.state.value && this.state.value.eventDate && eventDays) {
                let treatmentPointStartsAt = new Date(this.state.value.eventDate),
                    consecutiveDate = new Date(treatmentPointStartsAt).setDate(treatmentPointStartsAt.getDate() + eventDays);
            }
        }

        const cloneFormValue = Object.assign({}, this.state.value);
        cloneFormValue['eventName'] = recommendation.name;
        cloneFormValue['eventDescription'] = recommendation.description;
        cloneFormValue['reminderAmount'] = reminderDetails?.amount;
        cloneFormValue['reminderUnit'] = reminderDetails?.unit;

        this.setState({value: cloneFormValue});

        reminderDetails && reminderDetails.unit === 'hour' ? this.setHourReminderIntervalRate(0, 23) :
            this.setHourReminderIntervalRate(1, 30);
    }
}

export default withTranslation()(connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
        retrievedRecommendationDefinitions: retrievedRecommendationDefinitionListSelector(state)
    }),
    {
        recommendationDefinitionList
    }
)(withRouter(CreateTravelPlanEvent)));
