import { Observable } from "rxjs";
import {meditripClinicAPI} from "./provider/meditripClinic";
import {RestQueryParams} from "./base/queryParams";


export function invitePatientAPI(
    clinicId: string,
    accessToken: string,
    login: string,
    account: {
        firstName: string
        lastName: string,
        login: string,
        birthdate?: string,
        phone?: string,
        address?: any,
        invoiceAddress?: any,
        residency?: string
    },
    returnUrlForRegisteredUser: string,
    returnUrlForNewUser: string
): Observable<any> {


    let accountPayload = {
        firstName: account.firstName,
        lastName: account.lastName
    };
    // account.birthdate ? accountPayload['birthdate'] = account.birthdate : null;
    // account.phone ? accountPayload['phone'] = account.phone : null;
    // account.address ? accountPayload['address'] = account.address : null;
    // account.invoiceAddress ? accountPayload['invoiceAddress'] = account.invoiceAddress : null;
    // account.residency ? accountPayload['residency'] = account.residency : null;

    let payload = {
        login: login,
        account: accountPayload,
        returnUrlForRegisteredUser: returnUrlForRegisteredUser,
        returnUrlForNewUser: returnUrlForNewUser
    };

    return meditripClinicAPI.put(
        `clinic/${clinicId}/invite_patient`,
        payload,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${accessToken}`,
        },
    );
}
