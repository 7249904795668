import {injectable} from 'inversify';
import {
    calendarWeekDay,
    ICalendarGeneratorHourItem,
    ICalendarRule,
    ITimeSlotItem,
    weekDays,
    WeekDays
} from '../store/reducers/calendarSlice';
import {getTimezoneOffset, numberToHourConverter} from '../utils/dateTransformUtils';
import {formatServerDateToString} from 'meditrip-common-web';
import {deepCloneObject} from '../utils/runtimeUtils';
import moment from "moment";


export interface ICalendarSettings {
    slotLength: number | null;
    availableFrom: number | null;
    availableUntil: number | null;
}

@injectable()
class CalendarCreationService {

    public generateTimeSlotsTable = (calendarSettings: ICalendarSettings): ICalendarGeneratorHourItem[] => {
        let slotsTable: ICalendarGeneratorHourItem[] = [],
            step: number;
        if (calendarSettings.availableFrom === null || calendarSettings.availableUntil === null || calendarSettings.slotLength === null) {
            return slotsTable;
        }
        for (step = calendarSettings.availableFrom; step < calendarSettings.availableUntil; step = step + calendarSettings.slotLength) {
            const label = numberToHourConverter(step),
                endLabel = numberToHourConverter((step + calendarSettings.slotLength));
            slotsTable.push({value: step, label: label, endLabel: endLabel, isFree: true});
        }
        return slotsTable;
    };


    public generateWeekDaysArray = (timeSlotsTable: ICalendarGeneratorHourItem[], timezone: string): calendarWeekDay[] => {
        let weekDaysArray: calendarWeekDay[] = [];

        weekDays.forEach((weekday: WeekDays, index) => {
            let weekDayDate = new Date(0);
            weekDayDate.setDate(weekDayDate.getDate() - (weekDayDate.getDay() - (index + 1)) % 7);

            let timeSlotsFromHours: ITimeSlotItem[] = [];

            timeSlotsTable.forEach((dayTimeSlot: ICalendarGeneratorHourItem) => {
                const dateTime = formatServerDateToString(weekDayDate);
                let dateTimeStart = dateTime,
                    dateTimeEnd = dateTime;
                if (dayTimeSlot.label) {
                    dateTimeStart = dateTime + 'T' + dayTimeSlot.label + ':00.00' + timezone;
                    dateTimeStart = moment(dateTimeStart).toISOString();
                }
                if (dayTimeSlot.endLabel) {
                    dateTimeEnd = dateTime + 'T' + dayTimeSlot.endLabel + ':00' + timezone;
                    dateTimeEnd = moment(dateTimeEnd).toISOString();
                }
                const timeSlotFromHours: ITimeSlotItem = {
                    value: dayTimeSlot.value,
                    label: dayTimeSlot.label,
                    endLabel: dayTimeSlot.endLabel,
                    isFree: dayTimeSlot.isFree,
                    dayName: weekday,
                    dayDateTimeStart: dateTimeStart,
                    dayDateTimeEnd: dateTimeEnd,
                };
                return timeSlotsFromHours.push(timeSlotFromHours);
            });
            weekDaysArray.push({dayName: weekday, dayIndex: index, dayTimeSlots: timeSlotsFromHours});
        });

        return weekDaysArray;
    };

    public generateWeekFromCalendarRules = (calendarRules: ICalendarRule[]): any[] => {
        const weekdays = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
        let calendarRulesWithWeekday: any = deepCloneObject(calendarRules);

        calendarRulesWithWeekday.forEach((calendarRule: any) => calendarRule['day'] = weekdays[new Date(calendarRule.intervalStart).getDay()]);
        let calendarRulesWeek: any[] = [];
        weekDays.forEach((weekDay: any) => {
            calendarRulesWeek[weekDay] = calendarRulesWithWeekday.filter((calendarRule: any) => calendarRule.day === weekDay);
        });

        return calendarRulesWeek;
    };

    public generateCalendarSettings = (fromServer: boolean, data: any): ICalendarSettings => {
        if (!fromServer) {
            return {
                availableFrom: data.value.availableFrom,
                availableUntil: data.value.availableUntil,
                slotLength: data.value.slotLength
            };
        }

        const timezoneOffset = getTimezoneOffset(data.timezone);
        const availableFromTimezone = moment(new Date(data.availableFrom)).utcOffset(timezoneOffset).format('YYYY-MM-DD HH:mm'),
            availableUntilTimezone = moment(new Date(data.availableUntil)).utcOffset(timezoneOffset).format('YYYY-MM-DD HH:mm');
        const availableFromTemp = {
                hour: new Date(availableFromTimezone).getHours(),
                minutes: new Date(availableFromTimezone).getMinutes() / 60
            },
            availableUntilTemp = {
                hour: new Date(availableUntilTimezone).getHours(),
                minutes: new Date(availableUntilTimezone).getMinutes() / 60
            };
        return {
            slotLength: (data.slotLength / 60),
            availableFrom: availableFromTemp.hour + availableFromTemp.minutes,
            availableUntil: availableUntilTemp.hour + availableUntilTemp.minutes,
        };
    };

    public getMonthAvailableDates = (availableConsultationSlots: any, currentMonth: number): Date[] => {
        let availableDates: Date[] = [];
        let selectedYear = Object.keys(availableConsultationSlots as any)[0];
        const selectedMonths = (availableConsultationSlots as any)[selectedYear];
        Object.keys(selectedMonths).forEach((key: any) => {
            if (key === currentMonth.toString()) {
                let selectedMonth = selectedMonths[key];
                Object.keys(selectedMonth).forEach((key: any) => {
                    if (selectedMonth[key]) {
                        let selectedDay = selectedMonth[key];
                        let retrievedDays: any[] = [];
                        Object.keys(selectedDay).forEach((day: any) => retrievedDays.push(selectedDay[day]));
                        let isDayAvailable = retrievedDays.some((el: any) => el.is_free);
                        if (isDayAvailable) {
                            return availableDates.push(new Date(`${selectedYear}-${currentMonth}-${key}`));
                        }
                    }
                });

                return availableDates;
            }
        });

        return availableDates;
    };
}

export default CalendarCreationService;
