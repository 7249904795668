import React from 'react';
import {connect} from 'react-redux';
import {authTokenSelector, Form, FormControlChangeType, IFormConfig, Translation} from 'meditrip-common-web';
import {calendarSettingsConfig} from './calendarGeneratorConfig';
import styles from "./styles.module.scss";
import {BehaviorSubject, of, Subscription} from "rxjs";
import {catchError, debounceTime, filter, tap} from "rxjs/operators";
import {deepCloneObject, isNullOrUndefined} from "../../../../../utils/runtimeUtils";
import CalendarWeek from "./CalendarWeek";
import {
    changeCalendarSettings,
    ICalendarDetails,
    ICalendarGeneratorHourItem,
    ICalendarRule
} from '../../../../../store/reducers/calendarSlice';
import {fixInjectedProperties, lazyInject} from "../../../../../ioc";
import CalendarCreationService, {ICalendarSettings} from "../../../../../service/calendarCreationService";
import {RootState} from '../../../../../store/reducers';
import {
    calendarDetailsSelector,
    calendarIdSelector,
    calendarSettingsSelector
} from "../../../../../store/selectors/calendarSelectors";
import {updateCalendarAPI} from "../../../../../api/updateCalendarAPI";
import {IAlertManagerService} from "../../../../../service/alertManagerService";
import {getTimezoneOffset, numberToDateConverter} from "../../../../../utils/dateTransformUtils";

interface IConnectedCalendarGeneratorProps {
    readonly calendarSettings: ICalendarSettings | null;
    readonly calendarDetails: ICalendarDetails;
    readonly calendarId: string | null,
    readonly authToken: string;
}

interface ICalendarGeneratorProps extends IConnectedCalendarGeneratorProps {
    readonly changeCalendarSettings: typeof changeCalendarSettings;
    readonly calendar?: any;
}

interface ICalendarGeneratorState {
    calendarGeneratorSettings: ICalendarSettings;
    dayHoursTable: ICalendarGeneratorHourItem[];
    formConfig: typeof IFormConfig;
    value: any;
    calendarRules: ICalendarRule[];
}

class CalendarGenerator extends React.Component<ICalendarGeneratorProps, ICalendarGeneratorState> {
    @lazyInject('CalendarCreationService') private calendarCreationService: CalendarCreationService;
    @lazyInject('AlertManagerService') private alertManagerService: IAlertManagerService;

    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    readonly subscriptions: Subscription[] = [];

    constructor(props: any) {
        super(props);
        this.state = {
            formConfig: calendarSettingsConfig,
            calendarGeneratorSettings: {
                slotLength: null,
                availableFrom: null,
                availableUntil: null
            },
            dayHoursTable: [],
            value: null,
            calendarRules: []
        };
        fixInjectedProperties(this);
    }

    componentDidMount() {
        this.subscriptions.push(
            this.onValueStateChange$.pipe(
                filter((data: any) => data && data.changeType === FormControlChangeType.User),
                debounceTime(500),
                tap((data: any) => this.onFormValueChange(data)),
            ).subscribe()
        );
        if (this.props.calendar) {
            this.updateFormFromServer(this.props.calendar);
        }
    }

    componentDidUpdate(prevProps: Readonly<ICalendarGeneratorProps>, prevState: Readonly<ICalendarGeneratorState>, snapshot?: any) {
        if (prevProps.calendar.id !== this.props.calendar.id) {
            this.updateFormFromServer(this.props.calendar, true);
        }
    }

    componentWillUnmount() {
        if (null !== this.subscriptions) {
            this.subscriptions.forEach(subscription => subscription.unsubscribe());
        }
    }

    render() {
        return (
            <article className={styles.calendarGeneratorWrapper}>
                <header className={styles.calendarGeneratorHeader}>
                    <Form config={this.state.formConfig}
                          value={this.state.value}
                          onValueStateChange={this.onValueStateChange}
                          controlName={'CalendarGeneratorForm'}/>
                </header>
                {this.renderCalendarWeek()}
            </article>
        );
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private renderCalendarWeek() {
        if (this.state.dayHoursTable.length < 1) {
            return;
        }

        return <React.Fragment>
            <h3 className={styles.calendarWeekTitle}><Translation text={'calendar.calendarTimeSlots.tabs.week.details'}/></h3>
            <CalendarWeek timeSlotsTable={this.state.dayHoursTable}
                          calendarRules={this.state.calendarRules}
                          timezone={this.props.calendarDetails?.timezone}/>
        </React.Fragment>
    }

    private onFormValueChange = (data: any) => {
        const calendarSettings = this.calendarCreationService.generateCalendarSettings(false, data);

        this.props.changeCalendarSettings(calendarSettings);
        this.setState({
            calendarGeneratorSettings: calendarSettings
        });
        if (isNullOrUndefined(calendarSettings.availableFrom) || isNullOrUndefined(calendarSettings.availableUntil) || isNullOrUndefined(calendarSettings.slotLength)) {
            return;
        }
        if (calendarSettings.availableFrom && calendarSettings.availableUntil && this.props.calendarDetails) {
            const timezoneOffset = getTimezoneOffset(this.props.calendarDetails.timezone);
            const availableFrom = numberToDateConverter(calendarSettings.availableFrom, timezoneOffset),
                availableUntil = numberToDateConverter(calendarSettings.availableUntil, timezoneOffset);
            this.updateCalendarDetails(availableFrom, availableUntil);
        }

        const slotsTable = this.calendarCreationService.generateTimeSlotsTable(calendarSettings);
        this.setState({dayHoursTable: slotsTable});
    };

    private updateFormFromServer = (calendar: any, forceSettingsUpdate = false) => {
        const calendarSettings = this.calendarCreationService.generateCalendarSettings(true, calendar);
        const calendarRules: ICalendarRule[] = deepCloneObject(calendar.calendarRules);
        this.setState({
            value: calendarSettings,
            calendarRules: calendarRules
        });
        if (forceSettingsUpdate || !this.props.calendarSettings) {
            this.props.changeCalendarSettings(calendarSettings);
        }

        if (isNullOrUndefined(calendarSettings.availableFrom) || isNullOrUndefined(calendarSettings.availableUntil) || isNullOrUndefined(calendarSettings.slotLength)) {
            return;
        }
        const slotsTable = this.calendarCreationService.generateTimeSlotsTable(calendarSettings);
        this.setState({dayHoursTable: slotsTable});
    };

    private updateCalendarDetails = (availableFrom: any, availableUntil: any) => {
        // let calendarRules = Array.from(this.props.calendar.calendarRules);
        // let rules = calendarRules.map((item: any) => {
        //     return {
        //         startsAt: item.startsAt,
        //         endsAt: item.endsAt,
        //         calendarId: this.props.calendar.id,
        //         interval: item.interval,
        //         intervalStart: item.intervalStart
        //     };
        // });

        if (this.props.calendarDetails && this.props.calendarId) {
            updateCalendarAPI(this.props.calendarId, this.props.authToken, {
                name: this.props.calendarDetails.calendarName,
                public: true,
                price: this.props.calendarDetails.calendarPrice,
                slotLength: this.props.calendar.slotLength,
                availableFrom: availableFrom,
                availableUntil: availableUntil,
                timezone: this.props.calendarDetails.timezone,
                calendarRules: this.props.calendar.calendarRules, // rules,
                clinicEmail: this.props.calendarDetails.clinicEmail,
                specialistEmail: this.props.calendarDetails.specialistEmail
            }).pipe(
                tap(() => {
                    this.alertManagerService.addAlert('calendar.modals.confirmUpdateModal.success');
                }),
                catchError((error: any) => {
                    this.alertManagerService.handleApiError(error.response);
                    return of(error);
                })
            ).subscribe();
        }
    }
}

export default connect(
    (state: RootState) => ({
        calendarSettings: calendarSettingsSelector(state),
        calendarDetails: calendarDetailsSelector(state),
        calendarId: calendarIdSelector(state),
        authToken: authTokenSelector(state),
    }),
    {
        changeCalendarSettings
    }
)(CalendarGenerator);
