import { Observable } from "rxjs";
import {RestQueryParams} from "./base/queryParams";
import {meditripClinicAPI} from "./provider/meditripClinic";

export function getAccountDataAPI(
    accessToken: string
): Observable<any> {
    return meditripClinicAPI.get(
        `me`,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${accessToken}`,
        },
    );
}
