import React from 'react';
import {updateAccountData} from '../../../store/reducers/accountSlice';
import {connect} from 'react-redux';
import {RootState} from '../../../store/reducers';
import {
    accountLoadingSelector,
    addAlert,
    authTokenSelector,
    updateCompanyName,
    CustomCard,
    Form,
    Translation,
    usernameSelector,
    clinicSelector
} from 'meditrip-common-web';
import {personalDataFormConfig} from './personalDataFormConfig';
import {fixInjectedProperties} from '../../../ioc';
import {accountIdSelector, accountSelector} from '../../../store/selectors/accountSelectors';
import {UpdateCardType} from '../../../service/accountPayloadMapperService';
import BaseAccountForm, {IBaseAccountFormProps, IBaseAccountFormState} from '../BaseAccountForm';

interface IPersonalDataConnectedProps {
    readonly account: any;
    readonly accountId: string | null;
    readonly clinicEmail?: string | null;
    readonly clinic: any;
    readonly authToken: string | null;
    readonly isLoading: boolean;
    readonly updateCompanyName: typeof updateCompanyName;
    readonly updateAccountData: typeof updateAccountData;
    readonly addAlert: typeof addAlert;
}

interface IPersonalDataExternalProps {
}

interface IPersonalDataProps extends IPersonalDataConnectedProps,
    IPersonalDataExternalProps, IBaseAccountFormProps {
}

interface IPersonalDataState extends IBaseAccountFormState {
}

class PersonalData extends BaseAccountForm<IPersonalDataProps, IPersonalDataState> {

    constructor(props: IPersonalDataProps) {
        super(personalDataFormConfig, props);

        fixInjectedProperties(this);
    }

    render() {
        return (
            <CustomCard showLocalLoader={this.state.isLoading}>
                <CustomCard.Header>
                    <Translation text={'setting.personalData.title'}/>
                </CustomCard.Header>
                <CustomCard.Body>
                    <Form config={this.state.formConfig}
                          submitForm={this.save}
                          value={this.state.value}
                          controlName={'personalDataForm'}/>
                </CustomCard.Body>
            </CustomCard>
        );
    }

    protected mapAccountToFormData(account: any, clinic: any): any {
       const personalData = account.personalData || {};
        return {
            clinicEmail: this.props.clinicEmail,
            companyName: clinic.companyName,
            firstName: personalData.firstName,
            lastName: personalData.lastName,
            phone: personalData.phone,
            timezone: personalData.timezone
        };
    }

    protected cardType(): UpdateCardType {
        return UpdateCardType.PERSONAL;
    }

    protected successMessage(): string {
        return 'Personal Data updated successfully.';
    }
}

export default connect(
    (state: RootState) => ({
        account: accountSelector(state),
        accountId: accountIdSelector(state),
        clinic: clinicSelector(state),
        clinicEmail: usernameSelector(state),
        authToken: authTokenSelector(state),
        isLoading: accountLoadingSelector(state)
    }),
    {
        updateAccountData,
        updateCompanyName,
        addAlert
    }
)(PersonalData);
