import {FormControlType, IFormConfig, InputType, ValidationRules} from 'meditrip-common-web';

export const eventFrequencyUnits = [
    {
        value: 'day',
        label: 'day'
    },
    {
        value: 'week',
        label: 'week'
    },
    {
        value: 'month',
        label: 'month'
    }
];

export const reminderFrequencyUnits = [{value: 'hour', label: 'hour'}, ...eventFrequencyUnits];

export const eventTypeFormConfig: typeof IFormConfig = {
    controlType: 'group',
    class: '',
    dataAccessor: (data: any) => data, // data is not grouped
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);

        return previousStateSnapshot;
    },
    controls: [
        {
            controlType: 'group',
            key: 'treatmentPlannerType',
            class: 'form-row',
            controls: {
                treatmentPlannerType: {
                    hostClass: 'col-100 d-flex',
                    controlType: 'control',
                    // defaultValue: 'travel_plan',
                    formControlType: FormControlType.RADIO,
                    options: [
                        {value: 'travel_plan', displayValue: 'Travel Plan'},
                        {value: 'after_care', displayValue: 'Aftercare'}
                    ],
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                    ],
                    placeholder: 'treatmentPlanner.newTreatmentPlan.form.plannerType',
                    label: 'treatmentPlanner.newTreatmentPlan.form.plannerType',
                    type: InputType.RADIO,
                }
            }
        },
    ]
};
