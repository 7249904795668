import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {RootState} from "../../../../store/reducers";
import {
    IMultiselectOption,
    Form,
    IFormConfig,
    FormControlChangeType,
    BasicModal,
    CustomCard,
    CustomCardType,
    createPatientAPI,
    clinicIdSelector,
    Translation
} from "meditrip-common-web";
import {connect} from "react-redux";
import {WithTranslation, withTranslation} from "react-i18next";
import {IAlertManagerService} from '../../../../service/alertManagerService';
import {fixInjectedProperties, lazyInject} from "../../../../ioc";
import {BehaviorSubject, of, Subscription} from "rxjs";
import {catchError, debounceTime, filter, map, tap} from "rxjs/operators";
import {addAfterCareFormConfig} from "./formConfig";
import {inviteNewPatientControls} from "./inviteNewPatientControls";
import {createAftercareAPI, ICreateAftercarePayload} from "../../../../api/createAftercareAPI";

interface IConnectedAddTreatmentPlanFormProps {
    readonly clinicId: string;
}

interface IAddTreatmentPlanFormProps extends IConnectedAddTreatmentPlanFormProps,
    RouteComponentProps,
    WithTranslation {
        authToken: string;
        addAfterCareModalShown: boolean;
        closeAfterCareModal: any;
        toggleAfterCareModal: any;
        treatmentTypes: typeof IMultiselectOption[];
        patientList: typeof IMultiselectOption[];
}

interface IAddTreatmentPlanFormState {
    formConfig: typeof IFormConfig;
    isFormValid: boolean;
    isLoading: boolean;
    inviteNewPatient: boolean;
}

class AddTreatmentPlanForm extends React.Component<IAddTreatmentPlanFormProps, IAddTreatmentPlanFormState> {
    readonly subscriptions: Subscription[] = [];
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;

    constructor(props: IAddTreatmentPlanFormProps) {
        super(props);

        this.state = {
            formConfig: addAfterCareFormConfig,
            isFormValid: true,
            isLoading: false,
            inviteNewPatient: false
        };

        fixInjectedProperties(this);
    }

    componentDidMount() {
        this.subscriptions.push(
            this.onValueStateChange$.pipe(
                filter((data: any) => data && data.changeType === FormControlChangeType.User),
                debounceTime(500),
                tap((data: any) => this.onFormValueChange(data.value, data.changeType)),
            ).subscribe()
        );
    }

    componentDidUpdate(
        prevProps: Readonly<IAddTreatmentPlanFormProps>,
        prevState: Readonly<IAddTreatmentPlanFormState>,
        snapshot?: any
    ): void {
        if (this.props.patientList !== prevProps.patientList) {
            this.setPatientMultiselectOptions();
        }

        if (this.props.treatmentTypes !== prevProps.treatmentTypes) {
            this.setTreatmentTypesMultiselectOptions();
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        return (
            <React.Fragment>
                <BasicModal isModalShown={this.props.addAfterCareModalShown} closeModal={this.props.closeAfterCareModal}>
                    <CustomCard showLocalLoader={this.state.isLoading} type={CustomCardType.MODAL_CARD}>
                        <CustomCard.Body>
                            <div className="modal-header">
                                <Translation text={'treatmentPlanner.addNewPlan'}/>
                                <button className="btn-modal-close" onClick={() => this.props.toggleAfterCareModal()}>
                                    <span className="feather icon-x"/>
                                </button>
                            </div>
                            <div className="modal-body">
                            <Form config={this.state.formConfig}
                                  onValueStateChange={this.onValueStateChange}
                                  onValidationStateChange={this.onValidationStateChange}
                                  submitForm={this.addNewAfterCare}
                                  // value={this.state.value}
                                  controlName={'addAfterCareForm'}/>
                            </div>
                        </CustomCard.Body>
                    </CustomCard>
                </BasicModal>
            </React.Fragment>
        );
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private onValidationStateChange = (controlName: string, isValid: boolean) => {
        this.setState({isFormValid: isValid});
    };

    private onFormValueChange = (value: any, changeType: typeof FormControlChangeType) => {
        Object.keys(value).map((key: string) => {
           if (key === 'patient' && value[key] !== null && !this.state.inviteNewPatient) {
                if (value[key] === 'add_new_patient' && changeType === FormControlChangeType.User) {
                    this.inviteNewPatientFields();
                    return;
                }
           }

            if (key === 'patient' && value[key] !== 'add_new_patient' && this.state.inviteNewPatient) {
                    this.removeInviteNewPatientFields();
            }
        });
    };

    private addNewAfterCare = (event: any, value: any, valid: boolean, touched: boolean) => {
        if (!touched || !valid || !this.props.authToken) {
            return;
        }
        this.setState({isLoading: true});

        let payload: {[key: string]: any} = {
            clinicId: this.props.clinicId,
            treatmentTypeId: value.treatmentType,
            treatmentEndDate: new Date(value.treatmentDate).toISOString(),
            doctorId: null,
            planName: value.planName
        };

        if (!this.state.inviteNewPatient) {
            payload['patientId'] = value.patient;
            return this.subscriptions.push(
                this.handleCreateAftercareAPI(payload as ICreateAftercarePayload).subscribe()
            );
        }

        if (this.state.inviteNewPatient) {
            const patientAccount = {
                firstName: value.firstName,
                lastName: value.lastName
            };

            return this.subscriptions.push(
                createPatientAPI(
                    this.props.authToken,
                    value.email,
                    patientAccount
                ).pipe(
                    map((response: any) => {
                        payload['patientId'] = response.id;
                        return this.subscriptions.push(
                            this.handleCreateAftercareAPI(payload as ICreateAftercarePayload).subscribe()
                        );
                    }),
                    catchError((error: any) => {
                        this.setState({isLoading: false});
                        return of(this.alertManager.handleApiError(error));
                    })
                ).subscribe()
            );
        }
    };

    private handleCreateAftercareAPI(payload: ICreateAftercarePayload) {
        return createAftercareAPI(this.props.authToken, payload).pipe(
            map((response) => {
                this.setState({isLoading: false, inviteNewPatient: false});
                this.props.closeAfterCareModal();
                this.alertManager.addAlert('Aftercare was successfully created.');
                this.props.history.push(`/dashboard/aftercare/${response.id}`);
            }),
            catchError((error: any) => {
                    this.setState({isLoading: false});
                    return of(this.alertManager.handleApiError(error));
                }
            )
        )
    }

    private setPatientMultiselectOptions = () => {
        if (!this.props.patientList || !this.props.patientList.length) {
            return;
        }

        let patients: typeof IMultiselectOption[] = [];
        // patients.push({
        //     label: 'Add new patient',
        //     value: 'add_new_patient'
        // });

        this.props.patientList.forEach(item => patients.push(item));

        addAfterCareFormConfig.controls.map((control: any) => {
            if (control.hasOwnProperty("controls")) {
                Object.keys(control.controls).map((key: string) => {
                    if (key === 'patient') {
                        control.controls[key].multiselectOptions = patients;
                    }
                });
            }

            return control;
        });

        this.setState({formConfig: addAfterCareFormConfig});
    };

    private setTreatmentTypesMultiselectOptions() {
        if (!this.props.treatmentTypes) {
            return;
        }

        addAfterCareFormConfig.controls.map((control: any) => {
            if (control.hasOwnProperty("controls")) {
                Object.keys(control.controls).map((key: string) => {
                    if (key === 'treatmentType') {
                        control.controls[key].multiselectOptions = this.props.treatmentTypes;
                    }
                });
            }

            return control;
        });

        this.setState({formConfig: addAfterCareFormConfig});
    }

    private inviteNewPatientFields(): void {
        const controls = Array.from(this.state.formConfig.controls);
        const cloneFormConfig = Object.assign({}, addAfterCareFormConfig);
        cloneFormConfig['controls'] = [...controls, ...inviteNewPatientControls];

        this.setState({
            formConfig: cloneFormConfig,
            inviteNewPatient: true,
        });

        setTimeout(() => {
            addAfterCareFormConfig['controls'] = cloneFormConfig['controls'];
        }, 0);
    }

    private removeInviteNewPatientFields(): void {
        const controls = Array.from(this.state.formConfig.controls);
        const cloneFormConfig = Object.assign({}, addAfterCareFormConfig);
        cloneFormConfig['controls'] = controls.slice(0, controls.length - 2);

        this.setState({
            formConfig: cloneFormConfig,
            inviteNewPatient: false,
        });
        setTimeout(() => {
            addAfterCareFormConfig['controls'] = cloneFormConfig['controls'];
        }, 0);
    }
}

export default withTranslation()(connect(
    (state: RootState) => ({
        clinicId: clinicIdSelector(state)
    }),
    {}
)(withRouter(AddTreatmentPlanForm)));
