import React from 'react';
import {BasicModal, CustomCard, CustomCardType, Translation} from "meditrip-common-web";
import {WithTranslation, withTranslation} from "react-i18next";

interface IConnectedTreatmentPlanPatientInvitationModalProps {}

interface ITreatmentPlanPatientInvitationModalProps extends
    IConnectedTreatmentPlanPatientInvitationModalProps,
    WithTranslation {
    patientInvitedModalShown: boolean;
    togglePatientInvitedModal: any;
}

interface ITreatmentPlanPatientInvitationModalState {}

class TreatmentPlanPatientInvitationModal extends React.Component<ITreatmentPlanPatientInvitationModalProps, ITreatmentPlanPatientInvitationModalState> {

    render() {
        return (
            <BasicModal isModalShown={this.props.patientInvitedModalShown} closeModal={this.props.togglePatientInvitedModal}>
                <CustomCard type={CustomCardType.MODAL_CARD}>
                    <CustomCard.Body>
                        <div className="modal-body">
                            <p className="text-center">
                                <Translation text={'treatmentPlanner.newTreatmentPlan.invitation.description'}/>
                            </p>
                        </div>
                        <div className="modal-footer justify-content-center">
                            <button className="btn btn-theme-outline"
                                    onClick={() => this.props.togglePatientInvitedModal()}>
                                <span className="feather icon-minus-circle"/>
                                <Translation text={'treatmentPlanner.newTreatmentPlan.invitation.button'}/>
                            </button>
                        </div>
                    </CustomCard.Body>
                </CustomCard>
            </BasicModal>
        );
    }
}

export default withTranslation()(TreatmentPlanPatientInvitationModal);
