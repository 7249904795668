import {FormButtonType, FormControlType, IFormConfig, InputType, MultiSelectType, ValidationRules} from 'meditrip-common-web';
import moment from 'moment';

export const addAfterCareFormConfig: typeof IFormConfig = {
    controlType: 'group',
    class: '',
    dataAccessor: (data: any) => data, // data is not grouped
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);

        return previousStateSnapshot;
    },
    controls: [
        {
            controlType: 'group',
            key: 'planName',
            class: 'form-row',
            controls: {
                planName: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                    ],
                    placeholder: 'Plan name',
                    label: 'Plan name',
                    type: InputType.TEXT,
                    hostClass: 'col-100'
                },
            }
        },
        {
            controlType: 'group',
            key: 'treatmentDate',
            class: 'form-row',
            controls: {
                treatmentDate: {
                    hostClass: 'col-100',
                    controlType: 'control',
                    defaultValue: '',
                    additionalStyles: 'horizontal',
                    formControlType: FormControlType.DATE,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                    ],
                    placeholder: 'Select treatment date',
                    label: 'Treatment date',
                    maxDate: '2100-01-01',
                    openToDate: moment().format('YYYY-MM-DD'),
                    type: InputType.DATE,
                },
            }
        },
        {
            controlType: 'group',
            key: 'treatmentType',
            class: 'form-row',
            controls: {
                treatmentType: {
                    hostClass: 'col-100',
                    controlType: 'control',
                    defaultValue: null,
                    placeholder: 'Select treatment type',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.SINGLE,
                    label: 'Treatment type',
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                    ],
                    multiselectOptions: [],
                    inputDataMapper: (data: any) => {
                        if (!data) {
                            return [];
                        }

                        return addAfterCareFormConfig.controls[0].controls.treatmentType.multiselectOptions
                            .filter((calendar: any) => calendar.value === data)
                            .map((calendar: any) => ({
                                value: calendar.value,
                                label: calendar.label,
                            }));
                    },
                    outputDataMapper: (data: any) => {
                        if (Array.isArray(data)) {
                            return (data.length > 0) ? data[0].value : null;
                        }
                        if (data && typeof data === 'object') {
                            return data.value;
                        }

                        return data;
                    },
                }
            }
        },
        {
            controlType: 'group',
            key: 'patient',
            class: 'form-row',
            controls: {
                patient: {
                    hostClass: 'col-100',
                    controlType: 'control',
                    defaultValue: null,
                    placeholder: 'Select a patient',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.SINGLE,
                    label: 'Patient',
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                    ],
                    multiselectOptions: [],
                    inputDataMapper: (data: any) => {
                        if (!data) {
                            return [];
                        }

                        return addAfterCareFormConfig.controls[1].controls.patient.multiselectOptions
                            .filter((patient: any) => patient.value === data)
                            .map((patient: any) => ({
                                value: patient.value,
                                label: patient.label,
                            }));
                    },
                    outputDataMapper: (data: any) => {
                        if (Array.isArray(data)) {
                            return (data.length > 0) ? data[0].value : null;
                        }
                        if (data && typeof data === 'object') {
                            return data.value;
                        }
                        return data;
                    },
                }
            }
        },
        {
            controlType: 'group',
            key: 'aftercare_submit',
            class: 'form-row',
            controls: {
                submitButton: {
                    controlType: 'control',
                    formControlType: FormControlType.BUTTON,
                    buttonType: FormButtonType.SUBMIT,
                    hostClass: 'col-100 mb-0',
                    defaultContainerStyles: '',
                    containerStyles: 'button-container mt-2',
                    defaultValue: null,
                    btnText: 'treatmentPlanner.addNewPlanButton',
                },
            },
        },
    ]
};
