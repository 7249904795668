import {FormControlType, ValidationRules, IFormConfig} from "meditrip-common-web";

export const calendarFormConfig: typeof IFormConfig = {
  controlType: 'group',
  class: '',
  dataAccessor: (data: any) => data, // data is not grouped
  outputDataMapper: (data: any, previousStateSnapshot: any) => {
    Object.assign(previousStateSnapshot, data);

    return previousStateSnapshot;
  },
  controls: [
    {
      controlType: 'group',
      key: 'date',
      class: 'form-row d-flex align-items-center justify-content-center',
      controls: {
        date: {
          hostClass: 'month-calendar event-calendar',
          controlType: 'control',
          defaultValue: null,
          placeholder: 'Choose date',
          formControlType: FormControlType.EVENT_CALENDAR,
          // label: "Choose date",
          validationRules: [
            { name: ValidationRules.IS_REQUIRED },
          ],
        }
      }
    },
  ]
};
