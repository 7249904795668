import {FormButtonType, FormControlType, IFormConfig, InputType, MultiSelectType, timezoneList, ValidationRules} from 'meditrip-common-web';

export const personalDataFormConfig: typeof IFormConfig = {
    class: '',
    dataAccessor: (data: any) => data, // data is not grouped
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);

        return previousStateSnapshot;
    },
    controls: [
        {
            controlType: 'group',
            key: 'clinic_email',
            class: 'row',
            controls: {
                clinicEmail: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                    ],
                    placeholder: 'Clinic Email',
                    label: 'Clinic Email',
                    type: InputType.TEXT,
                    hostClass: 'col-xl-12',
                    disabled: true,
                }
            }
        },
        {
            controlType: 'group',
            key: 'clinic_name',
            class: 'row',
            controls: {
                companyName: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                    ],
                    placeholder: 'Clinic Name',
                    label: 'Clinic Name',
                    type: InputType.TEXT,
                    hostClass: 'col-xl-12',
                }
            }
        },
        {
            controlType: 'group',
            key: 'owner_name',
            class: 'row',
            controls: {
                firstName: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                    ],
                    placeholder: 'Owner Name',
                    label: 'Owner Name',
                    type: InputType.TEXT,
                    hostClass: 'col-xl-6',
                },
                lastName: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                    ],
                    placeholder: 'Owner Surname',
                    label: 'Owner Surname',
                    type: InputType.TEXT,
                    hostClass: 'col-xl-6',
                }
            }
        },
        {
            controlType: 'group',
            key: 'clinic_phone',
            class: 'row',
            controls: {
                phone: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                        {name: ValidationRules.MIN_LENGTH, params: {length: 9}}
                    ],
                    placeholder: 'Enter your phone',
                    label: 'Phone',
                    type: InputType.TEXT,
                    hostClass: 'col-xl-12',
                }
            }
        },
        {
            controlType: 'group',
            key: 'clinic_timezone',
            class: 'form-row',
            controls: {
                timezone: {
                    controlType: 'control',
                    defaultValue: '',
                    placeholder: 'Select your timezone',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.SINGLE,
                    label: 'Time Zone',
                    validationRules: [
                        // {name: ValidationRules.IS_REQUIRED},
                    ],
                    multiselectOptions: timezoneList,
                    firstOptionValue: 'Select timezone',
                    hostClass: 'col-33',
                    inputDataMapper: (data: any) => {
                        if (!data) {
                            return [];
                        }

                        return timezoneList
                            .filter((timezone: any) => timezone.label === data)
                            .map((timezone: any) => ({
                                value: timezone.value,
                                label: timezone.label,
                            }));
                    },
                    outputDataMapper: (data: any) => {
                        if (Array.isArray(data)) {
                            return (data.length > 0) ? data[0].value : null;
                        }
                        if (data && typeof data === 'object') {
                            return data.value;
                        }

                        return data;
                    },
                },
            }
        },
        {
            controlType: 'group',
            key: 'personal_submit',
            class: 'row',
            controls: {
                submitButton: {
                    controlType: 'control',
                    formControlType: FormControlType.BUTTON,
                    buttonType: FormButtonType.SUBMIT,
                    hostClass: 'col-xl-12 mb-0',
                    defaultContainerStyles: '',
                    containerStyles: 'button-container mt-2 pr-0',
                    defaultValue: null,
                    btnText: 'Change personal information',
                    defaultStyles: 'btn btn-primary'
                },
            },
        },
    ]
};
