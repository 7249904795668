import { Observable } from "rxjs";
import { RestQueryParams } from "meditrip-common-web";
import {meditripClinicAPI} from "./provider/meditripClinic";


export function cancelConsultationAPI(
    consultationId: string,
    accessToken: string
): Observable<any> {
    return meditripClinicAPI.put(
        `online_consultation/${consultationId}/cancel`,
        {},
        new RestQueryParams(),
        {
            Authorization: `Bearer ${accessToken}`
        }
    );
}
