import React from 'react';
import {
    addUserNoteAPI,
    AllUserConnections,
    authTokenSelector,
    Chat,
    ChatContact,
    ChatMessage,
    clearChatNotification,
    clinicSelector,
    downloadFile,
    editChatNotification,
    Footer,
    getNotificationFromIdAPI,
    getNotificationsAPI,
    getUserNoteAPI,
    isAuthenticatedSelector,
    Loader,
    NavBar,
    Note,
    Notification,
    NotificationMessage,
    postDelayedMessageAPI,
    saveFile,
    sendMessageToOfflineAPI,
    ServerNote,
    ServerNotificationMessage,
    setAuthState,
    setClinicProfileState,
    setClinicState,
    Sidebar,
    Toast,
    toggleNotificationAPI,
    usernameSelector,
    withChatConnection,
    withChatContactsList
} from 'meditrip-common-web';
import Specialists from '../Specialists';
import Patients from '../Patients';
import Settings from '../Settings';
import {Redirect, Route, RouteComponentProps, Switch, withRouter} from 'react-router-dom';
import navigation from './menu-items';
import {connect} from 'react-redux';
import {RootState} from '../../store/reducers';
import {showLoaderSelector} from '../../store/selectors/sagaSelectors';
import Profile from '../Profile';
import SpecialistView from '../Specialists/SpecialistView';
import PatientView from '../Patients/PatientView';
import {getAccountDataAPI} from "../../api/getAccountData";
import {catchError, map, mergeMap, take, tap} from "rxjs/operators";
import {Observable, of, Subject, Subscription} from "rxjs";
import {fixInjectedProperties, lazyInject} from "../../ioc";
import {IAlertManagerService} from "../../service/alertManagerService";
import ConsultationsList from '../Consultations/ConsultationsList';
import CalendarWidgets from "../Consultations/CalendarWidgets";
import CalendarEdit from "../Consultations/CalendarWidgets/CalendarEdit";
import AfterCareList from '../TreatmentPlanner/TreatmentPlannerList';
import AfterCareView from "../TreatmentPlanner/TreatmentPlannerList/TreatmentPlanView";
import PredefinedRecommendationsList from '../TreatmentPlanner/PredefinedRecommendationsList';
import Billings from "../Billings";


interface IConnectedPanelHostProps {
    readonly setAuthState: typeof setAuthState;
    readonly setClinicState: typeof setClinicState;
    readonly setClinicProfileState: typeof setClinicProfileState;
    readonly showLoader: boolean;
    readonly clinic: any;
    readonly authToken: string;
    readonly username: string;
    readonly isAuthenticated: boolean;
    readonly userId: string;
    readonly accountId: string;
}

interface IExternalPanelHostProps {
    fullWidthLayout?: any;
    peerConnections: typeof AllUserConnections;
    addMessage: (message: typeof ChatMessage, room: string) => void;
    setSelectedChatRoomId: (roomId: string) => void;
    selectedChatRoomId: string;
    hasUnreadMessages: string[];
    hasNodeConnection: boolean;
    allowedChannels: typeof ChatContact[];
    errors: Subject<Error>
}

interface IPanelHostProps extends IConnectedPanelHostProps,
    IExternalPanelHostProps,
    RouteComponentProps {
}

interface IPanelHostState {
    isSidebarCollapsed: boolean;
    dataIsLoading: boolean;
    accountId: string;
}

class PanelHost extends React.Component<IPanelHostProps, IPanelHostState> {
    @lazyInject('AlertManagerService') private alertManagerService: IAlertManagerService;

    private navMenu: any[];
    private subscription: Subscription | null = null;

    constructor(props: IPanelHostProps) {

        super(props);

        this.state = {
            isSidebarCollapsed: false,
            dataIsLoading: false,
            accountId: ''
        };

        this.navMenu = [
            {
                url: '/dashboard/profile',
                icon: 'icon-user',
                title: 'Profile'
            },
            // {
            //     url: '#',
            //     icon: 'icon-mail',
            //     title: 'My messages'
            // },
            {
                url: '/dashboard/settings',
                icon: 'icon-settings',
                title: 'Settings'
            },
            {
                url: '/dashboard/billings',
                icon: 'icon-credit-card',
                title: 'Billings & Payments'
            },
            {
                url: '#',
                icon: 'icon-log-out',
                title: 'Logout',
                onClick: this.onLogout
            }
        ];
        fixInjectedProperties(this);

    }

    componentDidMount(): void {
        this.setState({dataIsLoading: true});
        if (this.props.authToken) {
            this.subscription = this.getAccountData(this.props.authToken).subscribe();
        } else {
            this.setState({dataIsLoading: false})
        }
    }

    componentWillUnmount() {
        if (null !== this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    render() {
        const collapsed = this.state.isSidebarCollapsed ? 'collapsed' : '',
            username = this.props.username ? this.props.username : null,
            name = this.props.clinic?.companyName ? `${this.props.clinic.companyName}` : username;
        return (
            <div className="dashboard-view">
                <NavBar onToggleNavigation={this.toggleSidebar}
                        onLogout={this.onLogout}
                        isDropdownVisible={false}
                        isSearchVisible={false}
                        generalNotificationsDisplayed={false}
                        profileNotificationsDisplayed={false}
                        navMenu={this.navMenu}
                        hasUnreadMessages={this.props.hasUnreadMessages}
                        username={name}/>
                <main className="content-main">
                    <Sidebar navigation={navigation}
                             collapseMenu={this.state.isSidebarCollapsed}/>

                    <div className={`main-container ${collapsed}`}>
                        <div className="content">

                            <Switch>
                                {/*<Route path="/dashboard" component={Dashboard} exact strict key="dashboard"/>*/}
                                <Route path="/dashboard/specialists" component={Specialists} exact strict
                                       key="specialists"/>
                                <Route path="/dashboard/specialists/:id" component={SpecialistView} exact
                                       key="specialistView"/>
                                <Route path="/dashboard/patients" component={Patients} exact strict key="patients"/>
                                <Route path="/dashboard/patients/:id" component={PatientView} exact key="patientView"/>

                                <Route path="/dashboard/settings" component={Settings} exact strict key="settings"/>
                                <Route path="/dashboard/profile" component={Profile} exact strict key="profile"/>

                                <Route path="/dashboard/consultations" component={ConsultationsList} exact strict
                                       key="consultationsList"/>
                                <Route path="/dashboard/consultations/widget" component={CalendarWidgets} exact strict
                                       key="calendarWidgets"/>
                                <Route path="/dashboard/consultations/edit/:id" component={CalendarEdit} exact
                                       key="edit"/>

                                <Route path="/dashboard/aftercare" component={AfterCareList} exact strict
                                       key="aftercareList"/>
                                <Route path="/dashboard/aftercare/:id" component={AfterCareView} exact
                                       key="aftercareView"/>

                                <Route path="/dashboard/recommendations" component={PredefinedRecommendationsList} exact
                                       strict
                                       key="recommendationsList"/>

                                <Route path="/dashboard/billings" component={Billings} exact strict key="billings"/>
                                <Route path="/dashboard/chats" render={() => <Chat
                                    saveMessage={(message: any) => sendMessageToOfflineAPI(this.props.authToken, message)}

                                    saveNotification={(delayedMessage: typeof NotificationMessage) => postDelayedMessageAPI(this.props.authToken, delayedMessage)}
                                    getNotification={(accountId: string) => this.getNotification(accountId)}
                                    toggleNotification={(payload: any) => toggleNotificationAPI(this.props.authToken, payload)}
                                    editNotification={(delayedMessage: typeof NotificationMessage, notificationId: string) => editChatNotification(this.props.authToken, delayedMessage, notificationId)}
                                    clearNotification={(notificationId: string) => clearChatNotification(this.props.authToken, notificationId)}


                                    saveFile={(formData: FormData) => saveFile(this.props.authToken, formData)}
                                    downloadFile={(url: string) => downloadFile(this.props.authToken, url)}

                                    saveNote={(note: typeof Note) => addUserNoteAPI(this.props.authToken, note)}
                                    getNote={(accountId: string) => this.getNote(accountId)}

                                    errors={this.props.errors}
                                    errorHandler={(e: any) => this.alertManagerService.handleApiError(e)}

                                    userId={this.props.userId}
                                    accountId={this.state.accountId}
                                    showOnlineIndicator={true}
                                    hasUnreadMessages={this.props.hasUnreadMessages}
                                    addMessage={this.props.addMessage}
                                    setSelectedChatRoomId={this.props.setSelectedChatRoomId}
                                    selectedChatRoomId={this.props.selectedChatRoomId}
                                    showNotesSection={true}
                                    chatContactsList={this.props.allowedChannels}
                                    peerConnections={this.props.peerConnections}
                                    isConnected={this.props.hasNodeConnection}/>} exact strict key="chats"/>

                                <Route component={() => (<Redirect to={'/dashboard/consultations'}/>)}/>

                            </Switch>

                        </div>
                    </div>
                </main>
                <Footer/>

                <Loader showLoader={this.props.showLoader}/>
                <Toast/>
            </div>
        );
    }

    toggleSidebar = (): void => {
        this.setState({isSidebarCollapsed: !this.state.isSidebarCollapsed});
    };

    private getNote(accountId: string): Observable<string> {
        return getUserNoteAPI(this.props.authToken, accountId).pipe(
            take(1),
            map((data: any) => {
                if (data) {
                    const chatNotification: typeof ServerNote = data['hydra:member']?.[0];
                    return chatNotification?.content || ''
                }
            }),
            catchError(e => of(this.alertManagerService.handleApiError(e)))
        )
    }

    private getNotification(accountId: string): Observable<Notification> {
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + 1);
        const stringDate = currentDate.toISOString();
        return getNotificationsAPI(this.props.authToken, accountId).pipe(
            take(1),
            map((data: any) => {
                if (data) {
                    const chatNotification = data['hydra:member'].filter((element: typeof NotificationMessage) => element.targets[0].contentType === 'chat');
                    return chatNotification[0]?.id || ''
                }
            }),
            mergeMap((id: string) => {
                if (!id) {
                    return of({
                        content: '',
                        reminderId: '',
                        disabled: false,
                        sendAtDateTime: stringDate
                    })
                }

                return getNotificationFromIdAPI(this.props.authToken, id).pipe(
                    take(1),
                    map((data: typeof ServerNotificationMessage) => {
                        if (data) {
                            const notification = data?.targets?.[0];
                            return ({
                                content: notification?.content?.rawMessage || '',
                                reminderId: id,
                                disabled: notification?.disabled || false,
                                sendAtDateTime: notification?.sendAtDateTime || stringDate
                            })
                        }
                        return ({
                            content: '',
                            reminderId: '',
                            disabled: false,
                            sendAtDateTime: stringDate
                        })
                    }),
                    catchError(e => of(this.alertManagerService.handleApiError(e)))
                )
            }),
            catchError(e => of(this.alertManagerService.handleApiError(e)))
        )
    }

    private getAccountData(authToken: string) {
        return getAccountDataAPI(authToken).pipe(
            tap((resp) => {
                this.setState({accountId: resp.account.id});
                this.props.setClinicState(
                    resp.clinic.id,
                    resp.clinic.reference,
                    resp.clinic.treatmentCategories,
                    resp.clinic.logo,
                    resp.clinic.feePercent,
                    resp.clinic.companyName
                );

                this.props.setClinicProfileState(
                    resp.clinic.profile.id,
                    resp.clinic.companyName,
                    resp.clinic.profileopenHours,
                    resp.clinic.profile.description,
                    resp.clinic.profile.certificates,
                    resp.clinic.profile.localAttractions,
                    resp.clinic.profile.localization,
                    resp.clinic.profile.extraServices,
                    resp.clinic.profile.languages,
                    resp.clinic.profile.paymentOptions,
                    resp.clinic.profile.accreditations,
                    resp.clinic.profile.staff,
                    resp.clinic.profile.beforeAndAfter,
                    ''
                );
                return resp;
            }),
            catchError((err: any) => {
                this.setState({dataIsLoading: false});
                return of(this.alertManagerService.handleFormApiError(err));
            })
        );
    }

    private onLogout = () => {
        localStorage.clear();
        this.props.setAuthState(null, null, null, null, null, false);
        window.location.href = '/auth/login';
    };
}

export default connect(
    (state: RootState) => ({
        showLoader: showLoaderSelector(state),
        clinic: clinicSelector(state),
        username: usernameSelector(state),
        authToken: authTokenSelector(state),
        isAuthenticated: isAuthenticatedSelector(state),
    }),
    {
        setAuthState,
        setClinicState,
        setClinicProfileState
    }
)(withRouter(withChatContactsList(withChatConnection(PanelHost))));
