import {
    currencyList,
    FormButtonType,
    FormControlType,
    IFormConfig,
    InputType,
    MultiSelectType,
    ValidationRules,
} from 'meditrip-common-web';

export const calendarDetailsConfig: typeof IFormConfig = {
    controlType: 'form',
    class: '',
    dataAccessor: (data: any) => data,
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            controlType: 'group',
            key: 'name',
            class: 'form-row',
            controls: {
                name: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                        {name: ValidationRules.MIN_LENGTH, params: {length: 6}}
                    ],
                    placeholder: 'calendar.calendarDetails.form.name.placeholder',
                    label: 'calendar.calendarDetails.form.name.label',
                    type: InputType.TEXT,
                    hostClass: 'col-100',
                }
            }
        },
        {
            controlType: 'group',
            key: 'clinic_timezone',
            class: 'form-row',
            controls: {
                timezone: {
                    controlType: 'control',
                    defaultValue: "Europe/Brussels",
                    placeholder: 'calendar.calendarDetails.form.timezone.placeholder',
                    formControlType: FormControlType.TIMEZONE,
                    label: 'calendar.calendarDetails.form.timezone.label',
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                    ],
                    // tooltipText: 'calendar.calendarDetails.form.timezone.tooltipText',
                    hostClass: 'col-100',
                    disabled: true,
                    inputDataMapper: (data: any) => {
                        if (!data) {
                            return [];
                        }
                        return data;
                    },
                    outputDataMapper: (data: any) => {
                        if(!data) {
                            return;
                        }
                        if (Array.isArray(data)) {
                            return (data.length > 0) ? data[0].value : null;
                        }
                        if (data && typeof data === 'object') {
                            return data.value
                        }
                        return data;
                    },
                },
            }
        },
        {
            controlType: 'group',
            key: 'amount',
            class: 'form-row',
            controls: {
                // amount: {
                //     controlType: 'control',
                //     defaultValue: '',
                //     formControlType: FormControlType.CURRENCY,
                //     validationRules: [
                //         {name: ValidationRules.IS_REQUIRED},
                //     ],
                //     placeholder: `calendar.calendarDetails.form.price.placeholder.${CurrencyType.EURO}`,
                //     label: 'calendar.calendarDetails.form.price.label',
                //     hostClass: 'col-100',
                //     currency: CurrencyType.EURO,
                //     type: InputType.NUMBER,
                // },
                amount: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    placeholder: 'calendar.calendarDetails.form.price.placeholder',
                    label: 'calendar.calendarDetails.form.price.label',
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                    ],
                    type: InputType.NUMBER,
                    hostClass: 'col-75 pr-0',
                    isCurrencyInput: true
                },
                currency: {
                    controlType: 'control',
                    defaultValue: null,
                    placeholder: 'calendar.calendarDetails.form.currency.placeholder',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.SINGLE,
                    isCurrencySelect: true,
                    validationRules: [
                        { name: ValidationRules.IS_REQUIRED },
                    ],
                    multiselectOptions: () => {
                        return currencyList.map((currency: any) => ({
                            value: currency.value,
                            label: currency.label
                        }))
                    },
                    inputDataMapper: (data: any) => {
                        if (!data) {
                            return [];
                        }

                        return currencyList
                            .filter((currency: any) => currency.value === data)
                            .map((currency: any) => ({
                                value: currency.value,
                                label: currency.label,
                            }));
                    },
                    outputDataMapper: (data: any) => {
                        if (Array.isArray(data)) {
                            return (data.length > 0) ? data[0].value : null;
                        }
                        if (data && typeof data === 'object') {
                            return data.value;
                        }

                        return data;
                    },
                    hostClass: 'col-25 pl-0',
                }
            }
        },
        {
            controlType: 'group',
            key: 'mail_group',
            class: 'form-row',
            controls: {
                clinicEmail: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                        {name: ValidationRules.IS_EMAIL},
                    ],
                    placeholder: 'calendar.calendarDetails.form.clinicEmail.placeholder',
                    label: 'calendar.calendarDetails.form.clinicEmail.label',
                    type: InputType.EMAIL,
                    hostClass: 'col-100',
                },
                specialistEmail: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        // {name: ValidationRules.IS_EMAIL},
                    ],
                    placeholder: 'calendar.calendarDetails.form.specialistEmail.placeholder',
                    label: 'calendar.calendarDetails.form.specialistEmail.label',
                    type: InputType.EMAIL,
                    hostClass: 'col-100',
                },
            }
        },
        {
            controlType: 'group',
            key: 'calendar_edit_submit',
            class: 'form-row',
            controls: {
                submitButton: {
                    controlType: 'control',
                    formControlType: FormControlType.BUTTON,
                    buttonType: FormButtonType.SUBMIT,
                    hostClass: 'col-100 mb-0',
                    defaultContainerStyles: '',
                    containerStyles: 'button-container mt-2 pr-0',
                    defaultValue: null,
                    btnText: 'buttons.update',
                    buttonDisabled: (mappedOutputValue: any, isFormValid: boolean, isFormTouched: boolean) => {
                        return !isFormTouched;
                    },
                    defaultStyles: 'btn btn-primary'
                },
            },
        },
    ]
};

