import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {IAlertManagerService} from '../../../service/alertManagerService';
import {fixInjectedProperties, lazyInject} from "../../../ioc";
import {catchError, map} from "rxjs/operators";
import {of, Subscription} from "rxjs";
import styles from "./styles.module.scss";
import {Loader, Toast, Translation} from "meditrip-common-web";
import {confirmTreatmentPlanAPI} from "../../../api/confirmTreatmentPlan";

interface ITreatmentPlanConfirmationProps extends
    RouteComponentProps {}

interface ITreatmentPlanConfirmationState {
    accountId: string | null;
    recommendationId: string | null;
    notificationToken: string | null;
    isLoading: boolean;
}

class TreatmentPlanConfirmation extends React.Component<ITreatmentPlanConfirmationProps, ITreatmentPlanConfirmationState> {
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;
    private subscription: Subscription | null = null;

    constructor(props: ITreatmentPlanConfirmationProps) {
        super(props);

        this.state = {
            accountId: null,
            recommendationId: null,
            notificationToken: null,
            isLoading: false
        };

        fixInjectedProperties(this);
    }

    componentDidMount() {
        const routeParams = this.props.match.params,
            query = new URLSearchParams(this.props.location.search),
            notificationToken = query.get('token');

        this.setState({
            accountId: (routeParams as any).accountId,
            recommendationId: (routeParams as any).recommendationId,
            notificationToken: notificationToken
        });
    }

    componentWillUnmount() {
        if(null !== this.subscription && undefined !== this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className={styles.confirmationContainer}>
                    <div className={styles.confirmationText}>
                        <Translation text={'treatmentPlanner.treatmentPlannerConfirmation.description'}/>
                    </div>

                    <button className="btn btn-theme"
                            type="button"
                            onClick={() => this.confirmAfterCare()}>
                        <span className="feather icon-check-circle" />
                        <Translation text={'treatmentPlanner.treatmentPlannerConfirmation.button'}/>
                    </button>
                </div>
                <Loader showLoader={this.state.isLoading}/>
                <Toast/>
            </React.Fragment>
        );
    }

    private confirmAfterCare = () => {
        if(!this.state.accountId ||
            !this.state.notificationToken ||
            !this.state.recommendationId) {
            return;
        }

        this.setState({isLoading: true});
        this.subscription = confirmTreatmentPlanAPI(
            this.state.notificationToken,
            this.state.recommendationId,
        ).pipe(
            map(() => {
                this.setState({isLoading: false});
                this.alertManager.addAlert('The invitation to Treatment Planner is confirmed');
                window.location.href = `${process.env.REACT_APP_CLINIC_PANEL_URL}/aftercare_confirmation/success`;
            }),
            catchError((error: any) => {
                this.setState({isLoading: false});
                return of(this.alertManager.handleApiError(error));
            })
        ).subscribe()
    };

}

export default withRouter(TreatmentPlanConfirmation);
