import { Observable } from "rxjs";
import { RestQueryParams } from "meditrip-common-web";
import {meditripCalendarAPI} from "./provider/meditripCalendar";

export function deleteCalendarAPI(
    calendarId: string,
    accessToken: string
): Observable<any> {
    return meditripCalendarAPI.delete(
        `calendars/${calendarId}`,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${accessToken}`
        }
    );
}
