import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {RootState} from "../../../../../store/reducers";
import {
    authTokenSelector,
    BasicModal,
    CustomCard,
    CustomCardType,
    Form,
    FormControlChangeType,
    IFormConfig,
    Translation
} from "meditrip-common-web";
import {connect} from "react-redux";
import {WithTranslation, withTranslation} from "react-i18next";
import {IAlertManagerService} from '../../../../../service/alertManagerService';
import {fixInjectedProperties, lazyInject} from "../../../../../ioc";
import {BehaviorSubject, of, Subscription} from "rxjs";
import {catchError, filter, map, tap} from "rxjs/operators";
import {IAftercareMapperService} from "../../../../../service/aftercareMapperService";
import {list as recommendationDefinitionList} from "../../../../../actions/recommendationDefinition/list";
import {retrievedRecommendationDefinitionListSelector} from "../../../../../store/selectors/recommendationDefinitionListSelectors";
import {RecommendationDefinitionType} from "../../../PredefinedRecommendationsList";
import {retrieve} from "../../../../../actions/treatmentPlanner/show";
import {IUpdateAftercarePayload, updateAftercareAPI} from "../../../../../api/updateAftercareAPI";
import {eventTypeFormConfig} from "./formConfig";
import {EventType} from "../../../PredefinedRecommendationsList/AddPredefinedRecommendation";
import CreateTravelPlanEvent from "./CreateTravelPlan/index";
import CreateAfterCareEvent from "./CreateAfterCareEvent";
import {isNullOrUndefined} from "../../../../../utils/runtimeUtils";

interface IConnectedAddAfterCareEventProps {
    readonly authToken: string;
    readonly recommendationDefinitionList: any;
    readonly retrievedRecommendationDefinitions: any;
    readonly retrieve: typeof retrieve;
}

interface ICreateEventProps extends IConnectedAddAfterCareEventProps,
    RouteComponentProps,
    WithTranslation {
        addAfterCareModalShown: boolean;
        toggleAfterCareModal: any;
        afterCare: {[key: string]: any} | null;
        selectedRecommendationDate: string | null;
}

interface ICreateEventState {
    formConfig: typeof IFormConfig;
    isFormValid: boolean;
    isLoading: boolean;
    repeatEvent: boolean;
    value: any;
}

class CreateEvent extends React.Component<ICreateEventProps, ICreateEventState> {
    readonly subscriptions: Subscription[] = [];
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;
    @lazyInject('AftercareMapperService') private aftercareMapper: IAftercareMapperService;

    constructor(props: ICreateEventProps) {
        super(props);

        this.state = {
            formConfig: eventTypeFormConfig,
            isFormValid: true,
            isLoading: false,
            repeatEvent: false,
            value: null
        };

        fixInjectedProperties(this);
    }

    componentDidMount() {
        this.subscriptions.push(
            this.onValueStateChange$.pipe(
                filter((data: any) => data && data.changeType === FormControlChangeType.User),
                tap((data: any) => this.onFormValueChange(data.value, data.changeType)),
            ).subscribe()
        );
    }

    componentDidUpdate(
        prevProps: Readonly<ICreateEventProps>,
        prevState: Readonly<ICreateEventState>,
        snapshot?: any
    ): void {}

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        return (
            <React.Fragment>
                <BasicModal isModalShown={this.props.addAfterCareModalShown} closeModal={this.props.toggleAfterCareModal}>
                    <CustomCard showLocalLoader={this.state.isLoading} type={CustomCardType.MODAL_CARD}>
                        <CustomCard.Body>
                            <div className="modal-header">
                                <Translation text={'treatmentPlanner.newTreatmentPlan.addEventTitle'}/>
                                <button className="btn-modal-close" onClick={() => {this.props.toggleAfterCareModal()}}>
                                    <span className="feather icon-x"/>
                                </button>
                            </div>
                            <div className="modal-body aftercare-event-modal">
                                <Form config={this.state.formConfig}
                                      onValueStateChange={this.onValueStateChange}
                                      onValidationStateChange={this.onValidationStateChange}
                                      value={this.state.value}
                                      controlName={'addEventForm'}
                                      submitForm={this.addNewEvent}/>

                                {this.state.value?.treatmentPlannerType === EventType.AFTERCARE ?
                                    (<CreateAfterCareEvent selectedRecommendationDate={this.props.selectedRecommendationDate}
                                                   closeRecommendationModal={this.props.toggleAfterCareModal}
                                                   addNewEvent={this.addNewEvent}/>) :
                                    (<CreateTravelPlanEvent selectedRecommendationDate={this.props.selectedRecommendationDate}
                                                    closeRecommendationModal={this.props.toggleAfterCareModal}
                                                    addNewEvent={this.addNewEvent}/>)
                                }
                            </div>
                        </CustomCard.Body>
                    </CustomCard>
                </BasicModal>
            </React.Fragment>
        );
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private onValidationStateChange = (controlName: string, isValid: boolean) => {
        this.setState({isFormValid: isValid});
    };

    private onFormValueChange = (value: any, changeType: typeof FormControlChangeType) => {
        this.setState({value: value});
    };

    private addNewEvent = (value: any) => {
        this.setState({isLoading: true});
        this.subscriptions.push(this.handleAddEventAPI(value).subscribe());
    };

    private handleAddEventAPI(value: any) {
        if (!this.props.afterCare || !value) {
            return of();
        }

        // const value = this.state.value,
            // cycleInterval = this.aftercareMapper.convertValueToDateInterval(value.intervalValue, value.intervalUnit),
            const treatmentPointStartAt = this.aftercareMapper.treatmentPointStartDate(
                value.eventDate,
                value.eventHour,
                value.eventDayPeriod
            ),
            treatmentPointEndDate = this.aftercareMapper.calculateRecommendationEndDate(
                treatmentPointStartAt,
                value.intervalValue,
                value.intervalUnit,
                value.consecutiveType,
                value.consecutiveValue
            ),
            startNotificationPropagation = this.aftercareMapper.calculateNotificationPropagationDate(
                treatmentPointStartAt,
                value.reminderAmount,
                value.reminderUnit
            ),
            // notificationType = value.repeatEvent === RecommendationDefinitionType.SINGLE ?
            //     RecommendationDefinitionType.SINGLE : value.consecutiveType;
            notificationType = RecommendationDefinitionType.SINGLE;

        let aftercareRecommendations = this.props.afterCare.treatmentPoints ? [...this.props.afterCare.treatmentPoints] : [];
        if (value.repeatEvent === RecommendationDefinitionType.SINGLE || isNullOrUndefined(value.repeatEvent)) {
            aftercareRecommendations.push({
                type: this.state.value.treatmentPlannerType,
                name: value.eventName,
                description: value.eventDescription ? value.eventDescription : null,
                treatmentPointStartsAt: treatmentPointStartAt,
                treatmentPointEndDate: treatmentPointEndDate,
                startNotificationPropagation: startNotificationPropagation,
                treatmentPlanId: this.props.afterCare.id,
                cyclic: false,
                cycleInterval: null,
                notificationType: notificationType
            })
        }

        if (value.repeatEvent === RecommendationDefinitionType.CONSECUTIVE) {
            let initRecommendation = {
                type: this.state.value.treatmentPlannerType,
                name: value.eventName,
                description: value.eventDescription,
                treatmentPointStartsAt: treatmentPointStartAt,
                treatmentPointEndDate: treatmentPointEndDate,
                startNotificationPropagation: startNotificationPropagation,
                treatmentPlanId: this.props.afterCare.id,
                cyclic: false,
                cycleInterval: null,
                notificationType: notificationType
            };

            let recurringRecommendations = this.aftercareMapper.getRecurringEvents(
                initRecommendation,
                value.consecutiveType,
                value.consecutiveValue,
                value.intervalValue,
                value.intervalUnit
            );

            recurringRecommendations.forEach((recommendation: {[key: string]: any}) => {
                return aftercareRecommendations.push(recommendation);
            });
        }

        let payload: IUpdateAftercarePayload = {
            treatmentPoints: aftercareRecommendations
        };

        return updateAftercareAPI(this.props.authToken, this.props.afterCare.id, payload).pipe(
            map(() => {
                this.setState({isLoading: false});
                if (this.props.afterCare?.id) {
                    this.props.retrieve(`treatment_plans/${this.props.afterCare.id}`, this.props.authToken);
                }
                this.props.toggleAfterCareModal();
                this.alertManager.addAlert('New event was successfully created.');
            }),
            catchError((error: any) => {
                    this.setState({isLoading: false});
                    return of(this.alertManager.handleApiError(error));
                }
            )
        );
    }
}

export default withTranslation()(connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
        retrievedRecommendationDefinitions: retrievedRecommendationDefinitionListSelector(state)
    }),
    {
        recommendationDefinitionList,
        retrieve
    }
)(withRouter(CreateEvent)));
