import React from 'react';
import { Route } from 'react-router-dom';
import App from "../App";
import PanelHost from "../components/PanelHost";
import {
  AuthPanel, AuthType,
  Maintenance,
  UserRole,
  PrivateRoute
} from "meditrip-common-web";
import {registrationFormConfig} from "./registrationFormConfig";
import TreatmentPlanConfirmation from "../components/TreatmentPlanner/TreatmentPlanConfirmation";
import TreatmentPlanConfirmationSuccess
  from "../components/TreatmentPlanner/TreatmentPlanConfirmation/TreatmentPlanConfirmationSuccess";

export default [
  <Route path="/" component={App} exact key="home" />,
  // <Route path="/dashboard/:slug" component={ViewHost} key="dashboard" />,
  <PrivateRoute path="/dashboard/" component={PanelHost} key="dashboard" />,
  <Route path="/aftercare_confirmation/:accountId/:recommendationId" component={TreatmentPlanConfirmation} exact strict
         key="afterCareConfirmation" />,
  <Route path="/aftercare_confirmation/success" component={TreatmentPlanConfirmationSuccess} exact strict
         key="afterCareConfirmationSuccess" />,
  <Route path="/auth/login" component={() => <AuthPanel userRole={UserRole.CLINIC} authType={AuthType.LOGIN} envData={process.env}/>} exact key="login"/>,
  <Route path="/auth/register"
         component={() =>  <AuthPanel userRole={UserRole.CLINIC} authType={AuthType.REGISTER} formConfig={registrationFormConfig}  envData={process.env}/>}
         exact key="register"/>,
  <Route path="/auth/register/:subscriptionPlanId"
         component={() =>  <AuthPanel userRole={UserRole.CLINIC} authType={AuthType.REGISTER} formConfig={registrationFormConfig}  envData={process.env}/>}
         exact key="registerWithSubscription"/>,
  <Route path="/auth/reset-password"
         component={() =>  <AuthPanel userRole={UserRole.CLINIC} authType={AuthType.RESET_PASSWORD}  envData={process.env}/>} exact key="reset-password"/>,
  <Route path="/auth/new-password/:slug" component={() =>  <AuthPanel  userRole={UserRole.CLINIC} authType={AuthType.NEW_PASSWORD}  envData={process.env}/>} exact key="new-password"/>,

  <Route key="not-found" component={Maintenance} />
];
