import {FormControlType, IFormConfig, InputType, MultiSelectType, ValidationRules} from "meditrip-common-web";
import moment from "moment";

export const treatmentPlanDetailsFormConfig: typeof IFormConfig = {
    controlType: 'group',
    class: '',
    dataAccessor: (data: any) => data, // data is not grouped
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);

        return previousStateSnapshot;
    },
    controls: [
        {
            controlType: 'group',
            key: 'planName',
            class: 'form-row',
            controls: {
                planName: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                    ],
                    placeholder: 'Plan name',
                    label: 'Plan name',
                    type: InputType.TEXT,
                    hostClass: 'col-100',
                    disabled: true,
                },
            }
        },
        {
            controlType: 'group',
            key: 'treatmentDate',
            class: 'form-row',
            controls: {
                aftercareDate: {
                    hostClass: 'col-50',
                    controlType: 'control',
                    defaultValue: '',
                    additionalStyles: 'horizontal',
                    formControlType: FormControlType.DATE,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                        {
                            name: ValidationRules.IS_DATE_GTE_THAN,
                            params: {
                                valueAccessor: (data: any) => data,
                                compareAccessor: () => moment().subtract(1, 'days'),
                            }
                        },
                    ],
                    placeholder: 'Select treatment date',
                    label: 'Treatment date',
                    maxDate: '2100-01-01',
                    minDate: moment().format('YYYY-MM-DD'),
                    openToDate: moment().format('YYYY-MM-DD'),
                    type: InputType.DATE,
                    disabled: true
                },
                aftercareTreatmentType: {
                    hostClass: 'col-50',
                    controlType: 'control',
                    defaultValue: null,
                    placeholder: 'Select treatment type',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.SINGLE,
                    label: 'Treatment type',
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                    ],
                    multiselectOptions: [],
                    disabled: true,
                    outputDataMapper: (data: any) => {
                        if (Array.isArray(data)) {
                            return (data.length > 0) ? data[0].value : null;
                        }
                        if (data && typeof data === 'object') {
                            return data.value;
                        }
                        return data;
                    },
                }
            }
        }
    ]
};
