import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {RootState} from "../../../../store/reducers";
import {authTokenSelector, BasicModal, CustomCard, CustomCardType, Translation} from "meditrip-common-web";
import {connect} from "react-redux";
import {WithTranslation, withTranslation} from "react-i18next";
import {IAlertManagerService} from '../../../../service/alertManagerService';
import {fixInjectedProperties, lazyInject} from "../../../../ioc";
import {of, Subscription} from "rxjs";
import styles from "./styles.module.scss";
import {PlanButtonType} from "../SubscriptionPlanCard";
import {accountIdSelector} from "../../../../store/selectors/accountSelectors";
import {buySubscriptionDefinitionAPI, PaymentServiceName} from "../../../../api/buySubscriptionDefiniton";
import {catchError, map} from "rxjs/operators";

interface IConnectedChangeSubscriptionPlanProps {
    accountId: string | null;
    authToken: string;
}

interface IChangeSubscriptionPlanProps extends IConnectedChangeSubscriptionPlanProps,
    RouteComponentProps,
    WithTranslation {
    isModalShown: boolean;
    toggleModal: any;
    currentPlan: {[key: string]: any} | null;
    nextPlan: {[key: string]: any;}
}

interface IChangeSubscriptionPlanState {
    isLoaderShown: boolean;
}

class ChangeSubscriptionPlan extends React.Component<IChangeSubscriptionPlanProps, IChangeSubscriptionPlanState> {
    readonly subscriptions: Subscription[] = [];
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;

    constructor(props: IChangeSubscriptionPlanProps) {
        super(props);

        this.state = {
            isLoaderShown: false
        };

        fixInjectedProperties(this);
    }

    componentDidMount() {}

    componentDidUpdate(
        prevProps: Readonly<IChangeSubscriptionPlanProps>,
        prevState: Readonly<IChangeSubscriptionPlanState>,
        snapshot?: any
    ): void {}

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        const currentPlanDetails = (this.props.currentPlan && this.props.currentPlan.subscriptionDefinition) ?
            this.props.currentPlan.subscriptionDefinition.subscriptionDetails :
            (this.props.currentPlan && this.props.currentPlan.subscriptionDetails),
        currentPlanPrice = currentPlanDetails.price.amount,
            nextPlanPrice = this.props.nextPlan.subscriptionDetails.price.amount;
        let planBtnType = Number(nextPlanPrice) > Number(currentPlanPrice) ?
                PlanButtonType.UPGRADE : PlanButtonType.DOWNGRADE;
        return (
            <React.Fragment>
                <BasicModal isModalShown={this.props.isModalShown} closeModal={this.props.toggleModal}>
                    <CustomCard showLocalLoader={this.state.isLoaderShown} type={CustomCardType.MODAL_CARD}>
                        <CustomCard.Body>
                            <div className="modal-header">
                                <Translation text={'billings.subscriptions.changeSubscriptionModal.title'}/>
                                <button className="btn-modal-close" onClick={() => this.props.toggleModal()}>
                                    <span className="feather icon-x"/>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className={styles.offerContainer}>
                                    {this.renderPlanDetails(this.props.currentPlan, 'currentPlan')}

                                    {this.renderPlanDetails(this.props.nextPlan, 'newPlan')}
                                </div>
                                <div className={styles.offerInfo}>
                                    {planBtnType === PlanButtonType.DOWNGRADE ?
                                        (<p><Translation text={'billings.subscriptions.changeSubscriptionModal.downgradeDescription'}/></p>) :
                                        (<p><Translation text={'billings.subscriptions.changeSubscriptionModal.upgradeDescription'}/></p>)
                                    }
                                </div>
                                <div className={styles.btnContainer}>
                                    {planBtnType === PlanButtonType.DOWNGRADE ?
                                        (<button className="btn btn-no-outline mr-4"
                                                 onClick={() => this.props.toggleModal()}>
                                            <span className="feather icon-chevron-left"/>
                                            <Translation text={`buttons.back`}/>
                                        </button>) : null}

                                    <button className="btn btn-theme"
                                            onClick={() => this.changePlan()}>
                                        <Translation text={`billings.subscriptions.changeSubscriptionModal.${planBtnType}`}/>
                                    </button>
                                </div>
                            </div>
                        </CustomCard.Body>
                    </CustomCard>
                </BasicModal>
            </React.Fragment>
        );
    }

    private changePlan = () => {
        if (this.props.accountId) {
            this.setState({isLoaderShown: true});
            return this.subscriptions.push(
                buySubscriptionDefinitionAPI(
                    this.props.authToken,
                    this.props.nextPlan.id,
                    PaymentServiceName.STRIPE
                ).pipe(
                    map(() => {
                        this.props.toggleModal();
                        this.alertManager.addAlert('Your subscription plan has been successfully updated');
                    }),
                    catchError((error: any) => {
                        this.setState({isLoaderShown: false});
                        return of(this.alertManager.handleApiError(error));
                    })
                ).subscribe()
            )
        }
    };

    private renderPlanDetails = (plan: {[key: string]: any} | null, planType: string) => {
        if (!plan) {
            return;
        }

        const planDetails = plan.subscriptionDefinition ? plan.subscriptionDefinition.subscriptionDetails : plan.subscriptionDetails;

        return(
            <div className={`${styles.plan} ${styles[planType]}`}>
                <div className={styles.planDetails}>
                    <h4>{planDetails.name}</h4>
                    <p>
                        <Translation text={`billings.subscriptions.changeSubscriptionModal.${planType}`}/>
                    </p>
                </div>
                <div className={styles.planPrice}>
                    <p className={styles.price}>{Number(planDetails.price.amount) / 100}</p>
                    <p className={styles.currency}>{planDetails.price.currency.code} <span>/month</span></p>
                </div>
            </div>
        )
    }
}

export default withTranslation()(connect(
    (state: RootState) => ({
        accountId: accountIdSelector(state),
        authToken: authTokenSelector(state)
    }),
    {}
)(withRouter(ChangeSubscriptionPlan)));
