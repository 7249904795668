import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {list, reset} from '../../../actions/consultation/list';
import {RootState} from "../../../store/reducers";
import {
    authTokenSelector,
    CustomCard,
    DateConverter,
    RestQueryParams,
    CustomPagination,
    Translation,
    formatDateToString,
    transformToPriceString
} from "meditrip-common-web";
import {
    consultationListErrorSelector,
    consultationListEventSourceSelector,
    consultationListLoadingSelector,
    retrievedListSelector
} from '../../../store/selectors/consultationListSelectors';
import {DateRangePicker} from 'rsuite';
import {isNullOrUndefined} from '../../../utils/runtimeUtils';
import moment from 'moment';
import {connect} from "react-redux";
import {WithTranslation, withTranslation} from "react-i18next";
import {IAlertManagerService} from '../../../service/alertManagerService';
import {fixInjectedProperties, lazyInject} from "../../../ioc";
import SelectCalendarInput from './SelectCalendarInput';
import ConsultationInvitationForm from './ConsultationInvitationForm';
import {getAccountDataAPI} from "../../../api/getAccountData";
import {catchError, tap} from "rxjs/operators";
import {of, Subscription} from "rxjs";
import ConsultationAlert from "../ConsultationAlert";
import CancelConsultation from "./CancelConsultation";
import {ModuleConfigType} from "../CalendarWidgets";
import {saveAs} from "file-saver";
// import SubscriptionPlanDetails from "./SubscriptionPlanDetails";

export enum ConsultationStatus {
    FINISHED = 'finished',
    SCHEDULED = 'scheduled',
    CANCELLED = 'cancelled',
    DRAFT = 'draft',
    NOT_STARTED = 'not_started',
    STARTED = 'started',
    SUCCEEDED = 'succeeded'
}

const datepickerRanges: any[] = [
    {
        label: 'Previous Month',
        value: [
            new Date(moment().subtract(1, 'months').startOf('month').format()),
            new Date(moment().subtract(1, 'months').endOf('month').format())
        ]
    },
    {
        label: 'last7Days',
        value: [
            new Date(moment().subtract(7, 'days').format()),
            new Date(moment().endOf('day').format())]
    },
    {
        label: 'today',
        value: [
            new Date(moment().startOf('day').format()),
            new Date(moment().endOf('day').format())
        ]
    },
    {
        label: 'Current Month',
        value: [
            new Date(moment().startOf('month').format()),
            new Date(moment().endOf('month').format())
        ]
    },
];

interface IConnectedListProps {
    readonly retrieved: any;
    readonly loading: boolean;
    readonly error: string;
    readonly eventSource: EventSource;
    readonly list: any;
    readonly reset: any;
    readonly authToken: string;
}

interface IConsultationsListProps extends IConnectedListProps,
    RouteComponentProps,
    WithTranslation {
}

interface IConsultationsListState {
    startDate: Date | null;
    defaultDateValue: any;
    reportInsuranceNumber: string | null;
    reportStartDate: string;
    reportEndDate: string;
    reportCalendar: string | null;
    invitationModalShown: boolean;
    onlineConsultationModuleConfig: {[key: string]: any} | null;
    subscriptionEndDate: string | null;
    isTelemedicineAlertShown: boolean;
    isSubscriptionPlanFree: boolean;
}

class ConsultationsList extends React.Component<IConsultationsListProps, IConsultationsListState> {
    readonly subscriptions: Subscription[] = [];
    private linkRef: any;
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;

    constructor(props: IConsultationsListProps) {
        super(props);

        let date = new Date();
        const defaultStartValue = new Date(date.getFullYear(), date.getMonth(), 1),
            defaultEndValue = new Date(date.getFullYear(), date.getMonth() + 1, 0);

        this.state = {
            startDate: null,
            defaultDateValue: [defaultStartValue, defaultEndValue],
            reportInsuranceNumber: null,
            reportStartDate: this.getTimeString(defaultStartValue),
            reportEndDate: this.getTimeString(defaultEndValue),
            reportCalendar: null,
            invitationModalShown: false,
            onlineConsultationModuleConfig: null,
            subscriptionEndDate: null,
            isTelemedicineAlertShown: true,
            isSubscriptionPlanFree: false
        };
        this.linkRef = React.createRef();
        fixInjectedProperties(this);
    }

    componentDidMount() {
        // this.getConsultationsSummary(this.props.authToken);
        if (this.props.authToken) {
            this.subscriptions.push(
                getAccountDataAPI(this.props.authToken).pipe(
                    tap((resp: any) => {
                        let onlineConsultationModuleConfig = resp.clinic.moduleConfigs.find((item: {[key: string]: any}) => {
                            return item.module.name === ModuleConfigType.ONLINE_CONSULTATION;
                        });
                        let currentPlan = resp.account.subscription.currentSubscriptionEntry,
                            currentPlanPrice = currentPlan?.subscriptionDefinition?.subscriptionDetails?.price?.amount,
                            isPlanFree = !currentPlan || !currentPlanPrice || (currentPlanPrice && Number(currentPlanPrice) === 0);
                        this.setState({
                            onlineConsultationModuleConfig: onlineConsultationModuleConfig.moduleParameters,
                            subscriptionEndDate: resp.account.subscription.currentSubscriptionEntry?.validUntil,
                            isSubscriptionPlanFree: isPlanFree
                        });
                    }),
                    catchError((error: any) => {
                        return of(this.alertManager.handleApiError(error));
                    })
                ).subscribe()
            );
        }
    }

    componentDidUpdate(
        prevProps: Readonly<IConsultationsListProps>,
        prevState: Readonly<IConsultationsListState>,
        snapshot?: any
    ): void {
        if (this.props.error !== prevProps.error) {
            this.alertManager.handleApiError(this.props.error);
        }

        if (this.state.reportStartDate !== prevState.reportStartDate ||
            this.state.reportEndDate !== prevState.reportEndDate) {
            // this.getConsultationsSummary(this.props.authToken);
        }
    }

    componentWillUnmount() {
        this.props.reset(this.props.eventSource);
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        let minutesLeft: boolean | null = !!(this.state.onlineConsultationModuleConfig &&
            this.state.onlineConsultationModuleConfig.minutesAvailable <= 0);

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-xl-12">
                        <div className="view-header">
                            <div className="view-title flex-grow-1 align-items-center justify-content-between">
                                <Translation text={'consultations.title'}/>
                                {/*<SubscriptionPlanDetails*/}
                                {/*    onlineConsultationModuleConfig={this.state.onlineConsultationModuleConfig}*/}
                                {/*    subscriptionEndDate={this.state.subscriptionEndDate}/>*/}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12">
                        <ConsultationAlert onlineConsultationModuleConfig={this.state.onlineConsultationModuleConfig}
                                           isSubscriptionPlanFree={this.state.isSubscriptionPlanFree}/>
                    </div>
                    <div className="col-xl-12 d-flex justify-content-between">
                        <div className="filter-container">
                            <SelectCalendarInput showConsultations={this.showConsultations}/>
                            <div className="form-control mr-4">
                                <DateRangePicker placeholder="Select Date Range"
                                                 onChange={this.handleDateChange}
                                                 placement="bottomEnd"
                                                 ranges={datepickerRanges}
                                                 defaultValue={this.state.defaultDateValue}
                                                 renderValue={(value) => {
                                                     return `${formatDateToString(value[0])} - ${formatDateToString(value[1])}`;
                                                 }}
                                />
                            </div>
                        </div>

                        <div className="action-container">
                            <button className="btn btn-no-outline mr-2"
                                    type="button"
                                    onClick={() => this.downloadReport()}>
                                <span className="feather icon-download"/>
                                Download report
                            </button>
                            <a ref={this.linkRef}/>
                            <button className="btn btn-theme"
                                    type="button"
                                    disabled={minutesLeft}
                                    onClick={() => this.toggleInvitationModal()}>
                                Send Consultation invitation to Patient
                            </button>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12">
                        <CustomCard showLocalLoader={this.props.loading}>
                            <CustomCard.Body>
                                {this.renderConsultationList()}
                                <CustomPagination retrieved={this.props.retrieved}
                                    basePath="dashboard"
                                    path="consultations"
                                    provider={this.getInquiryList} />
                            </CustomCard.Body>
                        </CustomCard>
                    </div>
                </div>

                {this.state.invitationModalShown &&
                    (<ConsultationInvitationForm invitationModalShown={this.state.invitationModalShown}
                                                 closeInvitationModal={this.closeInvitationModal}
                                                 toggleInvitationModal={this.toggleInvitationModal}
                    />)
                }
            </React.Fragment>
        );
    }

    private renderConsultationList = () => {
        if (!this.props.retrieved ||
            !this.props.retrieved['hydra:member'] ||
            !Array.isArray(this.props.retrieved['hydra:member'])) {
            return <p>There is no data available</p>;
        }

        return (
            <React.Fragment>
                <table className="data-table">
                    <thead>
                    <tr>
                        <th><Translation text={'consultations.list.consultationTime'}/></th>
                        <th><Translation text={'consultations.list.calendarName'}/></th>
                        <th><Translation text={'consultations.list.patientName'}/></th>
                        <th><Translation text={'consultations.list.createdAt'}/></th>
                        <th><Translation text={'consultations.list.price'}/></th>
                        <th><Translation text={'consultations.list.durationTime'}/></th>
                        {/*<th className="text-center"><Translation text={'consultations.reminderSent.title'}/></th>*/}
                        <th className="text-center"><Translation text={'consultations.list.status'}/></th>
                        <th className="text-center"><Translation text={"consultations.cancelConsultation"}/></th>
                    </tr>
                    </thead>
                    <tbody>
                        {this.renderTableRows()}
                    </tbody>
                </table>
            </React.Fragment>
        )
    };

    private handleDateChange = (e: any) => {
        if (!e.length || isNullOrUndefined(e)) {
            return;
        }

        const startDate = this.getTimeString(e[0]);
        const endDate = this.getTimeString(e[1]);
        this.props.list(
            `/online_consultations?page=1&startsAt[after]=${startDate}&endsAt[before]=${endDate}`,
            this.props.authToken,
        );

        this.setState({
            reportStartDate: startDate,
            reportEndDate: endDate
        })
    };

    private renderTableRows() {
        const {t} = this.props,
            rows: any[] = [],
            list = this.props.retrieved['hydra:member'].sort(this.sortMethod);

        list.map((item: any) => {
            let itemStatus = item.status === ConsultationStatus.FINISHED ? ConsultationStatus.SUCCEEDED : item.status,
                isCancelBtnDisabled = item.status === ConsultationStatus.CANCELLED || item.status === ConsultationStatus.FINISHED;

            return rows.push((
                <tr key={item['@id']}>
                    <td>
                        {this.renderConsultationTime(item.startsAt, item.endsAt)}
                    </td>
                    <td>
                        {item['calendar'] ? item['calendar'].name : '---'}
                    </td>
                    <td>
                        {this.renderPatientName(item)}
                    </td>
                    <td>
                        <DateConverter date={item.createdAt}/>
                    </td>
                    <td>
                        {item.payments[0]?.value ?
                            transformToPriceString(item.payments[0].value.currency.code, item.payments[0].value.amount / 100) :
                        '---'}
                    </td>
                    <td className="align-middle">
                        {this.getConsultationDuration(item.startsAt, item.endsAt)}
                    </td>

                    {/*<td className="align-middle text-center">*/}
                    {/*  <span className="">*/}
                    {/*      /!*<abbr className={`${styles.linkItemTooltip} ${styles.statusIcon}`}*!/*/}
                    {/*            /!*title={t(`consultations.reminderSent.description`, {isSent: `${item.reminderSent ? 'sent' : 'not sent'}`})}>*!/*/}
                    {/*          <span*/}
                    {/*              className={`feather icon-${item.reminderSent ? 'check' : 'x color-danger'}`}*/}
                    {/*              aria-hidden="true"/>*/}
                    {/*      /!*</abbr>*!/*/}
                    {/*      /!*<span className="sr-only"><Translation*!/*/}
                    {/*          /!*text={`consultations.reminderSent.${item.reminderSent ? 'sent' : 'notSent'}`}/></span>*!/*/}
                    {/*  </span>*/}
                    {/*</td>*/}
                    <td className="text-center align-middle">
                        <div className={`status-wrapper consultation-${itemStatus.replace('_', '-')}`}>
                            <p className="status">
                                <Translation text={`consultations.statuses.${itemStatus.replace(/_([a-z0-9])/g, (g: string) => g[1].toUpperCase())}`}/>
                            </p>
                        </div>
                    </td>
                    <td className="text-center align-middle">
                        <CancelConsultation id={item.id}
                                            isDisabled={isCancelBtnDisabled}
                                            authToken={this.props.authToken}
                                            getInquiryList={this.props.list} />
                    </td>
                </tr>
            ))
        });
        return rows;
    }

    private renderConsultationTime = (startsAt: Date, endsAt: Date) => {
        return (
            <React.Fragment>
                <p>{moment(startsAt).format('YYYY-MM-DD')}</p>
                <p>{moment(startsAt).format('HH:mm')} - {moment(endsAt).format('HH:mm')} </p>
            </React.Fragment>
        )
    };

    private renderPatientName = (item: {[key: string]: any}) => {
        const patient = item.participants.find((participant: {[key: string]: any}) => participant.role === 'ROLE_PATIENT');

        if (patient && patient.account) {
            return <p>{patient.account.firstName} {patient.account.lastName}</p>;
        } else {
            return <p>---</p>;
        }
    };

    private getConsultationDuration = (startsAt: Date, endsAt: Date) => {
        const duration = moment.duration(moment(endsAt).diff(moment(startsAt))).asMinutes();
        return (
            <p>{Math.ceil(duration)} <Translation text={'consultations.list.durationMinutes'}/></p>

        )
    };

    private getInquiryList = (searchParams: typeof RestQueryParams) => {
        searchParams = searchParams.add('order[startsAt]', 'DESC');

        this.props.list(
            `online_consultations${searchParams.prepareQuery()}`,
            this.props.authToken
        );
    };

    private showConsultations = (calendarId: string) => {
        this.setState({reportCalendar: calendarId});
        if (calendarId === 'all') {
            return this.props.list(
                `online_consultations`,
                this.props.authToken,
            );
        }
        return this.props.list(
            `online_consultations?page=1&calendar.id=${calendarId}`,
            this.props.authToken
        );
    };

    private downloadReport = () => {
        let requestUrl = `online_consultations/export?from[after]=${this.state.reportStartDate}&to[before]=${this.state.reportEndDate}`;
        if (this.state.reportCalendar) {
            requestUrl = requestUrl + `&calendar.id=${this.state.reportCalendar}`;
        }

        let url = `${process.env.REACT_APP_AUTH_API_URL}/${requestUrl}`;
        return fetch(url, {
            headers: {
                "Accept": "text/csv",
                "Content-type": "application/json",
                "Authorization": `Bearer ${this.props.authToken}`
            },
        }).then(response => response.blob())
            .then(blob => saveAs(blob, 'report.csv'));
    };

    private getTimeString(value: Date): string {
        return new Date(value.getTime() - (value.getTimezoneOffset() * 60000)).toISOString().split('T')[0];
    }

    private sortMethod(a: any, b: any): number {
        const aDate = new Date(a.startsAt),
            bDate = new Date(b.startsAt),
            aTime = aDate.getTime(),
            bTime = bDate.getTime();

        return aTime > bTime ? -1 : aTime !== bTime ? 1 : 0;
    }

    private toggleInvitationModal = () => {
        this.setState({invitationModalShown: !this.state.invitationModalShown});
    };

    private closeInvitationModal = () => {
        return this.setState({invitationModalShown: false});
    };
}

export default withTranslation()(connect(
    (state: RootState) => ({
        retrieved: retrievedListSelector(state),
        loading: consultationListLoadingSelector(state),
        error: consultationListErrorSelector(state),
        eventSource: consultationListEventSourceSelector(state),
        authToken: authTokenSelector(state),
    }),
    {
        list,
        reset,
    }
)(withRouter(ConsultationsList)));
