import {Observable} from "rxjs";
import {RestQueryParams} from "./base/queryParams";
import {meditripCalendarAPI} from "./provider/meditripCalendar";

export interface ICreateAftercarePayload {
    patientId: string,
    clinicId: string,
    treatmentTypeId: string,
    treatmentEndDate: string,
    doctorId: string | null,
    planName: string;
}

export function createAftercareAPI(
    authToken: string | undefined,
    aftercareDetails: ICreateAftercarePayload
): Observable<any> {
    const payload: any = {
        patientId: aftercareDetails.patientId,
        clinicId: aftercareDetails.clinicId,
        treatmentTypeId: aftercareDetails.treatmentTypeId,
        treatmentEndDate: aftercareDetails.treatmentEndDate,
        doctorId: null,
        name: aftercareDetails.planName
    };

    return meditripCalendarAPI.post(
        "treatment_plans",
        payload,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`,
        },
    );
}
