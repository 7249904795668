import {Observable} from "rxjs";
import {RestQueryParams} from "./base/queryParams";
import {meditripCalendarAPI} from "./provider/meditripCalendar";

export function sendAfterCareToPatientAPI(
    authToken: string | undefined,
    treatmentPlanId: string,
    email: string,
    returnUrl: string
): Observable<any> {
    const payload = {
        email: email,
        returnUrl: returnUrl,
        treatmentPlanId: treatmentPlanId
    };

    return meditripCalendarAPI.post(
        `treatment_plans/send_confirmation`,
        payload,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`,
        },
    );
}
