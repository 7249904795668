import {Container} from 'inversify';
import getDecorators, {fixInjectedProperties} from './decorators';
import {FormHandleChangeService} from 'meditrip-common-web';
import {
    AccountFormUpdaterService,
    AccountPayloadMapperService,
    AftercareMapperService,
    AlertManagerService,
    CalendarCreationService
} from '../service';

const container = new Container();

container.bind<typeof FormHandleChangeService>('FormHandleChangeService').to(FormHandleChangeService).inSingletonScope();
container.bind<AlertManagerService>('AlertManagerService').to(AlertManagerService).inSingletonScope();
container.bind<AccountPayloadMapperService>('AccountPayloadMapperService').to(AccountPayloadMapperService).inSingletonScope();
container.bind<AccountFormUpdaterService>('AccountFormUpdaterService').to(AccountFormUpdaterService).inSingletonScope();
container.bind<CalendarCreationService>('CalendarCreationService').to(CalendarCreationService).inSingletonScope();
container.bind<AftercareMapperService>('AftercareMapperService').to(AftercareMapperService).inSingletonScope();

const {lazyInject} = getDecorators(container);

export {container, lazyInject, fixInjectedProperties};
