import React from 'react';
import {
    addAlert,
    authTokenSelector,
    CustomCard,
    Form,
    FormControlChangeType,
    IFormConfig,
    Translation
} from "meditrip-common-web";
import {connect} from "react-redux";
import {calendarDetailsSelector, calendarIdSelector} from "../../../../../store/selectors/calendarSelectors";
import {changeCalendarDetails, ICalendarDetails} from "../../../../../store/reducers/calendarSlice";
import {RootState} from "../../../../../store/reducers";
import {BehaviorSubject, of, Subscription} from "rxjs";
import {calendarDetailsConfig} from "./formConfig";
import {catchError, filter, tap} from "rxjs/operators";
import {isNullOrUndefined} from "../../../../../utils/runtimeUtils";
import {updateCalendarAPI} from "../../../../../api/updateCalendarAPI";
import {fixInjectedProperties, lazyInject} from "../../../../../ioc";
import {IAlertManagerService} from "../../../../../service/alertManagerService";

interface IConnectedCalendarDetailsFormProps {
    readonly calendarDetails: ICalendarDetails | null;
    readonly calendarId: string | null,
    readonly authToken: string;
    readonly changeCalendarDetails: typeof changeCalendarDetails;
}

export interface ICalendarDetailsFormProps extends IConnectedCalendarDetailsFormProps {
    calendar: any;
}

interface ICalendarDetailsFormState {
    isFormValid: boolean;
    formConfig: typeof IFormConfig;
    value: any;
    isLoading: boolean;
}

class CalendarDetailsForm extends React.Component<ICalendarDetailsFormProps, ICalendarDetailsFormState> {
    @lazyInject('AlertManagerService') private alertManagerService: IAlertManagerService;
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    readonly subscriptions: Subscription[] = [];

    constructor(props: any) {
        super(props);

        this.state = {
            isFormValid: false,
            formConfig: calendarDetailsConfig,
            value: null,
            isLoading: false
        };
        fixInjectedProperties(this);
    }

    componentDidMount() {
        this.updateFormFromAPI(this.props.calendar);
        this.subscriptions.push(
            this.onValueStateChange$.pipe(
                filter((data: any) => data && data.changeType === FormControlChangeType.User),
                tap((data: any) => this.onFormValueChange(data.value)),
            ).subscribe()
        );
    }

    componentDidUpdate(
        prevProps: Readonly<any>,
        prevState: Readonly<any>,
        snapshot?: any
    ): void {
        if (this.props.calendar !== prevProps.calendar && this.props.calendar) {
            this.updateFormFromAPI(this.props.calendar);
        }
    }

    componentWillUnmount() {
        if (null !== this.subscriptions) {
            this.subscriptions.forEach(subscription => subscription.unsubscribe());
        }
    }

    render() {
        return (
            <div className="col-lg-6">
                <CustomCard showLocalLoader={this.state.isLoading}>
                    <CustomCard.Header>
                        <Translation text={'calendar.calendarDetails.title'}/>
                    </CustomCard.Header>
                    <CustomCard.Body>
                        <Form config={this.state.formConfig}
                              onValidationStateChange={this.onFormValidityChange}
                              onValueStateChange={this.onValueStateChange}
                              submitForm={this.updateCalendarDetails}
                              value={this.state.value}
                              controlName={'addCalendarForm'}/>
                    </CustomCard.Body>
                </CustomCard>
            </div>
        );
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private onFormValueChange = (value: any) => {
        if (isNullOrUndefined(value.name) || isNullOrUndefined(value.timezone) || isNullOrUndefined(value.amount)) {
            return
        }
        this.props.changeCalendarDetails({
            calendarName: value.name,
            calendarPrice: {
                amount: value.amount * 100,
                currency: {code: value.currency}
            },
            timezone: value.timezone,
            clinicEmail: value.clinicEmail,
            specialistEmail: value.specialistEmail
        })
    };

    private onFormValidityChange = (controlName: string, isValid: boolean, errorMessages: ReadonlyArray<string>) => {
        this.setState({isFormValid: isValid});
    };

    private updateFormFromAPI = (calendar: any) => {
        if (calendar) {
            this.props.changeCalendarDetails({
                calendarName: calendar.name,
                calendarPrice: calendar.price,
                timezone: calendar.timezone,
                clinicEmail: calendar.clinicEmail,
                specialistEmail: calendar.specialistEmail
            });
            this.setState({
                value: {
                    name: calendar.name,
                    timezone: calendar.timezone,
                    amount: calendar.price.amount / 100,
                    currency: calendar.price.currency.code,
                    clinicEmail: calendar.clinicEmail,
                    specialistEmail: calendar.specialistEmail
                }
            });
        }
    };

    private updateCalendarDetails = () => {
        if (this.props.calendarDetails && this.props.calendarId) {
            updateCalendarAPI(this.props.calendarId, this.props.authToken, {
                name: this.props.calendarDetails.calendarName,
                public: true,
                price: this.props.calendarDetails.calendarPrice,
                slotLength: this.props.calendar.slotLength,
                availableFrom: this.props.calendar.availableFrom,
                availableUntil: this.props.calendar.availableUntil,
                timezone: this.props.calendarDetails.timezone,
                calendarRules: this.props.calendar.calendarRules,
                clinicEmail: this.props.calendarDetails.clinicEmail,
                specialistEmail: this.props.calendarDetails.specialistEmail
            }).pipe(
                tap(() => {
                    this.alertManagerService.addAlert('calendar.modals.confirmUpdateModal.success');
                }),
                catchError((error: any) => {
                    this.alertManagerService.handleApiError(error.response);
                    return of(error);
                })
            ).subscribe();
        }
    }
}

export default connect(
    (state: RootState) => ({
        calendarDetails: calendarDetailsSelector(state),
        calendarId: calendarIdSelector(state),
        authToken: authTokenSelector(state),
    }),
    {
        addAlert,
        changeCalendarDetails,
    }
)(CalendarDetailsForm);
