import {Observable} from "rxjs";
import {RestQueryParams} from "./base/queryParams";
import {meditripCalendarAPI} from "./provider/meditripCalendar";
import {ICalendarRule} from "../store/reducers/calendarSlice";

export function addCalendarRulesAPI(
    authToken: string,
    calendarId: string,
    calendarRules: ICalendarRule[]
): Observable<any> {
    const payload: any = {
        calendarRules: calendarRules
    };

    return meditripCalendarAPI.put(
        `calendars/${calendarId}/calendar_rules`,
        payload,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`,
        },
    );
}
