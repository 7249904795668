import React from "react";
import { connect } from "react-redux";
import {authTokenSelector, CustomCard, Translation} from "meditrip-common-web";
import {generateStripeOnboardingAPI, OnboardingType} from "../../../api/generateStripeOnboarding";
import {RootState} from "../../../store/reducers";
import {fixInjectedProperties, lazyInject} from "../../../ioc";
import {catchError, map} from "rxjs/operators";
import {of} from "rxjs";
import {IAlertManagerService} from "../../../service/alertManagerService";
import {accountSelector, paymentAccountsSelector} from "../../../store/selectors/accountSelectors";
import {stripeDashboardRedirectAPI} from "../../../api/stripeDashboardRedirect";
import {PaymentAccount} from "../../../store/reducers/accountSlice";
import {PaymentServiceName} from "../../../api/buySubscriptionDefiniton";
import styles from './styles.module.scss';


interface IPaymentCollectionConnectedProps {
    authToken: string;
    account: any;
    readonly paymentAccounts: PaymentAccount[];
}

interface IPaymentCollectionExternalProps {}

interface IPaymentCollectionProps extends
    IPaymentCollectionConnectedProps,
    IPaymentCollectionExternalProps {}

interface IPaymentCollectionState {
    isLoading: boolean;
    isOnboardingCompleted: boolean;
    paymentAccountId: string | null;
    paymentAccountVendorData: {[key: string]: any} | null;
}

class PaymentCollection extends React.Component<IPaymentCollectionProps, IPaymentCollectionState> {
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;

    constructor(props: IPaymentCollectionProps) {
        super(props);

        this.state = {
            isLoading: true,
            isOnboardingCompleted: false,
            paymentAccountId: null,
            paymentAccountVendorData: null
        };
        fixInjectedProperties(this);
    }

    componentDidMount(): void {
        const stripePaymentAccount = this.props.paymentAccounts
            .find(account => (account.paymentAccountVendorType === PaymentServiceName.STRIPE));
        if (stripePaymentAccount !== null &&
            stripePaymentAccount !== undefined
        ) {
            this.setState({
                isOnboardingCompleted: stripePaymentAccount.paymentAccountVendorData.chargesEnabled,
                paymentAccountId: stripePaymentAccount.id,
                isLoading: false,
                paymentAccountVendorData: stripePaymentAccount.paymentAccountVendorData
            });
        }
    }

    componentDidUpdate(
        prevProps: Readonly<IPaymentCollectionProps>,
        prevState: Readonly<IPaymentCollectionState>,
        snapshot?: any
    ): void {
        const stripePaymentAccount = this.props.paymentAccounts
            .find(account => (account.paymentAccountVendorType === PaymentServiceName.STRIPE)),
            prevStripePaymentAccount = prevProps.paymentAccounts
                .find(account => (account.paymentAccountVendorType === PaymentServiceName.STRIPE));

        if (stripePaymentAccount &&
            prevStripePaymentAccount &&
            stripePaymentAccount.paymentAccountVendorData.chargesEnabled !==
            prevStripePaymentAccount.paymentAccountVendorData.chargesEnabled
        ) {
            this.setState({
                isOnboardingCompleted: stripePaymentAccount.paymentAccountVendorData.chargesEnabled,
                isLoading: false
            });
        }
    }

    render() {
        return (
            <CustomCard showLocalLoader={this.state.isLoading}>
                <CustomCard.Header>
                    <Translation text={'billings.paymentsCollection.title'}/>
                    <p className={styles.onboardingStatus}>{this.state.isOnboardingCompleted ?
                        <span className={styles.complete}>
                            <Translation text={'billings.paymentsCollection.onboardingStatus.onboardingComplete'}/>
                        </span> :
                        <span className={styles.required}>
                            <Translation text={'billings.paymentsCollection.onboardingStatus.onboardingRequired'}/>
                        </span>
                    }</p>
                </CustomCard.Header>
                <CustomCard.Body>
                    {this.state.isOnboardingCompleted ?
                        this.renderOnboardingCompleteSection() :
                        this.renderOnboardingRequiredSection()
                    }
                </CustomCard.Body>
            </CustomCard>
        );
    }

    private renderOnboardingRequiredSection = () => {
        return(
            <React.Fragment>
                <div>
                    <p className={styles.onboardingDescription}>
                        <Translation text={'billings.paymentsCollection.subtitle'}/>
                    </p>
                    <p className={styles.onboardingDescription}>
                        <Translation text={'billings.paymentsCollection.instructions'}/>
                    </p>
                </div>

                <div className="mt-5">
                    <button className="btn btn-primary"
                            onClick={() => this.sendOnboarding()}>
                        <Translation text={'billings.paymentsCollection.startOnboarding'}/>
                    </button>
                </div>
          </React.Fragment>
        )
    };

    private renderOnboardingCompleteSection = () => {
        return(
            <div>
                {this.state.paymentAccountVendorData ?
                    (<div className={styles.bankDetails}>
                        <p><Translation text={'billings.paymentsCollection.stripeCard'}/></p>
                        <p className={styles.card}>** **** **** **** **** {this.state.paymentAccountVendorData.accountBankTransferLastFour}</p>
                    </div>):
                    null}
                <button className="btn btn-primary"
                        onClick={() => this.redirectToStripePanel()}>
                    <Translation text={'billings.paymentsCollection.updateOnboarding'}/>
                </button>
            </div>
        )
    };

    private sendOnboarding = () => {
        if (!this.props.account || !this.props.account.id) {
            return;
        }

        let payload = {
            refreshUrl: `${process.env.REACT_APP_CLINIC_PANEL_URL}/generate_onboarding_link`,
            returnUrl: `${process.env.REACT_APP_CLINIC_PANEL_URL}/dashboard/billings`,
            onboardingMode: OnboardingType.Onboarding
        };

        if (this.state.paymentAccountId) {
            return generateStripeOnboardingAPI(this.state.paymentAccountId, this.props.authToken, payload).pipe(
                map((response: any) => {
                    window.open(response.url, '_blank', 'noopener, noreferrer')
                }),
                catchError((error: any) => {
                    return of((this.alertManager.handleApiError(error)))
                })
            ).subscribe();
        }
    };

    private redirectToStripePanel = () => {
        if (!this.props.account || !this.props.account.id) {
            return;
        }

        const returnUrl = `${process.env.REACT_APP_CLINIC_PANEL_URL}`;

        if (this.state.paymentAccountId) {
            return stripeDashboardRedirectAPI(this.props.authToken, this.state.paymentAccountId, returnUrl).pipe(
                map((response: any) => {
                    window.open(response.url.url, '_blank', 'noopener, noreferrer')
                }),
                catchError((error: any) => {
                    return of((this.alertManager.handleApiError(error)))
                })
            ).subscribe();
        }
    }
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
        account: accountSelector(state),
        paymentAccounts: paymentAccountsSelector(state)
    }),
    {}
)(PaymentCollection);
